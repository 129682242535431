import styled from "styled-components";

export const PageLayoutTable = styled.div`
  display: flex;
  flex-flow: row;
  margin-left: 10px;
  margin-right: 10px;

  @media only screen and (max-width: 600px), (max-height: 600px) {
    flex-flow: column;
  }
`;
