import { Text } from "atoms";
import { useState } from "react";
import { Cancel, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectPagination } from "containers/StoresPage/slice/selectors";
import { IUser, IUserRoleEnum } from "openapi/types";
import { ConfirmationDialogue } from "components/ConfirmationDialogue/ConfirmationDialogue";
import { deleteUser } from "containers/UsersPage/slice";
import { AddUserModal } from "../UserModal/AddUserModal";
import { getUsersEntityByRole } from "utils/UtilFunctions";

interface IUserRowProps {
  user: IUser;
}

export const UserRow = ({ user }: IUserRowProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const pagination = useSelector(selectPagination);
  const dispatch = useDispatch();

  const onAction = () => {
    if (user.userId) {
      dispatch(
        deleteUser({
          userId: user.userId,
          pagination,
        })
      );
    }
  };

  return (
    <tr style={{ borderTop: "1px solid #e8e8e8", height: "63px" }}>
      <th>
        <Text bold>{`${user.firstName} ${user.lastName}`}</Text>
      </th>
      <td>
        <Text bold>{user.email}</Text>
      </td>
      <td>
        <Text bold>{user.role}</Text>
      </td>
      <td>
        <Text bold>{getUsersEntityByRole(user)}</Text>
      </td>
      <td>
        <IconButton className="mr-2" onClick={() => setIsVisible(true)}>
          <Edit />
        </IconButton>
        <IconButton onClick={() => setConfirmVisibility(true)}>
          <Cancel />
        </IconButton>
      </td>
      {isVisible && (
        <AddUserModal onClose={() => setIsVisible(false)} user={user} />
      )}
      {confirmVisibility && (
        <ConfirmationDialogue
          onClose={() => setConfirmVisibility(false)}
          description="Ești sigur că vrei să ștergi acest user?"
          onConfirm={onAction}
        />
      )}
    </tr>
  );
};
