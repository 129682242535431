import { ActionType } from "typesafe-actions";
import {
  createProducer,
  deleteProducer,
  setPagination,
  setPaginationError,
  setPaginationSuccess,
  updateProducer,
} from ".";
import { call, put, takeLatest } from "redux-saga/effects";
import { producerApiInstance } from "services";

export function* setPaginationSaga(action: ActionType<typeof setPagination>) {
  const { pagination } = action.payload;
  try {
    const pageSize = pagination.pageSize;
    const pageNumber = pagination.pageNumber;
    const response = yield call(
      [producerApiInstance, producerApiInstance.getAllProducer],
      pageSize,
      pageNumber
    );
    const totalPagesCount = response.data.totalPagesCount;
    yield put(
      setPaginationSuccess({
        producerList: response.data.producerDaoList,
        totalPagesCount,
      })
    );
  } catch (e: any) {
    const response = e;
    yield put(
      setPaginationError({
        error: response.data.message ?? "Something went wrong",
      })
    );
  }
}

export function* updateProducerSaga(action: ActionType<typeof updateProducer>) {
  const { producer, pagination } = action.payload;

  try {
    yield call(
      [producerApiInstance, producerApiInstance.updateProducer],
      producer
    );
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
  }
}

export function* deleteProducerSaga(action: ActionType<typeof deleteProducer>) {
  const { producerId, pagination } = action.payload;

  try {
    yield call(
      [producerApiInstance, producerApiInstance.deleteProducerById],
      producerId
    );
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
  }
}

export function* createProducerSaga(action: ActionType<typeof createProducer>) {
  const { producer, pagination } = action.payload;

  try {
    yield call(
      [producerApiInstance, producerApiInstance.registerProducer],
      producer
    );
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
  }
}

export function* producersSaga() {
  yield takeLatest(setPagination.type, setPaginationSaga);
  yield takeLatest(createProducer.type, createProducerSaga);
  yield takeLatest(updateProducer.type, updateProducerSaga);
  yield takeLatest(deleteProducer.type, deleteProducerSaga);
}
