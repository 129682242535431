import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import sessionReducer from "containers/App/slice/index";
import storesReducer from "containers/StoresPage/slice/index";
import producersReducer from "containers/ProducersPage/slice/index";
import warehousesReducer from "containers/WarehousesPage/slice/index";
import productsReducer from "containers/ProductsPage/slice/index";
import orderReducer from "containers/CartPage/slice/index";
import ordersReducer from "containers/OrdersPage/slice/index";
import singleOrderReducer from "containers/SingleOrderPage/slice/index";
import toastMessageReducer from "components/ToastMessage/slice/index";
import usersReducer from "containers/UsersPage/slice/index";
import profileReducer from "containers/ProfilePage/slice/index";
import rootSaga from "types/RootSaga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    session: sessionReducer,
    stores: storesReducer,
    producers: producersReducer,
    warehouses: warehousesReducer,
    users: usersReducer,
    products: productsReducer,
    order: orderReducer,
    orders: ordersReducer,
    singleOrder: singleOrderReducer,
    toastMessage: toastMessageReducer,
    profile: profileReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;
