import styled from "styled-components";

export const TableWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  padding-right: 10px;
  border: 1px solid grey;
  border-radius: 5px;

  scrollbar-width: auto;
  scrollbar-color: white;

  ::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #aaab5a;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaab5a;
  }
`;
