import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-radius: 3px;
  position: relative;

  font-size: 13px;
  line-height: 15px;

  color: #555555;
`;
