import { TableHeader } from "components/TableHeader/TableHeader";

export const HeaderRow = () => {
  const elements = [
    {
      label: "Identificator",
      withoutBorder: true,
    },
    {
      label: "Data",
    },
    {
      label: "Status",
    },
    {
      label: "Pret total",
    },
    {
      label: "AWB",
    },
    {
      label: "Actiuni",
    },
  ];
  return <TableHeader elements={elements} />;
};
