import { ActionType } from "typesafe-actions";
import {
  createUser,
  deleteUser,
  loadPrerequisites,
  loadPrerequisitesSuccess,
  setPagination,
  setPaginationError,
  setPaginationSuccess,
  updateUser,
  updateUserError,
} from ".";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  producerApiInstance,
  storeApiInstance,
  userApiInstance,
  warehouseApiInstance,
} from "services";
import { displayToastMessage } from "components/ToastMessage/slice";
import { ToastType } from "components/ToastMessage/slice/types";

export function* setPaginationSaga(action: ActionType<typeof setPagination>) {
  const { email, pagination } = action.payload;
  try {
    const pageSize = pagination.pageSize;
    const pageNumber = pagination.pageNumber;
    const response = yield call(
      [userApiInstance, userApiInstance.searchUsers],
      email,
      pageSize,
      pageNumber
    );
    const totalPagesCount = response.data.totalPagesCount;
    yield put(
      setPaginationSuccess({
        userList: response.data.userDaoList,
        totalPagesCount,
      })
    );
  } catch (e: any) {
    const response = e;
    yield put(
      setPaginationError({
        error: response.data.message ?? "Something went wrong",
      })
    );
  }
}

export function* loadPrerequisitesSaga(
  action: ActionType<typeof loadPrerequisites>
) {
  try {
    const warehouseResp = yield call([
      warehouseApiInstance,
      warehouseApiInstance.getWarehouseList,
    ]);
    const producerResp = yield call([
      producerApiInstance,
      producerApiInstance.getProducerList,
    ]);
    const storeResp = yield call([
      storeApiInstance,
      storeApiInstance.getStoreList,
    ]);

    yield put(
      loadPrerequisitesSuccess({
        warehouseList: warehouseResp.data,
        producerList: producerResp.data,
        storeList: storeResp.data,
      })
    );
  } catch (e: any) {
    const response = e;
  }
}

export function* updateUserSaga(action: ActionType<typeof updateUser>) {
  const { user, pagination } = action.payload;

  try {
    yield call([userApiInstance, userApiInstance.updateUser], user);
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    yield put(
      updateUserError({
        error: "Something went wrong",
      })
    );
    yield put(
      displayToastMessage({
        type: ToastType.Error,
        message: "Acest email este in folosire!",
      })
    );
  }
}

export function* deleteUserSaga(action: ActionType<typeof deleteUser>) {
  const { userId, pagination } = action.payload;

  try {
    yield call([userApiInstance, userApiInstance.deleteUser], userId);
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
  }
}

export function* createUserSaga(action: ActionType<typeof createUser>) {
  const { user, password, pagination } = action.payload;

  try {
    yield call([userApiInstance, userApiInstance.createUser], {
      user,
      password,
    });
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
  }
}

export function* usersSaga() {
  yield takeLatest(setPagination.type, setPaginationSaga);
  yield takeLatest(loadPrerequisites.type, loadPrerequisitesSaga);
  yield takeLatest(createUser.type, createUserSaga);
  yield takeLatest(updateUser.type, updateUserSaga);
  yield takeLatest(deleteUser.type, deleteUserSaga);
}
