import { Collapse } from "reactstrap";
import styled from "styled-components";

export const CollapseWrapper = styled(Collapse)`
  z-index: 1000;
  background-color: white;
  position: absolute;
  width: 100%;
  padding: 0 10px;
  min-width: 220px;
  right: 0;
  margin-right: -20px;
  margin-top: 10px;
`;
