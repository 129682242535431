import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IStoresState } from "./types";
import { IStoreIn, IStoreOut } from "openapi/types";

export const initialState: IStoresState = {
  storeList: [],
  pagination: {
    pageSize: 10,
    pageNumber: 0,
  },
  totalPagesCount: 0,
  loading: false,
  error: undefined,
};

export const storesSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    setPagination(
      state,
      action: PayloadAction<{
        pagination: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.pagination = action.payload.pagination;
      state.loading = true;
      state.error = undefined;
    },
    setPaginationSuccess(
      state,
      action: PayloadAction<{ storeList: IStoreOut[]; totalPagesCount: number }>
    ) {
      state.storeList = action.payload.storeList;
      state.totalPagesCount = action.payload.totalPagesCount;
      state.loading = false;
    },
    setPaginationError(state, action: PayloadAction<{ error: string }>) {
      state.error = action.payload.error;
      state.loading = false;
    },
    createStore(
      state,
      action: PayloadAction<{
        store: IStoreIn;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
    deleteStore(
      state,
      action: PayloadAction<{
        storeId: string;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
    updateStore(
      state,
      action: PayloadAction<{
        store: IStoreIn;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
  },
});

export const {
  setPagination,
  setPaginationSuccess,
  setPaginationError,
  createStore,
  deleteStore,
  updateStore,
} = storesSlice.actions;

export default storesSlice.reducer;
