import { Link } from "react-router-dom";
import styled from "styled-components";

export const TitleWrapper = styled(Link)`
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
  padding-left: 15px;
  text-decoration: none;

  &:hover {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }
`;
