import { TableHeader } from "components/TableHeader/TableHeader";

export const HeaderRow = () => {
  const elements = [
    {
      label: "Nume",
      withoutBorder: true,
    },
    {
      label: "Descriere",
    },
    {
      label: "Pret",
    },
    {
      label: "Depozit",
    },
    {
      label: "Stare",
    },
    {
      label: "Stoc",
    },
    {
      label: "Actiuni",
    },
  ];
  return <TableHeader elements={elements} />;
};
