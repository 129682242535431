import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./locales/i18n";
import App from "containers/App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import common_en from "./translations/en.json";
import { BrowserRouter } from "react-router-dom";
import store from "store/store";
import React from "react";

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);

const languageOptions = {
  order: ["querystring", "localStorage", "navigator"],
  lookupQuerystring: "lng",
  lookupLocalStorage: "i18nextLng",

  caches: ["localStorage"],
  excludeCacheFor: ["cimode"],
};

i18next.use(LanguageDetector).init({
  detection: languageOptions,
  interpolation: { escapeValue: false },
  supportedLngs: ["en"],
  fallbackLng: "en",
  resources: {
    en: {
      common: common_en,
    },
  },
  debug: false,
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
