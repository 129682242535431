import styled from "styled-components";

export const Tbl = styled.table<{
  showHorizontalShadow?: boolean;
  showVerticalShadow?: boolean;
}>`
  width: 100%;
  flex-grow: 1;

  thead,
  tbody tr {
    width: 100%;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: ${(p) =>
      p.showHorizontalShadow && "0px 5px 10px -2px rgba(0, 0, 0, 0.08)"};
    background: white;
  }

  thead th {
    min-width: 120px;
    white-space: nowrap;
    padding-top: 16px;

    padding-right: 40px;
  }

  thead th:first-child {
    border-left: unset;
    position: sticky;
    left: 0;
    padding-left: 20px;
    background: #ffffff;
    z-index: 2;
  }

  tbody th {
    position: sticky;
    left: 0;
    background: white;
    padding-left: 25px;
    z-index: 1;
    box-shadow: ${(p) =>
      p.showVerticalShadow && "5px 0px 10px -2px rgba(0, 0, 0, 0.08)"};
  }

  tbody td {
    padding-left: 15px;
  }
`;
