import Input from "components/Input";
import { Text } from "atoms";

interface IFormInputProps {
  name?: string;
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  type?: string;
}

export const FormInput = ({
  name,
  value,
  onChange,
  disabled,
  type,
}: IFormInputProps) => {
  return (
    <div className="flex flex-row mt-2">
      <Text bold>{`${name}:`}</Text>
      <Input
        type={type}
        disabled={disabled ? true : false}
        value={value}
        onChange={(val) => onChange(val)}
      />
    </div>
  );
};
