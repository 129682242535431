import { PaginationLink } from "reactstrap";
import styled from "styled-components";

export const CustomPaginationLink = styled(PaginationLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: #999999;
  height: 28px;
  font-size: 10px;
  padding: 2px;
  text-align: center;
  min-width: 28px;
  transition: all 0.3s;

  &:hover {
    color: #aaab5a;
    background: transparent;
  }

  &:focus,
  &:active {
    box-shadow: none;
  }

  svg {
    transition: 0.3s;
  }
`;
