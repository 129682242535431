import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUsersState } from "./types";
import {
  IProducerListOut,
  IStoreListOut,
  IUser,
  IWarehouseListOut,
} from "openapi/types";

export const initialState: IUsersState = {
  userList: [],
  pagination: {
    pageSize: 10,
    pageNumber: 0,
  },

  warehouseList: [],
  producerList: [],
  storeList: [],

  totalPagesCount: 0,
  loading: false,
  error: undefined,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setPagination(
      state,
      action: PayloadAction<{
        email?: string;
        pagination: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.pagination = action.payload.pagination;
      state.loading = true;
      state.error = undefined;
    },
    setPaginationSuccess(
      state,
      action: PayloadAction<{
        userList: IUser[];
        totalPagesCount: number;
      }>
    ) {
      state.userList = action.payload.userList;
      state.totalPagesCount = action.payload.totalPagesCount;
      state.loading = false;
    },
    setPaginationError(state, action: PayloadAction<{ error: string }>) {
      state.error = action.payload.error;
      state.loading = false;
    },
    loadPrerequisites(state) {},
    loadPrerequisitesSuccess(
      state,
      action: PayloadAction<{
        warehouseList: IWarehouseListOut[];
        producerList: IProducerListOut[];
        storeList: IStoreListOut[];
      }>
    ) {
      state.warehouseList = action.payload.warehouseList;
      state.producerList = action.payload.producerList;
      state.storeList = action.payload.storeList;
    },
    createUser(
      state,
      action: PayloadAction<{
        user: IUser;
        password: string;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
    deleteUser(
      state,
      action: PayloadAction<{
        userId: string;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
    updateUser(
      state,
      action: PayloadAction<{
        user: IUser;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
    updateUserError(state, action: PayloadAction<{ error: string }>) {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  setPagination,
  setPaginationSuccess,
  setPaginationError,
  loadPrerequisites,
  loadPrerequisitesSuccess,
  createUser,
  deleteUser,
  updateUser,
  updateUserError,
} = usersSlice.actions;

export default usersSlice.reducer;
