import { Close, KeyboardArrowUpOutlined } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";
import Input from "components/Input";
import { useRef, useState } from "react";
import { TitleWrapper } from "./atoms/TitleWrapper";
import { CollapseItem } from "components/CollapseItem/CollapseItem";
import { HeaderWrapper } from "./atoms/HeaderWrapper";
import { DropdownRow } from "./atoms/DropdownRow";

interface IDropdownProps {
  title: string;
  items: Array<{
    id: string;
    display: string;
  }>;
  onSelect: (id: string) => void;
  selectedItemId?: string;
  bordered?: boolean;
  isAutocomplete?: boolean;
  disabled?: boolean;
  inputChange?: (value: string) => void;

  id?: string;
  hasSearch?: boolean;
}

export const Dropdown = (props: IDropdownProps) => {
  const {
    id,
    title,
    items,
    selectedItemId,
    onSelect,
    bordered,
    hasSearch,
    inputChange,
  } = props;

  const collapseRef = useRef<any>();

  const [search, setSearch] = useState("");

  const toggle = () => {
    collapseRef?.current?.toggle();
  };

  let selectedItemName = "";
  let selectedValue: any = null;
  if (selectedItemId) {
    selectedValue = items.find((item) => item.id === selectedItemId);
    if (selectedValue) {
      selectedItemName = selectedValue.display;
    }
  }

  const selectItem = (itemId: string) => {
    if (selectedItemId === itemId) {
      onSelect("");
    } else {
      onSelect(itemId);
    }
    toggle();
  };

  const titleDisplay = (
    <TitleWrapper>
      {`${title ? title + ": " : ""}${selectedItemName}`}
    </TitleWrapper>
  );

  if (props.isAutocomplete && items.length) {
    return (
      <Autocomplete
        data-testid={id}
        options={items}
        disabled={props.disabled}
        value={selectedValue}
        sx={{
          width: "100%",
        }}
        getOptionLabel={(option) => option.display}
        onChange={(event: any, value: any) => {
          onSelect(value?.id || "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: {
                fontSize: 13,
                paddingBottom: 0,
                paddingTop: 0,
                paddingLeft: 13,
              },
            }}
            placeholder={title}
          />
        )}
      />
    );
  }

  return (
    <CollapseItem
      id={id}
      disabled={props.disabled}
      bordered={bordered}
      ref={collapseRef}
      title={titleDisplay}
    >
      <div className="d-flex flex-column">
        <HeaderWrapper
          onClick={() => {
            if (!props.disabled) {
              toggle();
            }
          }}
        >
          {titleDisplay}
          <KeyboardArrowUpOutlined fontSize="small" />
        </HeaderWrapper>
        <div>
          {hasSearch && (
            <Input
              onChange={(val: string) => {
                setSearch(val);
                if (inputChange) {
                  inputChange(val);
                }
              }}
              value={search}
              placeholder="Cauta..."
            />
          )}
          <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
            {inputChange
              ? items.map((item) => (
                  <DropdownRow
                    data-testid={item.id}
                    key={item.id}
                    selected={selectedItemId === item.id}
                    onClick={() => selectItem(item.id)}
                  >
                    {item.display}
                    {selectedItemId === item.id && (
                      <Close fontSize="small" color="inherit" />
                    )}
                  </DropdownRow>
                ))
              : items
                  .filter((item) =>
                    item.display.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((item) => (
                    <DropdownRow
                      key={item.id}
                      selected={selectedItemId === item.id}
                      onClick={() => selectItem(item.id)}
                    >
                      {item.display}
                      {selectedItemId === item.id && (
                        <Close fontSize="small" color="inherit" />
                      )}
                    </DropdownRow>
                  ))}
          </div>
        </div>
      </div>
    </CollapseItem>
  );
};
