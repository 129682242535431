import { RootState } from "types";
import { initialState } from ".";
import { createSelector } from "@reduxjs/toolkit";

const selectProducers = (state: RootState) => state.producers || initialState;

export const selectProducerList = createSelector(
  [selectProducers],
  (producers) => producers.producerList
);

export const selectLoading = createSelector(
  [selectProducers],
  (producers) => producers.loading
);

export const selectPagination = createSelector(
  [selectProducers],
  (producers) => producers.pagination
);

export const selectError = createSelector(
  [selectProducers],
  (producers) => producers.error
);

export const selectTotalPagesCount = createSelector(
  [selectProducers],
  (producers) => producers.totalPagesCount
);
