import Table from "components/Table";
import { useEffect } from "react";
import { ProductRow } from "./components/ProductItem/ProductRow";
import { HeaderRow } from "./components/ProductItem/HeaderRow";
import { PaginationWrapper } from "atoms/PaginationWrapper";
import CustomPagination from "components/Pagination";
import { loadProducers, loadWarehouses, setPagination } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoading,
  selectPagination,
  selectProductsList,
  selectTotalPagesCount,
  selectWarehouseList,
} from "./slice/selectors";
import {
  selectUser,
  selectUserProducer,
  selectUserWarehouse,
} from "containers/App/slice/selectors";
import { Dropdown } from "components/Dropdown/Dropdown";
import { IUserRoleEnum } from "openapi/types";
import { getProducer, getWarehouse } from "utils/localStorage";

export const ProductsTable = () => {
  const totalPagesCount = useSelector(selectTotalPagesCount);
  const productsList = useSelector(selectProductsList);
  const rows = productsList
    ? productsList.map((product) => <ProductRow product={product} />)
    : [];
  const pagination = useSelector(selectPagination);
  const loading = useSelector(selectLoading);
  const warehouse = getWarehouse();
  const producer = getProducer();
  const user = useSelector(selectUser);
  const warehouseDropdownList = useSelector(selectWarehouseList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.role === IUserRoleEnum.Producer) {
      dispatch(
        setPagination({
          pagination: {
            pageNumber: 0,
            pageSize: 10,
            producerId: producer?.producerId,
          },
        })
      );
    } else if (user?.role === IUserRoleEnum.Warehouse) {
      dispatch(
        setPagination({
          pagination: {
            pageNumber: 0,
            pageSize: 10,
            warehouseId: warehouse?.warehouseId,
          },
        })
      );
    } else if (user?.role === IUserRoleEnum.Store) {
      dispatch(
        setPagination({
          pagination: {
            pageNumber: 0,
            pageSize: 10,
            warehouseId: warehouse?.warehouseId,
            isActive: true,
            hasStock: true
          },
        })
      );
    } else {
      dispatch(
        setPagination({
          pagination: {
            pageNumber: 0,
            pageSize: 10,
          },
        })
      );
    }
    dispatch(loadWarehouses());
    dispatch(loadProducers());
  }, []);

  const selectPage = (pageNumber: number) => {
    dispatch(setPagination({ pagination: { ...pagination, pageNumber } }));
  };

  const onSelect = (warehouseId: string) => {
    if (pagination) {
      if (user?.role === IUserRoleEnum.Store) {
        dispatch(
          setPagination({
            pagination: {
              ...pagination,
              warehouseId,
              isActive: true,
            },
          })
        );
      } else {
        dispatch(
          setPagination({
            pagination: {
              ...pagination,
              warehouseId,
            },
          })
        );
      }
    }
  };

  return (
    <div>
      <div className="mr-2 mt-2" style={{ maxWidth: "320px" }}>
        {user?.role === IUserRoleEnum.Store && (
          <Dropdown
            items={[
              { id: "Toate", display: "Toate" },
              ...warehouseDropdownList.map((item) => {
                return {
                  id: item.warehouseId ?? "",
                  display: item.title ?? "",
                };
              }),
            ]}
            onSelect={onSelect}
            title="Depozit"
            selectedItemId={pagination.warehouseId ?? ""}
            bordered
          />
        )}
      </div>
      <div className="mt-2">
        <Table
          headerRow={<HeaderRow />}
          rows={rows}
          loading={loading}
          notFoundText="Nu s-au gasit produse!"
        />
        <PaginationWrapper>
          <CustomPagination
            pagesCount={totalPagesCount}
            pageSize={pagination.pageSize}
            pageNumber={pagination.pageNumber}
            onSelectPage={selectPage}
          />
        </PaginationWrapper>
      </div>
    </div>
  );
};
