import { Text } from "atoms";
import { useState } from "react";
import { Cancel, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IProductOut, IUserRoleEnum } from "openapi/types";
import {
  selectPagination,
  selectWarehouseList,
} from "containers/ProductsPage/slice/selectors";
import { addProduct, updateProduct } from "containers/ProductsPage/slice";
import { ConfirmationDialogue } from "components/ConfirmationDialogue/ConfirmationDialogue";
import { AddProductModal } from "../ProductModal/AddProductModal";
import { selectUser } from "containers/App/slice/selectors";
import Button from "components/Button";
import { getStore } from "utils/localStorage";
import { Image } from "atoms/Image";
import { displayToastMessage } from "components/ToastMessage/slice";
import { ToastType } from "components/ToastMessage/slice/types";
import { NumericInput } from "components/NumericInput/NumericInput";

interface IProductRowProps {
  product: IProductOut;
}

export const ProductRow = ({ product }: IProductRowProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const [productNumber, setProductNumber] = useState<number>(0);
  const pagination = useSelector(selectPagination);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const currentStore = getStore();

  const warehouseDropdownList = useSelector(selectWarehouseList);

  const onAction = () => {
    dispatch(
      updateProduct({
        product: { ...product, isActive: !product.isActive },
        pagination,
      })
    );
  };

  const onAddToCart = () => {
    if (currentStore && product.productId) {
      dispatch(
        addProduct({
          productId: product.productId,
          storeId: currentStore.storeId,
          quantity: productNumber,
        })
      );
      dispatch(
        displayToastMessage({
          message: "Produsul a fost adaugat cu succes!",
          type: ToastType.Success,
        })
      );
    }
  };

  const updateProductNumber = (value: number) => {
    setProductNumber(value);
  };

  return (
    <tr style={{ borderTop: "1px solid #e8e8e8", height: "63px" }}>
      <th>
        <div className="d-flex flex-row align-items-center">
          <Image height="60px" width="60px" src={product.picture} />
          <Text className="ml-2" bold>
            {product.name}
          </Text>
        </div>
      </th>
      <td>
        <div style={{ minWidth: "180px" }}>
          <Text bold>{product.description}</Text>
        </div>
      </td>
      <td>
        <Text bold>{product.price} RON</Text>
      </td>
      <td>
        <Text bold>
          {
            warehouseDropdownList.find(
              (item) => item.warehouseId === product.warehouse
            )?.title
          }
        </Text>
      </td>
      <td>
        <Text bold>{product.isActive ? "Activ" : "Inactiv"}</Text>
      </td>
      <td>
        <Text bold>{product.stock}</Text>
      </td>
      <td>
        {user?.role === IUserRoleEnum.Store ? (
          <>
            <NumericInput
              value={productNumber}
              updateValue={updateProductNumber}
              minValue={0}
              maxValue={product.stock ?? 0}
            />
            <Button onClick={onAddToCart}>Adauga in cos</Button>
          </>
        ) : (
          <>
            <IconButton className="mr-2" onClick={() => setIsVisible(true)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => setConfirmVisibility(true)}>
              <Cancel />
            </IconButton>
          </>
        )}
      </td>
      {isVisible && (
        <AddProductModal
          onClose={() => setIsVisible(false)}
          product={product}
        />
      )}

      {confirmVisibility && (
        <ConfirmationDialogue
          onClose={() => setConfirmVisibility(false)}
          description="Ești sigur că vrei să dezactivezi acest produs?"
          onConfirm={onAction}
        />
      )}
    </tr>
  );
};
