import { RootState } from "types";
import { initialState } from ".";
import { createSelector } from "@reduxjs/toolkit";

const selectOrders = (state: RootState) => state.orders || initialState;

export const selectOrdersList = createSelector(
  [selectOrders],
  (orders) => orders.ordersList
);

export const selectTotalPagesCount = createSelector(
  [selectOrders],
  (orders) => orders.totalPagesCount
);

export const selectLoading = createSelector(
  [selectOrders],
  (orders) => orders.loading
);

export const selectPagination = createSelector(
  [selectOrders],
  (orders) => orders.pagination
);

export const selectError = createSelector(
  [selectOrders],
  (orders) => orders.error
);
