import Modal from "components/Modal";
import { createProduct, updateProduct } from "containers/ProductsPage/slice";
import {
  selectPagination,
  selectProducerList,
  selectWarehouseList,
} from "containers/ProductsPage/slice/selectors";
import { IProductIn, IUserRoleEnum } from "openapi/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductIn } from "utils/interfaces/interfaces";
import { ModalContentWrapper } from "./atoms/ModalContentWrapper";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Button from "components/Button";
import _ from "lodash";
import { selectUser } from "containers/App/slice/selectors";
import { getProducer, getWarehouse } from "utils/localStorage";
import FormInput from "components/FormInput";
import { Text } from "atoms";
import { Dropdown } from "components/Dropdown/Dropdown";
import { ImageUploader } from "components/ImageUploader/ImageUploader";

interface IProductModalProps {
  onClose: () => void;
  product?: IProductIn;
}

interface IProductErrors {
  name?: string;
  price?: string;
  image?: string;
}

export const AddProductModal = ({ onClose, product }: IProductModalProps) => {
  const [selectedProduct, setSelectedProduct] = useState<IProductIn>(
    new ProductIn()
  );
  const [selectedProductImage, setSelectedProductImage] = useState<File | null>(
    null
  );
  const [errors, setErrors] = useState<IProductErrors>({});
  const [selectedWarehouse, setSelectedWarehouse] = useState<string>(
    product?.warehouse ?? ""
  );
  const [selectedProducer, setSelectedProducer] = useState<string>(
    product?.producer ?? ""
  );
  const user = useSelector(selectUser);
  const currentProducer = getProducer();
  const currentWarehouse = getWarehouse();
  const pagination = useSelector(selectPagination);
  const dispatch = useDispatch();
  const producerList = useSelector(selectProducerList);
  const warehouseList = useSelector(selectWarehouseList);

  useEffect(() => {
    if (product && product.warehouse && product.producer) {
      setSelectedProduct({ ...product });
      setSelectedProducer(product.producer);
      setSelectedWarehouse(product.warehouse);
    } else {
      if (user?.role === IUserRoleEnum.Producer) {
        setSelectedProducer(currentProducer.producerId);
      } else if (user?.role === IUserRoleEnum.Warehouse) {
        setSelectedWarehouse(currentWarehouse.warehouseId);
      }
    }
  }, []);

  const onAction = () => {
    let error: IProductErrors = {
      name: undefined,
      price: undefined,
      image: undefined,
    };

    if (!selectedProduct.name || selectedProduct.name === "")
      error.name = "Produsul nu are nume!";
    if (!selectedProduct.price || selectedProduct.price === 0)
      error.price = "Produsul nu are pret!";
    if (!product && !selectedProductImage)
      error.image = "Produsul nu are poza!";
    if (error.name || error.price || error.image) {
      setErrors(error);
      return;
    }
    if (selectedProductImage !== null) {
      dispatch(
        createProduct({
          image: selectedProductImage,
          product: {
            ...selectedProduct,
            warehouse: selectedWarehouse,
            producer: selectedProducer,
          },
          pagination,
        })
      );
    } else {
      dispatch(
        updateProduct({
          product: {
            ...selectedProduct,
            warehouse: selectedWarehouse,
            producer: selectedProducer,
          },
          pagination,
        })
      );
    }
    onClose();
  };

  const updateProductVariables = (
    key: keyof IProductIn,
    value: string | number | boolean
  ) => {
    setSelectedProduct({
      ...selectedProduct,
      [key]: value,
    });
  };

  return (
    <Modal isVisible={true} onClose={onClose}>
      <ModalContentWrapper>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <div className="mt-3">
          <Text bold fontSize="20px">
            Informatii produs
          </Text>
          <FormInput
            name="Nume"
            disabled={user?.role === IUserRoleEnum.Warehouse ? true : false}
            value={selectedProduct?.name ?? ""}
            onChange={(value) => {
              updateProductVariables("name", value);
              setErrors({ ...errors, name: undefined });
            }}
          />
          {errors.name && (
            <Text color="red" bold>
              {errors.name}
            </Text>
          )}
          <FormInput
            name="Descriere"
            disabled={user?.role === IUserRoleEnum.Warehouse ? true : false}
            value={selectedProduct?.description ?? ""}
            onChange={(value) => {
              updateProductVariables("description", value);
            }}
          />
          {user?.role !== IUserRoleEnum.Warehouse && (
            <>
              <Text bold>Depozit:</Text>
              <Dropdown
                items={warehouseList.map((item) => {
                  return {
                    id: item.warehouseId ?? "",
                    display: item.title ?? "",
                  };
                })}
                onSelect={(id: string) => setSelectedWarehouse(id)}
                title=""
                selectedItemId={selectedWarehouse ?? ""}
                bordered
              />
            </>
          )}
          {user?.role === IUserRoleEnum.SysAdmin && (
            <>
              <Text bold>Producator:</Text>
              <Dropdown
                items={producerList.map((item) => {
                  return {
                    id: item.producerId ?? "",
                    display: item.name ?? "",
                  };
                })}
                onSelect={(id: string) => setSelectedProducer(id)}
                title=""
                selectedItemId={selectedProducer ?? ""}
                bordered
              />
            </>
          )}
          {user?.role !== IUserRoleEnum.Producer && (
            <>
              <Text bold>Stare:</Text>
              <Dropdown
                items={[
                  { id: "ACTIVE", display: "Activ" },
                  { id: "INACTIVE", display: "Inactiv" },
                ]}
                onSelect={(id: string) => {
                  if (id === "ACTIVE") updateProductVariables("isActive", true);
                  else if (id === "INACTIVE")
                    updateProductVariables("isActive", false);
                }}
                title=""
                selectedItemId={
                  selectedProduct.isActive ? "ACTIVE" : "INACTIVE"
                }
                bordered
              />
            </>
          )}
          <Text bold>Stoc:</Text>
          <input
            style={{ maxWidth: "70px", height: "24px" }}
            type="number"
            value={selectedProduct.stock ?? 0}
            onChange={(e) => {
              updateProductVariables("stock", e.target.value);
            }}
          />
          <Text bold>Pret:</Text>
          <input
            disabled={user?.role === IUserRoleEnum.Warehouse ? true : false}
            style={{ maxWidth: "70px", height: "24px" }}
            type="number"
            value={selectedProduct.price ?? 0}
            onChange={(e) => {
              updateProductVariables("price", e.target.value);
              setErrors({ ...errors, price: undefined });
            }}
          />
          {errors.price && (
            <Text color="red" bold>
              {errors.price}
            </Text>
          )}
          <div>
            <Text bold>Poza:</Text>
            <ImageUploader
              image={selectedProduct.picture}
              onChange={(image) => {
                setSelectedProductImage(image);
                setErrors({ ...errors, image: undefined });
              }}
            />
            {errors.image && (
              <Text color="red" bold>
                {errors.image}
              </Text>
            )}
          </div>
        </div>
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button onClick={onAction}>{product ? "Editeaza" : "Adauga"}</Button>
        </div>
      </ModalContentWrapper>
    </Modal>
  );
};
