import { RootState } from "types";
import { initialState } from ".";
import { createSelector } from "@reduxjs/toolkit";

const selectOrders = (state: RootState) => state?.order || initialState;

export const selectOrder = createSelector(
  [selectOrders],
  (order) => order.order
);

export const selectLoading = createSelector(
  [selectOrders],
  (order) => order.loading
);
