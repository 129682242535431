export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("userState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("userState", serializedState);
  } catch {
    // ignore write errors
  }
};

export const getAccessToken = () => {
  const state = loadState();
  if (state) {
    return state.accessToken;
  } else {
    return null;
  }
};

export const getRefreshToken = () => {
  const state = loadState();
  if (state) {
    return state.refreshToken;
  } else {
    return null;
  }
};

export const getStore = () => {
  const state = loadState();
  if (state) {
    return state.store;
  } else {
    return undefined;
  }
};

export const getWarehouse = () => {
  const state = loadState();
  if (state) {
    return state.warehouse;
  } else {
    return undefined;
  }
};

export const getProducer = () => {
  const state = loadState();
  if (state) {
    return state.producer;
  } else {
    return undefined;
  }
};

export const getAppLanguage = () => {
  try {
    const appLanguage = localStorage.getItem("i18nextLng");
    if (appLanguage === null) {
      return undefined;
    }
    return appLanguage;
  } catch (err) {
    return undefined;
  }
};
