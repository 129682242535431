/* tslint:disable */
/* eslint-disable */
/**
 * Altfeldistrib openAPI JSON file
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface IAddressIn
 */
export interface IAddressIn {
    /**
     * 
     * @type {string}
     * @memberof IAddressIn
     */
    addressId?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddressIn
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddressIn
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddressIn
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddressIn
     */
    country?: string;
}
/**
 * 
 * @export
 * @interface IAddressOut
 */
export interface IAddressOut {
    /**
     * 
     * @type {string}
     * @memberof IAddressOut
     */
    addressId?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddressOut
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddressOut
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddressOut
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof IAddressOut
     */
    country?: string;
}
/**
 * 
 * @export
 * @interface IEmailIn
 */
export interface IEmailIn {
    /**
     * 
     * @type {string}
     * @memberof IEmailIn
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface IImagesIn
 */
export interface IImagesIn {
    /**
     * 
     * @type {string}
     * @memberof IImagesIn
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof IImagesIn
     */
    filename?: string;
}
/**
 * 
 * @export
 * @interface IInlineObject1
 */
export interface IInlineObject1 {
    /**
     * 
     * @type {IUser}
     * @memberof IInlineObject1
     */
    user?: IUser;
    /**
     * 
     * @type {string}
     * @memberof IInlineObject1
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface IInlineObject4
 */
export interface IInlineObject4 {
    /**
     * 
     * @type {string}
     * @memberof IInlineObject4
     */
    storeId: string;
    /**
     * 
     * @type {string}
     * @memberof IInlineObject4
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof IInlineObject4
     */
    quantity: number;
}
/**
 * 
 * @export
 * @interface IInlineObject5
 */
export interface IInlineObject5 {
    /**
     * 
     * @type {string}
     * @memberof IInlineObject5
     */
    orderId: string;
}
/**
 * 
 * @export
 * @interface IInlineObject6
 */
export interface IInlineObject6 {
    /**
     * 
     * @type {string}
     * @memberof IInlineObject6
     */
    orderId: string;
    /**
     * 
     * @type {string}
     * @memberof IInlineObject6
     */
    awb: string;
}
/**
 * 
 * @export
 * @interface IInvoiceIn
 */
export interface IInvoiceIn {
    /**
     * 
     * @type {string}
     * @memberof IInvoiceIn
     */
    invoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceIn
     */
    chamberOfCommRegNo?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceIn
     */
    fiscalIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceIn
     */
    headquarters?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceIn
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceIn
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceIn
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceIn
     */
    delegateName?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceIn
     */
    idSerial?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceIn
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceIn
     */
    carRegistrationNumber?: string;
}
/**
 * 
 * @export
 * @interface IInvoiceOut
 */
export interface IInvoiceOut {
    /**
     * 
     * @type {string}
     * @memberof IInvoiceOut
     */
    invoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceOut
     */
    chamberOfCommRegNo?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceOut
     */
    fiscalIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceOut
     */
    headquarters?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceOut
     */
    county?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceOut
     */
    bankAccount?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceOut
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceOut
     */
    delegateName?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceOut
     */
    idSerial?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceOut
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof IInvoiceOut
     */
    carRegistrationNumber?: string;
}
/**
 * 
 * @export
 * @interface IJwtAuthenticationRequest
 */
export interface IJwtAuthenticationRequest {
    /**
     * email for current user
     * @type {string}
     * @memberof IJwtAuthenticationRequest
     */
    email: string;
    /**
     * password
     * @type {string}
     * @memberof IJwtAuthenticationRequest
     */
    password: string;
    /**
     * push token
     * @type {string}
     * @memberof IJwtAuthenticationRequest
     */
    pushToken?: string;
}
/**
 * 
 * @export
 * @interface IJwtAuthenticationResponse
 */
export interface IJwtAuthenticationResponse {
    /**
     * access token
     * @type {string}
     * @memberof IJwtAuthenticationResponse
     */
    accessToken?: string;
    /**
     * refresh token
     * @type {string}
     * @memberof IJwtAuthenticationResponse
     */
    refreshToken?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ILanguageEnum {
    Ro = 'ro',
    En = 'en'
}

/**
 * 
 * @export
 * @interface IOrderDaoListPaged
 */
export interface IOrderDaoListPaged {
    /**
     * 
     * @type {Array<IOrderOut>}
     * @memberof IOrderDaoListPaged
     */
    orderDaoList?: Array<IOrderOut>;
    /**
     * 
     * @type {number}
     * @memberof IOrderDaoListPaged
     */
    totalPagesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderDaoListPaged
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof IOrderDaoListPaged
     */
    pageSize?: number;
}
/**
 * 
 * @export
 * @interface IOrderIn
 */
export interface IOrderIn {
    /**
     * 
     * @type {string}
     * @memberof IOrderIn
     */
    orderId?: string;
    /**
     * 
     * @type {IStoreIn}
     * @memberof IOrderIn
     */
    store?: IStoreIn;
    /**
     * 
     * @type {Array<IProductIn>}
     * @memberof IOrderIn
     */
    products?: Array<IProductIn>;
    /**
     * 
     * @type {IOrderStateEnum}
     * @memberof IOrderIn
     */
    orderState?: IOrderStateEnum;
    /**
     * 
     * @type {string}
     * @memberof IOrderIn
     */
    awb?: string;
}
/**
 * 
 * @export
 * @interface IOrderOut
 */
export interface IOrderOut {
    /**
     * 
     * @type {string}
     * @memberof IOrderOut
     */
    orderId?: string;
    /**
     * 
     * @type {Array<IOrderProductOut>}
     * @memberof IOrderOut
     */
    products?: Array<IOrderProductOut>;
    /**
     * 
     * @type {IOrderStateEnum}
     * @memberof IOrderOut
     */
    orderState?: IOrderStateEnum;
    /**
     * 
     * @type {string}
     * @memberof IOrderOut
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof IOrderOut
     */
    awb?: string;
}
/**
 * 
 * @export
 * @interface IOrderProductOut
 */
export interface IOrderProductOut {
    /**
     * 
     * @type {string}
     * @memberof IOrderProductOut
     */
    productId?: string;
    /**
     * 
     * @type {number}
     * @memberof IOrderProductOut
     */
    quantity?: number;
    /**
     * 
     * @type {IProductOut}
     * @memberof IOrderProductOut
     */
    product?: IProductOut;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum IOrderStateEnum {
    Placed = 'PLACED',
    Executed = 'EXECUTED',
    Closed = 'CLOSED',
    Invoiced = 'INVOICED',
    Paid = 'PAID',
    Shipped = 'SHIPPED',
    Canceled = 'CANCELED',
    Proforma = 'PROFORMA'
}

/**
 * 
 * @export
 * @interface IPasswordIn
 */
export interface IPasswordIn {
    /**
     * 
     * @type {string}
     * @memberof IPasswordIn
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof IPasswordIn
     */
    newPassword?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum IPasswordTokenEnum {
    InvalidToken = 'INVALID_TOKEN',
    Expired = 'EXPIRED'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum IPaymentMethodEnum {
    CARD = 0,
    BANK_TRANSFER = 1,
    CASH = 2
}

/**
 * 
 * @export
 * @interface IProducerDaoListPaged
 */
export interface IProducerDaoListPaged {
    /**
     * 
     * @type {Array<IProducerOut>}
     * @memberof IProducerDaoListPaged
     */
    producerDaoList?: Array<IProducerOut>;
    /**
     * 
     * @type {number}
     * @memberof IProducerDaoListPaged
     */
    totalPagesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof IProducerDaoListPaged
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof IProducerDaoListPaged
     */
    pageSize?: number;
}
/**
 * Producer Information
 * @export
 * @interface IProducerIn
 */
export interface IProducerIn {
    /**
     * Unique identifier for the producer
     * @type {string}
     * @memberof IProducerIn
     */
    producerId?: string;
    /**
     * Name of the producer
     * @type {string}
     * @memberof IProducerIn
     */
    name?: string;
    /**
     * 
     * @type {IAddressIn}
     * @memberof IProducerIn
     */
    address?: IAddressIn;
    /**
     * Payment term for the producer (in days)
     * @type {number}
     * @memberof IProducerIn
     */
    paymentTerm?: number;
    /**
     * 
     * @type {IPaymentMethodEnum}
     * @memberof IProducerIn
     */
    paymentMethod?: IPaymentMethodEnum;
    /**
     * Listing fee for the producer
     * @type {number}
     * @memberof IProducerIn
     */
    listingFee?: number;
    /**
     * Positioning fee for the producer
     * @type {number}
     * @memberof IProducerIn
     */
    positioningFee?: number;
    /**
     * Communication fee for the producer
     * @type {number}
     * @memberof IProducerIn
     */
    communicationFee?: number;
    /**
     * Logistic discount for the producer
     * @type {number}
     * @memberof IProducerIn
     */
    logisticDiscount?: number;
    /**
     * Producer score
     * @type {number}
     * @memberof IProducerIn
     */
    score?: number;
    /**
     * 
     * @type {Array<IProductIn>}
     * @memberof IProducerIn
     */
    products?: Array<IProductIn>;
}
/**
 * 
 * @export
 * @interface IProducerListOut
 */
export interface IProducerListOut {
    /**
     * 
     * @type {string}
     * @memberof IProducerListOut
     */
    producerId?: string;
    /**
     * 
     * @type {string}
     * @memberof IProducerListOut
     */
    name?: string;
}
/**
 * Producer Information
 * @export
 * @interface IProducerOut
 */
export interface IProducerOut {
    /**
     * Unique identifier for the producer
     * @type {string}
     * @memberof IProducerOut
     */
    producerId?: string;
    /**
     * Name of the producer
     * @type {string}
     * @memberof IProducerOut
     */
    name?: string;
    /**
     * 
     * @type {IAddressOut}
     * @memberof IProducerOut
     */
    address?: IAddressOut;
    /**
     * Payment term for the producer (in days)
     * @type {number}
     * @memberof IProducerOut
     */
    paymentTerm?: number;
    /**
     * 
     * @type {IPaymentMethodEnum}
     * @memberof IProducerOut
     */
    paymentMethod?: IPaymentMethodEnum;
    /**
     * Listing fee for the producer
     * @type {number}
     * @memberof IProducerOut
     */
    listingFee?: number;
    /**
     * Positioning fee for the producer
     * @type {number}
     * @memberof IProducerOut
     */
    positioningFee?: number;
    /**
     * Communication fee for the producer
     * @type {number}
     * @memberof IProducerOut
     */
    communicationFee?: number;
    /**
     * Logistic discount for the producer
     * @type {number}
     * @memberof IProducerOut
     */
    logisticDiscount?: number;
    /**
     * Producer score
     * @type {number}
     * @memberof IProducerOut
     */
    score?: number;
    /**
     * 
     * @type {Array<IProductOut>}
     * @memberof IProducerOut
     */
    products?: Array<IProductOut>;
}
/**
 * 
 * @export
 * @interface IProductDaoListPaged
 */
export interface IProductDaoListPaged {
    /**
     * 
     * @type {Array<IProductOut>}
     * @memberof IProductDaoListPaged
     */
    productDaoList?: Array<IProductOut>;
    /**
     * 
     * @type {number}
     * @memberof IProductDaoListPaged
     */
    totalPagesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof IProductDaoListPaged
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof IProductDaoListPaged
     */
    pageSize?: number;
}
/**
 * 
 * @export
 * @interface IProductIn
 */
export interface IProductIn {
    /**
     * 
     * @type {string}
     * @memberof IProductIn
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof IProductIn
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IProductIn
     */
    picture?: string;
    /**
     * 
     * @type {number}
     * @memberof IProductIn
     */
    stock?: number;
    /**
     * 
     * @type {number}
     * @memberof IProductIn
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof IProductIn
     */
    productUnit?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IProductIn
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IProductIn
     */
    reserved?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IProductIn
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IProductIn
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IProductIn
     */
    producer?: string;
    /**
     * 
     * @type {string}
     * @memberof IProductIn
     */
    warehouse?: string;
}
/**
 * 
 * @export
 * @interface IProductOut
 */
export interface IProductOut {
    /**
     * 
     * @type {string}
     * @memberof IProductOut
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof IProductOut
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IProductOut
     */
    picture?: string;
    /**
     * 
     * @type {number}
     * @memberof IProductOut
     */
    stock?: number;
    /**
     * 
     * @type {number}
     * @memberof IProductOut
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof IProductOut
     */
    productUnit?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IProductOut
     */
    isActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IProductOut
     */
    reserved?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IProductOut
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IProductOut
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof IProductOut
     */
    producer?: string;
    /**
     * 
     * @type {string}
     * @memberof IProductOut
     */
    warehouse?: string;
}
/**
 * 
 * @export
 * @interface IRegisterRequest
 */
export interface IRegisterRequest {
    /**
     * name
     * @type {string}
     * @memberof IRegisterRequest
     */
    firstName: string;
    /**
     * name
     * @type {string}
     * @memberof IRegisterRequest
     */
    lastName: string;
    /**
     * email for current user
     * @type {string}
     * @memberof IRegisterRequest
     */
    email: string;
    /**
     * password
     * @type {string}
     * @memberof IRegisterRequest
     */
    password: string;
    /**
     * 
     * @type {ILanguageEnum}
     * @memberof IRegisterRequest
     */
    language: ILanguageEnum;
    /**
     * 
     * @type {IUserRoleEnum}
     * @memberof IRegisterRequest
     */
    role: IUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof IRegisterRequest
     */
    uuid?: string;
}
/**
 * 
 * @export
 * @interface IStoreDaoListPaged
 */
export interface IStoreDaoListPaged {
    /**
     * 
     * @type {Array<IStoreOut>}
     * @memberof IStoreDaoListPaged
     */
    storeDaoList?: Array<IStoreOut>;
    /**
     * 
     * @type {number}
     * @memberof IStoreDaoListPaged
     */
    totalPagesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof IStoreDaoListPaged
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof IStoreDaoListPaged
     */
    pageSize?: number;
}
/**
 * 
 * @export
 * @interface IStoreIn
 */
export interface IStoreIn {
    /**
     * 
     * @type {string}
     * @memberof IStoreIn
     */
    storeId?: string;
    /**
     * 
     * @type {string}
     * @memberof IStoreIn
     */
    name?: string;
    /**
     * 
     * @type {IAddressIn}
     * @memberof IStoreIn
     */
    storeAddress?: IAddressIn;
    /**
     * 
     * @type {number}
     * @memberof IStoreIn
     */
    paymentTerm?: number;
    /**
     * 
     * @type {IPaymentMethodEnum}
     * @memberof IStoreIn
     */
    paymentMethod?: IPaymentMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof IStoreIn
     */
    bonus?: number;
    /**
     * 
     * @type {number}
     * @memberof IStoreIn
     */
    volumeDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof IStoreIn
     */
    regularityDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof IStoreIn
     */
    exclusiveDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof IStoreIn
     */
    score?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IStoreIn
     */
    isActive?: boolean;
    /**
     * 
     * @type {IOrderIn}
     * @memberof IStoreIn
     */
    order?: IOrderIn;
    /**
     * 
     * @type {IInvoiceIn}
     * @memberof IStoreIn
     */
    invoice?: IInvoiceIn;
}
/**
 * 
 * @export
 * @interface IStoreListOut
 */
export interface IStoreListOut {
    /**
     * 
     * @type {string}
     * @memberof IStoreListOut
     */
    storeId?: string;
    /**
     * 
     * @type {string}
     * @memberof IStoreListOut
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface IStoreOut
 */
export interface IStoreOut {
    /**
     * 
     * @type {string}
     * @memberof IStoreOut
     */
    storeId?: string;
    /**
     * 
     * @type {string}
     * @memberof IStoreOut
     */
    name?: string;
    /**
     * 
     * @type {IAddressOut}
     * @memberof IStoreOut
     */
    storeAddress?: IAddressOut;
    /**
     * 
     * @type {number}
     * @memberof IStoreOut
     */
    paymentTerm?: number;
    /**
     * 
     * @type {IPaymentMethodEnum}
     * @memberof IStoreOut
     */
    paymentMethod?: IPaymentMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof IStoreOut
     */
    bonus?: number;
    /**
     * 
     * @type {number}
     * @memberof IStoreOut
     */
    volumeDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof IStoreOut
     */
    regularityDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof IStoreOut
     */
    exclusiveDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof IStoreOut
     */
    score?: number;
    /**
     * 
     * @type {boolean}
     * @memberof IStoreOut
     */
    isActive?: boolean;
    /**
     * 
     * @type {IOrderOut}
     * @memberof IStoreOut
     */
    order?: IOrderOut;
    /**
     * 
     * @type {IInvoiceOut}
     * @memberof IStoreOut
     */
    invoice?: IInvoiceOut;
}
/**
 * 
 * @export
 * @interface IUser
 */
export interface IUser {
    /**
     * user unique uuid
     * @type {string}
     * @memberof IUser
     */
    userId?: string;
    /**
     * email for current user
     * @type {string}
     * @memberof IUser
     */
    email?: string;
    /**
     * firstName
     * @type {string}
     * @memberof IUser
     */
    firstName?: string;
    /**
     * lastName
     * @type {string}
     * @memberof IUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    updatedAt?: string;
    /**
     * 
     * @type {ILanguageEnum}
     * @memberof IUser
     */
    language?: ILanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    stripeCustomerId?: string;
    /**
     * 
     * @type {IUserRoleEnum}
     * @memberof IUser
     */
    role?: IUserRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    producer?: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    store?: string;
    /**
     * 
     * @type {string}
     * @memberof IUser
     */
    warehouse?: string;
}
/**
 * 
 * @export
 * @interface IUserDaoListPaged
 */
export interface IUserDaoListPaged {
    /**
     * 
     * @type {Array<IUser>}
     * @memberof IUserDaoListPaged
     */
    userDaoList?: Array<IUser>;
    /**
     * 
     * @type {number}
     * @memberof IUserDaoListPaged
     */
    totalPagesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof IUserDaoListPaged
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof IUserDaoListPaged
     */
    pageSize?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum IUserRoleEnum {
    Store = 'Store',
    Producer = 'Producer',
    Warehouse = 'Warehouse',
    SysAdmin = 'SysAdmin'
}

/**
 * 
 * @export
 * @interface IWarehouseDaoListPaged
 */
export interface IWarehouseDaoListPaged {
    /**
     * 
     * @type {Array<IWarehouseOut>}
     * @memberof IWarehouseDaoListPaged
     */
    warehouseDaoList?: Array<IWarehouseOut>;
    /**
     * 
     * @type {number}
     * @memberof IWarehouseDaoListPaged
     */
    totalPagesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof IWarehouseDaoListPaged
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof IWarehouseDaoListPaged
     */
    pageSize?: number;
}
/**
 * 
 * @export
 * @interface IWarehouseIn
 */
export interface IWarehouseIn {
    /**
     * 
     * @type {string}
     * @memberof IWarehouseIn
     */
    warehouseId?: string;
    /**
     * 
     * @type {string}
     * @memberof IWarehouseIn
     */
    title?: string;
    /**
     * 
     * @type {IAddressIn}
     * @memberof IWarehouseIn
     */
    address?: IAddressIn;
}
/**
 * 
 * @export
 * @interface IWarehouseListOut
 */
export interface IWarehouseListOut {
    /**
     * 
     * @type {string}
     * @memberof IWarehouseListOut
     */
    warehouseId?: string;
    /**
     * 
     * @type {string}
     * @memberof IWarehouseListOut
     */
    title?: string;
}
/**
 * 
 * @export
 * @interface IWarehouseOut
 */
export interface IWarehouseOut {
    /**
     * 
     * @type {string}
     * @memberof IWarehouseOut
     */
    warehouseId?: string;
    /**
     * 
     * @type {string}
     * @memberof IWarehouseOut
     */
    title?: string;
    /**
     * 
     * @type {IAddressOut}
     * @memberof IWarehouseOut
     */
    address?: IAddressOut;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * gets the user info
         * @summary gets the user info
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getUser', 'uuid', uuid)
            const localVarPath = `/authentication/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User authenticate
         * @param {IJwtAuthenticationRequest} iJwtAuthenticationRequest Authentication request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (iJwtAuthenticationRequest: IJwtAuthenticationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iJwtAuthenticationRequest' is not null or undefined
            assertParamExists('login', 'iJwtAuthenticationRequest', iJwtAuthenticationRequest)
            const localVarPath = `/authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iJwtAuthenticationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * send refresh token to get new access token
         * @summary Get refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/authentication/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary register
         * @param {IRegisterRequest} iRegisterRequest send register request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (iRegisterRequest: IRegisterRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iRegisterRequest' is not null or undefined
            assertParamExists('register', 'iRegisterRequest', iRegisterRequest)
            const localVarPath = `/authentication/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * gets the user info
         * @summary gets the user info
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary User authenticate
         * @param {IJwtAuthenticationRequest} iJwtAuthenticationRequest Authentication request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(iJwtAuthenticationRequest: IJwtAuthenticationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(iJwtAuthenticationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * send refresh token to get new access token
         * @summary Get refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary register
         * @param {IRegisterRequest} iRegisterRequest send register request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(iRegisterRequest: IRegisterRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IJwtAuthenticationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(iRegisterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * gets the user info
         * @summary gets the user info
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(uuid: string, options?: any): AxiosPromise<IUser> {
            return localVarFp.getUser(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User authenticate
         * @param {IJwtAuthenticationRequest} iJwtAuthenticationRequest Authentication request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(iJwtAuthenticationRequest: IJwtAuthenticationRequest, options?: any): AxiosPromise<IJwtAuthenticationResponse> {
            return localVarFp.login(iJwtAuthenticationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * send refresh token to get new access token
         * @summary Get refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(options?: any): AxiosPromise<IJwtAuthenticationResponse> {
            return localVarFp.refresh(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary register
         * @param {IRegisterRequest} iRegisterRequest send register request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(iRegisterRequest: IRegisterRequest, options?: any): AxiosPromise<IJwtAuthenticationResponse> {
            return localVarFp.register(iRegisterRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * gets the user info
     * @summary gets the user info
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getUser(uuid: string, options?: any) {
        return AuthenticationApiFp(this.configuration).getUser(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User authenticate
     * @param {IJwtAuthenticationRequest} iJwtAuthenticationRequest Authentication request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public login(iJwtAuthenticationRequest: IJwtAuthenticationRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).login(iJwtAuthenticationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * send refresh token to get new access token
     * @summary Get refresh token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public refresh(options?: any) {
        return AuthenticationApiFp(this.configuration).refresh(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary register
     * @param {IRegisterRequest} iRegisterRequest send register request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public register(iRegisterRequest: IRegisterRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).register(iRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentApi - axios parameter creator
 * @export
 */
export const DocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * add products to an order
         * @summary add products to an order
         * @param {IInlineObject4} iInlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProduct: async (iInlineObject4: IInlineObject4, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInlineObject4' is not null or undefined
            assertParamExists('addProduct', 'iInlineObject4', iInlineObject4)
            const localVarPath = `/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iInlineObject4, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieve template
         * @summary retrieve template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serveTemplate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * upload products in single document
         * @summary upload products in single document
         * @param {any} file file
         * @param {string} producerId producerId
         * @param {string} warehouseId warehouseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProducts: async (file: any, producerId: string, warehouseId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadProducts', 'file', file)
            // verify required parameter 'producerId' is not null or undefined
            assertParamExists('uploadProducts', 'producerId', producerId)
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('uploadProducts', 'warehouseId', warehouseId)
            const localVarPath = `/document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (producerId !== undefined) { 
                localVarFormParams.append('producerId', new Blob([JSON.stringify(producerId)], { type: "application/json", }));
            }
    
            if (warehouseId !== undefined) { 
                localVarFormParams.append('warehouseId', new Blob([JSON.stringify(warehouseId)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentApi - functional programming interface
 * @export
 */
export const DocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * add products to an order
         * @summary add products to an order
         * @param {IInlineObject4} iInlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addProduct(iInlineObject4: IInlineObject4, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrderOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addProduct(iInlineObject4, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * retrieve template
         * @summary retrieve template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serveTemplate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serveTemplate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * upload products in single document
         * @summary upload products in single document
         * @param {any} file file
         * @param {string} producerId producerId
         * @param {string} warehouseId warehouseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadProducts(file: any, producerId: string, warehouseId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IProductOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadProducts(file, producerId, warehouseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentApi - factory interface
 * @export
 */
export const DocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentApiFp(configuration)
    return {
        /**
         * add products to an order
         * @summary add products to an order
         * @param {IInlineObject4} iInlineObject4 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProduct(iInlineObject4: IInlineObject4, options?: any): AxiosPromise<IOrderOut> {
            return localVarFp.addProduct(iInlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         * retrieve template
         * @summary retrieve template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serveTemplate(options?: any): AxiosPromise<string> {
            return localVarFp.serveTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * upload products in single document
         * @summary upload products in single document
         * @param {any} file file
         * @param {string} producerId producerId
         * @param {string} warehouseId warehouseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadProducts(file: any, producerId: string, warehouseId: string, options?: any): AxiosPromise<Array<IProductOut>> {
            return localVarFp.uploadProducts(file, producerId, warehouseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentApi - object-oriented interface
 * @export
 * @class DocumentApi
 * @extends {BaseAPI}
 */
export class DocumentApi extends BaseAPI {
    /**
     * add products to an order
     * @summary add products to an order
     * @param {IInlineObject4} iInlineObject4 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public addProduct(iInlineObject4: IInlineObject4, options?: any) {
        return DocumentApiFp(this.configuration).addProduct(iInlineObject4, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * retrieve template
     * @summary retrieve template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public serveTemplate(options?: any) {
        return DocumentApiFp(this.configuration).serveTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * upload products in single document
     * @summary upload products in single document
     * @param {any} file file
     * @param {string} producerId producerId
     * @param {string} warehouseId warehouseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentApi
     */
    public uploadProducts(file: any, producerId: string, warehouseId: string, options?: any) {
        return DocumentApiFp(this.configuration).uploadProducts(file, producerId, warehouseId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * execute an order
         * @summary execute an order
         * @param {IInlineObject5} iInlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeOrder: async (iInlineObject5: IInlineObject5, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInlineObject5' is not null or undefined
            assertParamExists('executeOrder', 'iInlineObject5', iInlineObject5)
            const localVarPath = `/order/execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iInlineObject5, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get all orders
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrders: async (pageSize: number, pageNumber: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAllOrders', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getAllOrders', 'pageNumber', pageNumber)
            const localVarPath = `/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets the order
         * @summary gets the order
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getOrder', 'uuid', uuid)
            const localVarPath = `/order/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get orders by warehouse id
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} warehouseId 
         * @param {IOrderStateEnum} [orderState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByWarehouse: async (pageSize: number, pageNumber: number, warehouseId: string, orderState?: IOrderStateEnum, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getOrdersByWarehouse', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getOrdersByWarehouse', 'pageNumber', pageNumber)
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('getOrdersByWarehouse', 'warehouseId', warehouseId)
            const localVarPath = `/order/byWarehouse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouseId'] = warehouseId;
            }

            if (orderState !== undefined) {
                localVarQueryParameter['orderState'] = orderState;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ship an order
         * @summary ship an order
         * @param {IInlineObject6} iInlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipOrder: async (iInlineObject6: IInlineObject6, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInlineObject6' is not null or undefined
            assertParamExists('shipOrder', 'iInlineObject6', iInlineObject6)
            const localVarPath = `/order/shipped`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iInlineObject6, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update order data
         * @param {IOrderIn} iOrderIn put order details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder: async (iOrderIn: IOrderIn, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iOrderIn' is not null or undefined
            assertParamExists('updateOrder', 'iOrderIn', iOrderIn)
            const localVarPath = `/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iOrderIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * execute an order
         * @summary execute an order
         * @param {IInlineObject5} iInlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executeOrder(iInlineObject5: IInlineObject5, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executeOrder(iInlineObject5, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get all orders
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOrders(pageSize: number, pageNumber: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrderDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOrders(pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets the order
         * @summary gets the order
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrder(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrderOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrder(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get orders by warehouse id
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} warehouseId 
         * @param {IOrderStateEnum} [orderState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersByWarehouse(pageSize: number, pageNumber: number, warehouseId: string, orderState?: IOrderStateEnum, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrderDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersByWarehouse(pageSize, pageNumber, warehouseId, orderState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ship an order
         * @summary ship an order
         * @param {IInlineObject6} iInlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipOrder(iInlineObject6: IInlineObject6, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shipOrder(iInlineObject6, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update order data
         * @param {IOrderIn} iOrderIn put order details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOrder(iOrderIn: IOrderIn, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrderOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOrder(iOrderIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * execute an order
         * @summary execute an order
         * @param {IInlineObject5} iInlineObject5 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeOrder(iInlineObject5: IInlineObject5, options?: any): AxiosPromise<string> {
            return localVarFp.executeOrder(iInlineObject5, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get all orders
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrders(pageSize: number, pageNumber: number, options?: any): AxiosPromise<IOrderDaoListPaged> {
            return localVarFp.getAllOrders(pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * gets the order
         * @summary gets the order
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(uuid: string, options?: any): AxiosPromise<IOrderOut> {
            return localVarFp.getOrder(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get orders by warehouse id
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {string} warehouseId 
         * @param {IOrderStateEnum} [orderState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByWarehouse(pageSize: number, pageNumber: number, warehouseId: string, orderState?: IOrderStateEnum, options?: any): AxiosPromise<IOrderDaoListPaged> {
            return localVarFp.getOrdersByWarehouse(pageSize, pageNumber, warehouseId, orderState, options).then((request) => request(axios, basePath));
        },
        /**
         * ship an order
         * @summary ship an order
         * @param {IInlineObject6} iInlineObject6 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipOrder(iInlineObject6: IInlineObject6, options?: any): AxiosPromise<string> {
            return localVarFp.shipOrder(iInlineObject6, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update order data
         * @param {IOrderIn} iOrderIn put order details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(iOrderIn: IOrderIn, options?: any): AxiosPromise<IOrderOut> {
            return localVarFp.updateOrder(iOrderIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * execute an order
     * @summary execute an order
     * @param {IInlineObject5} iInlineObject5 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public executeOrder(iInlineObject5: IInlineObject5, options?: any) {
        return OrderApiFp(this.configuration).executeOrder(iInlineObject5, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get all orders
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getAllOrders(pageSize: number, pageNumber: number, options?: any) {
        return OrderApiFp(this.configuration).getAllOrders(pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets the order
     * @summary gets the order
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrder(uuid: string, options?: any) {
        return OrderApiFp(this.configuration).getOrder(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get orders by warehouse id
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {string} warehouseId 
     * @param {IOrderStateEnum} [orderState] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public getOrdersByWarehouse(pageSize: number, pageNumber: number, warehouseId: string, orderState?: IOrderStateEnum, options?: any) {
        return OrderApiFp(this.configuration).getOrdersByWarehouse(pageSize, pageNumber, warehouseId, orderState, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ship an order
     * @summary ship an order
     * @param {IInlineObject6} iInlineObject6 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public shipOrder(iInlineObject6: IInlineObject6, options?: any) {
        return OrderApiFp(this.configuration).shipOrder(iInlineObject6, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update order data
     * @param {IOrderIn} iOrderIn put order details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public updateOrder(iOrderIn: IOrderIn, options?: any) {
        return OrderApiFp(this.configuration).updateOrder(iOrderIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderproductApi - axios parameter creator
 * @export
 */
export const OrderproductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * delete product from order
         * @summary delete product from order
         * @param {string} orderId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderRemoveProduct: async (orderId: string, productId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderRemoveProduct', 'orderId', orderId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('orderRemoveProduct', 'productId', productId)
            const localVarPath = `/orderproduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update product from order
         * @summary update product from order
         * @param {string} orderId 
         * @param {string} productId 
         * @param {number} quantity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdateQuantity: async (orderId: string, productId: string, quantity: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderUpdateQuantity', 'orderId', orderId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('orderUpdateQuantity', 'productId', productId)
            // verify required parameter 'quantity' is not null or undefined
            assertParamExists('orderUpdateQuantity', 'quantity', quantity)
            const localVarPath = `/orderproduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (quantity !== undefined) {
                localVarQueryParameter['quantity'] = quantity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderproductApi - functional programming interface
 * @export
 */
export const OrderproductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderproductApiAxiosParamCreator(configuration)
    return {
        /**
         * delete product from order
         * @summary delete product from order
         * @param {string} orderId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderRemoveProduct(orderId: string, productId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderRemoveProduct(orderId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update product from order
         * @summary update product from order
         * @param {string} orderId 
         * @param {string} productId 
         * @param {number} quantity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderUpdateQuantity(orderId: string, productId: string, quantity: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderUpdateQuantity(orderId, productId, quantity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderproductApi - factory interface
 * @export
 */
export const OrderproductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderproductApiFp(configuration)
    return {
        /**
         * delete product from order
         * @summary delete product from order
         * @param {string} orderId 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderRemoveProduct(orderId: string, productId: string, options?: any): AxiosPromise<string> {
            return localVarFp.orderRemoveProduct(orderId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * update product from order
         * @summary update product from order
         * @param {string} orderId 
         * @param {string} productId 
         * @param {number} quantity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdateQuantity(orderId: string, productId: string, quantity: number, options?: any): AxiosPromise<string> {
            return localVarFp.orderUpdateQuantity(orderId, productId, quantity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderproductApi - object-oriented interface
 * @export
 * @class OrderproductApi
 * @extends {BaseAPI}
 */
export class OrderproductApi extends BaseAPI {
    /**
     * delete product from order
     * @summary delete product from order
     * @param {string} orderId 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderproductApi
     */
    public orderRemoveProduct(orderId: string, productId: string, options?: any) {
        return OrderproductApiFp(this.configuration).orderRemoveProduct(orderId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update product from order
     * @summary update product from order
     * @param {string} orderId 
     * @param {string} productId 
     * @param {number} quantity 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderproductApi
     */
    public orderUpdateQuantity(orderId: string, productId: string, quantity: number, options?: any) {
        return OrderproductApiFp(this.configuration).orderUpdateQuantity(orderId, productId, quantity, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProducerApi - axios parameter creator
 * @export
 */
export const ProducerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete producer data
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProducerById: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteProducerById', 'uuid', uuid)
            const localVarPath = `/producer/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary producer get
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProducer: async (pageSize: number, pageNumber: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAllProducer', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getAllProducer', 'pageNumber', pageNumber)
            const localVarPath = `/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets the producer
         * @summary gets the producer
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducerById: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getProducerById', 'uuid', uuid)
            const localVarPath = `/producer/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get product by user id
         * @summary get product by user id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducerByUserId: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getProducerByUserId', 'uuid', uuid)
            const localVarPath = `/producer/byUserId/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary producer get list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducerList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/producer/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary new user for a producer
         * @param {IRegisterRequest} iRegisterRequest new user for a producer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUserProducer: async (iRegisterRequest: IRegisterRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iRegisterRequest' is not null or undefined
            assertParamExists('registerNewUserProducer', 'iRegisterRequest', iRegisterRequest)
            const localVarPath = `/producer/newUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saving producer
         * @param {IProducerIn} iProducerIn send save producer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerProducer: async (iProducerIn: IProducerIn, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iProducerIn' is not null or undefined
            assertParamExists('registerProducer', 'iProducerIn', iProducerIn)
            const localVarPath = `/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProducerIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update producer data
         * @param {IProducerIn} iProducerIn put store details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProducer: async (iProducerIn: IProducerIn, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iProducerIn' is not null or undefined
            assertParamExists('updateProducer', 'iProducerIn', iProducerIn)
            const localVarPath = `/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProducerIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProducerApi - functional programming interface
 * @export
 */
export const ProducerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProducerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete producer data
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProducerById(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProducerById(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary producer get
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProducer(pageSize: number, pageNumber: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProducerDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProducer(pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets the producer
         * @summary gets the producer
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducerById(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProducerOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducerById(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get product by user id
         * @summary get product by user id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducerByUserId(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProducerOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducerByUserId(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary producer get list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducerList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IProducerListOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducerList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary new user for a producer
         * @param {IRegisterRequest} iRegisterRequest new user for a producer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerNewUserProducer(iRegisterRequest: IRegisterRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerNewUserProducer(iRegisterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary saving producer
         * @param {IProducerIn} iProducerIn send save producer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerProducer(iProducerIn: IProducerIn, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProducerOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerProducer(iProducerIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update producer data
         * @param {IProducerIn} iProducerIn put store details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProducer(iProducerIn: IProducerIn, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProducerOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProducer(iProducerIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProducerApi - factory interface
 * @export
 */
export const ProducerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProducerApiFp(configuration)
    return {
        /**
         * 
         * @summary delete producer data
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProducerById(uuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteProducerById(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary producer get
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProducer(pageSize: number, pageNumber: number, options?: any): AxiosPromise<IProducerDaoListPaged> {
            return localVarFp.getAllProducer(pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * gets the producer
         * @summary gets the producer
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducerById(uuid: string, options?: any): AxiosPromise<IProducerOut> {
            return localVarFp.getProducerById(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get product by user id
         * @summary get product by user id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducerByUserId(uuid: string, options?: any): AxiosPromise<IProducerOut> {
            return localVarFp.getProducerByUserId(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary producer get list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducerList(options?: any): AxiosPromise<Array<IProducerListOut>> {
            return localVarFp.getProducerList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary new user for a producer
         * @param {IRegisterRequest} iRegisterRequest new user for a producer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUserProducer(iRegisterRequest: IRegisterRequest, options?: any): AxiosPromise<string> {
            return localVarFp.registerNewUserProducer(iRegisterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saving producer
         * @param {IProducerIn} iProducerIn send save producer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerProducer(iProducerIn: IProducerIn, options?: any): AxiosPromise<IProducerOut> {
            return localVarFp.registerProducer(iProducerIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update producer data
         * @param {IProducerIn} iProducerIn put store details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProducer(iProducerIn: IProducerIn, options?: any): AxiosPromise<IProducerOut> {
            return localVarFp.updateProducer(iProducerIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProducerApi - object-oriented interface
 * @export
 * @class ProducerApi
 * @extends {BaseAPI}
 */
export class ProducerApi extends BaseAPI {
    /**
     * 
     * @summary delete producer data
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProducerApi
     */
    public deleteProducerById(uuid: string, options?: any) {
        return ProducerApiFp(this.configuration).deleteProducerById(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary producer get
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProducerApi
     */
    public getAllProducer(pageSize: number, pageNumber: number, options?: any) {
        return ProducerApiFp(this.configuration).getAllProducer(pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets the producer
     * @summary gets the producer
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProducerApi
     */
    public getProducerById(uuid: string, options?: any) {
        return ProducerApiFp(this.configuration).getProducerById(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get product by user id
     * @summary get product by user id
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProducerApi
     */
    public getProducerByUserId(uuid: string, options?: any) {
        return ProducerApiFp(this.configuration).getProducerByUserId(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary producer get list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProducerApi
     */
    public getProducerList(options?: any) {
        return ProducerApiFp(this.configuration).getProducerList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary new user for a producer
     * @param {IRegisterRequest} iRegisterRequest new user for a producer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProducerApi
     */
    public registerNewUserProducer(iRegisterRequest: IRegisterRequest, options?: any) {
        return ProducerApiFp(this.configuration).registerNewUserProducer(iRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saving producer
     * @param {IProducerIn} iProducerIn send save producer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProducerApi
     */
    public registerProducer(iProducerIn: IProducerIn, options?: any) {
        return ProducerApiFp(this.configuration).registerProducer(iProducerIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update producer data
     * @param {IProducerIn} iProducerIn put store details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProducerApi
     */
    public updateProducer(iProducerIn: IProducerIn, options?: any) {
        return ProducerApiFp(this.configuration).updateProducer(iProducerIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete product data
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductById: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteProductById', 'uuid', uuid)
            const localVarPath = `/product/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary product get
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {boolean} [isActive] 
         * @param {boolean} [hasStock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProduct: async (pageSize: number, pageNumber: number, isActive?: boolean, hasStock?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAllProduct', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getAllProduct', 'pageNumber', pageNumber)
            const localVarPath = `/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (hasStock !== undefined) {
                localVarQueryParameter['hasStock'] = hasStock;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieve image
         * @summary retrieve image
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinioImage: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getMinioImage', 'uuid', uuid)
            const localVarPath = `/product/image/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get the products of a producer
         * @param {string} producerId 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForProducer: async (producerId: string, pageSize: number, pageNumber: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'producerId' is not null or undefined
            assertParamExists('getProductsForProducer', 'producerId', producerId)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getProductsForProducer', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getProductsForProducer', 'pageNumber', pageNumber)
            const localVarPath = `/product/producer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (producerId !== undefined) {
                localVarQueryParameter['producerId'] = producerId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * save or update product
         * @summary save or update product
         * @param {any} [file] file
         * @param {IProductIn} [productIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProduct: async (file?: any, productIn?: IProductIn, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (productIn !== undefined) { 
                localVarFormParams.append('productIn', new Blob([JSON.stringify(productIn)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * save product\'s images with associated data
         * @summary save product\'s images
         * @param {Array<any>} filenameList 
         * @param {string} imagesIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProductsImages: async (filenameList: Array<any>, imagesIn: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filenameList' is not null or undefined
            assertParamExists('saveProductsImages', 'filenameList', filenameList)
            // verify required parameter 'imagesIn' is not null or undefined
            assertParamExists('saveProductsImages', 'imagesIn', imagesIn)
            const localVarPath = `/product/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (filenameList) {
                filenameList.forEach((element) => {
                    localVarFormParams.append('filenameList', element as any);
                })
            }

    
            if (imagesIn !== undefined) { 
                localVarFormParams.append('imagesIn', imagesIn as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update product data
         * @param {IProductIn} iProductIn put product details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (iProductIn: IProductIn, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iProductIn' is not null or undefined
            assertParamExists('updateProduct', 'iProductIn', iProductIn)
            const localVarPath = `/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iProductIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete product data
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductById(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductById(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary product get
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {boolean} [isActive] 
         * @param {boolean} [hasStock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProduct(pageSize: number, pageNumber: number, isActive?: boolean, hasStock?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProduct(pageSize, pageNumber, isActive, hasStock, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * retrieve image
         * @summary retrieve image
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMinioImage(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMinioImage(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get the products of a producer
         * @param {string} producerId 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsForProducer(producerId: string, pageSize: number, pageNumber: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsForProducer(producerId, pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * save or update product
         * @summary save or update product
         * @param {any} [file] file
         * @param {IProductIn} [productIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveProduct(file?: any, productIn?: IProductIn, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveProduct(file, productIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * save product\'s images with associated data
         * @summary save product\'s images
         * @param {Array<any>} filenameList 
         * @param {string} imagesIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveProductsImages(filenameList: Array<any>, imagesIn: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IProductOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveProductsImages(filenameList, imagesIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update product data
         * @param {IProductIn} iProductIn put product details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(iProductIn: IProductIn, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(iProductIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @summary delete product data
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductById(uuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteProductById(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary product get
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {boolean} [isActive] 
         * @param {boolean} [hasStock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProduct(pageSize: number, pageNumber: number, isActive?: boolean, hasStock?: boolean, options?: any): AxiosPromise<IProductDaoListPaged> {
            return localVarFp.getAllProduct(pageSize, pageNumber, isActive, hasStock, options).then((request) => request(axios, basePath));
        },
        /**
         * retrieve image
         * @summary retrieve image
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMinioImage(uuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.getMinioImage(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get the products of a producer
         * @param {string} producerId 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForProducer(producerId: string, pageSize: number, pageNumber: number, options?: any): AxiosPromise<IProductDaoListPaged> {
            return localVarFp.getProductsForProducer(producerId, pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * save or update product
         * @summary save or update product
         * @param {any} [file] file
         * @param {IProductIn} [productIn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProduct(file?: any, productIn?: IProductIn, options?: any): AxiosPromise<IProductOut> {
            return localVarFp.saveProduct(file, productIn, options).then((request) => request(axios, basePath));
        },
        /**
         * save product\'s images with associated data
         * @summary save product\'s images
         * @param {Array<any>} filenameList 
         * @param {string} imagesIn 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveProductsImages(filenameList: Array<any>, imagesIn: string, options?: any): AxiosPromise<Array<IProductOut>> {
            return localVarFp.saveProductsImages(filenameList, imagesIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update product data
         * @param {IProductIn} iProductIn put product details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(iProductIn: IProductIn, options?: any): AxiosPromise<IProductOut> {
            return localVarFp.updateProduct(iProductIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @summary delete product data
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public deleteProductById(uuid: string, options?: any) {
        return ProductApiFp(this.configuration).deleteProductById(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary product get
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {boolean} [isActive] 
     * @param {boolean} [hasStock] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getAllProduct(pageSize: number, pageNumber: number, isActive?: boolean, hasStock?: boolean, options?: any) {
        return ProductApiFp(this.configuration).getAllProduct(pageSize, pageNumber, isActive, hasStock, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * retrieve image
     * @summary retrieve image
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getMinioImage(uuid: string, options?: any) {
        return ProductApiFp(this.configuration).getMinioImage(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get the products of a producer
     * @param {string} producerId 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public getProductsForProducer(producerId: string, pageSize: number, pageNumber: number, options?: any) {
        return ProductApiFp(this.configuration).getProductsForProducer(producerId, pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * save or update product
     * @summary save or update product
     * @param {any} [file] file
     * @param {IProductIn} [productIn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public saveProduct(file?: any, productIn?: IProductIn, options?: any) {
        return ProductApiFp(this.configuration).saveProduct(file, productIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * save product\'s images with associated data
     * @summary save product\'s images
     * @param {Array<any>} filenameList 
     * @param {string} imagesIn 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public saveProductsImages(filenameList: Array<any>, imagesIn: string, options?: any) {
        return ProductApiFp(this.configuration).saveProductsImages(filenameList, imagesIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update product data
     * @param {IProductIn} iProductIn put product details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public updateProduct(iProductIn: IProductIn, options?: any) {
        return ProductApiFp(this.configuration).updateProduct(iProductIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoreApi - axios parameter creator
 * @export
 */
export const StoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete store data
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreById: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteStoreById', 'uuid', uuid)
            const localVarPath = `/store/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary store get
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStore: async (pageSize: number, pageNumber: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAllStore', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getAllStore', 'pageNumber', pageNumber)
            const localVarPath = `/store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get order by store id
         * @summary get order by store id
         * @param {string} uuid 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {IOrderStateEnum} [orderState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByStoreId: async (uuid: string, pageSize?: number, pageNumber?: number, orderState?: IOrderStateEnum, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getOrdersByStoreId', 'uuid', uuid)
            const localVarPath = `/store/ordersByStoreId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (orderState !== undefined) {
                localVarQueryParameter['orderState'] = orderState;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get store by id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreById: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getStoreById', 'uuid', uuid)
            const localVarPath = `/store/byId/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get store by user id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreByUser: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getStoreByUser', 'uuid', uuid)
            const localVarPath = `/store/byUserId/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary store get list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/store/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get warehouse by store id
         * @summary get warehouse by store id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarehouseByStoreId: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getWarehouseByStoreId', 'uuid', uuid)
            const localVarPath = `/store/warehouseByStoreId/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary new user for a store
         * @param {IRegisterRequest} iRegisterRequest new user for a store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUserStore: async (iRegisterRequest: IRegisterRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iRegisterRequest' is not null or undefined
            assertParamExists('registerNewUserStore', 'iRegisterRequest', iRegisterRequest)
            const localVarPath = `/store/newUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saving store
         * @param {IStoreIn} iStoreIn send save store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerStore: async (iStoreIn: IStoreIn, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iStoreIn' is not null or undefined
            assertParamExists('registerStore', 'iStoreIn', iStoreIn)
            const localVarPath = `/store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iStoreIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update store data
         * @param {IStoreIn} iStoreIn put store details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStore: async (iStoreIn: IStoreIn, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iStoreIn' is not null or undefined
            assertParamExists('updateStore', 'iStoreIn', iStoreIn)
            const localVarPath = `/store`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iStoreIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreApi - functional programming interface
 * @export
 */
export const StoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete store data
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteStoreById(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStoreById(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary store get
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllStore(pageSize: number, pageNumber: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStoreDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllStore(pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get order by store id
         * @summary get order by store id
         * @param {string} uuid 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {IOrderStateEnum} [orderState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersByStoreId(uuid: string, pageSize?: number, pageNumber?: number, orderState?: IOrderStateEnum, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IOrderDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrdersByStoreId(uuid, pageSize, pageNumber, orderState, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get store by id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreById(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStoreOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreById(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get store by user id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreByUser(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStoreOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreByUser(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary store get list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IStoreListOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStoreList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get warehouse by store id
         * @summary get warehouse by store id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWarehouseByStoreId(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IWarehouseOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWarehouseByStoreId(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary new user for a store
         * @param {IRegisterRequest} iRegisterRequest new user for a store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerNewUserStore(iRegisterRequest: IRegisterRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerNewUserStore(iRegisterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary saving store
         * @param {IStoreIn} iStoreIn send save store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerStore(iStoreIn: IStoreIn, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStoreOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerStore(iStoreIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update store data
         * @param {IStoreIn} iStoreIn put store details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStore(iStoreIn: IStoreIn, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStoreOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStore(iStoreIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreApi - factory interface
 * @export
 */
export const StoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreApiFp(configuration)
    return {
        /**
         * 
         * @summary delete store data
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStoreById(uuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteStoreById(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary store get
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStore(pageSize: number, pageNumber: number, options?: any): AxiosPromise<IStoreDaoListPaged> {
            return localVarFp.getAllStore(pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * get order by store id
         * @summary get order by store id
         * @param {string} uuid 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {IOrderStateEnum} [orderState] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByStoreId(uuid: string, pageSize?: number, pageNumber?: number, orderState?: IOrderStateEnum, options?: any): AxiosPromise<IOrderDaoListPaged> {
            return localVarFp.getOrdersByStoreId(uuid, pageSize, pageNumber, orderState, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get store by id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreById(uuid: string, options?: any): AxiosPromise<IStoreOut> {
            return localVarFp.getStoreById(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get store by user id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreByUser(uuid: string, options?: any): AxiosPromise<IStoreOut> {
            return localVarFp.getStoreByUser(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary store get list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreList(options?: any): AxiosPromise<Array<IStoreListOut>> {
            return localVarFp.getStoreList(options).then((request) => request(axios, basePath));
        },
        /**
         * get warehouse by store id
         * @summary get warehouse by store id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarehouseByStoreId(uuid: string, options?: any): AxiosPromise<IWarehouseOut> {
            return localVarFp.getWarehouseByStoreId(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary new user for a store
         * @param {IRegisterRequest} iRegisterRequest new user for a store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUserStore(iRegisterRequest: IRegisterRequest, options?: any): AxiosPromise<string> {
            return localVarFp.registerNewUserStore(iRegisterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saving store
         * @param {IStoreIn} iStoreIn send save store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerStore(iStoreIn: IStoreIn, options?: any): AxiosPromise<IStoreOut> {
            return localVarFp.registerStore(iStoreIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update store data
         * @param {IStoreIn} iStoreIn put store details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStore(iStoreIn: IStoreIn, options?: any): AxiosPromise<IStoreOut> {
            return localVarFp.updateStore(iStoreIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreApi - object-oriented interface
 * @export
 * @class StoreApi
 * @extends {BaseAPI}
 */
export class StoreApi extends BaseAPI {
    /**
     * 
     * @summary delete store data
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public deleteStoreById(uuid: string, options?: any) {
        return StoreApiFp(this.configuration).deleteStoreById(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary store get
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getAllStore(pageSize: number, pageNumber: number, options?: any) {
        return StoreApiFp(this.configuration).getAllStore(pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get order by store id
     * @summary get order by store id
     * @param {string} uuid 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {IOrderStateEnum} [orderState] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getOrdersByStoreId(uuid: string, pageSize?: number, pageNumber?: number, orderState?: IOrderStateEnum, options?: any) {
        return StoreApiFp(this.configuration).getOrdersByStoreId(uuid, pageSize, pageNumber, orderState, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get store by id
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreById(uuid: string, options?: any) {
        return StoreApiFp(this.configuration).getStoreById(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get store by user id
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreByUser(uuid: string, options?: any) {
        return StoreApiFp(this.configuration).getStoreByUser(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary store get list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getStoreList(options?: any) {
        return StoreApiFp(this.configuration).getStoreList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get warehouse by store id
     * @summary get warehouse by store id
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getWarehouseByStoreId(uuid: string, options?: any) {
        return StoreApiFp(this.configuration).getWarehouseByStoreId(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary new user for a store
     * @param {IRegisterRequest} iRegisterRequest new user for a store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public registerNewUserStore(iRegisterRequest: IRegisterRequest, options?: any) {
        return StoreApiFp(this.configuration).registerNewUserStore(iRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saving store
     * @param {IStoreIn} iStoreIn send save store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public registerStore(iStoreIn: IStoreIn, options?: any) {
        return StoreApiFp(this.configuration).registerStore(iStoreIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update store data
     * @param {IStoreIn} iStoreIn put store details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public updateStore(iStoreIn: IStoreIn, options?: any) {
        return StoreApiFp(this.configuration).updateStore(iStoreIn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary validates token to change password
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('changePassword', 'token', token)
            const localVarPath = `/users/savePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create user
         * @param {IInlineObject1} iInlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (iInlineObject1: IInlineObject1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iInlineObject1' is not null or undefined
            assertParamExists('createUser', 'iInlineObject1', iInlineObject1)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iInlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reset user password
         * @param {IEmailIn} iEmailIn reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (iEmailIn: IEmailIn, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iEmailIn' is not null or undefined
            assertParamExists('resetPassword', 'iEmailIn', iEmailIn)
            const localVarPath = `/users/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iEmailIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary save new user password
         * @param {IPasswordIn} iPasswordIn save new user password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePassword: async (iPasswordIn: IPasswordIn, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iPasswordIn' is not null or undefined
            assertParamExists('savePassword', 'iPasswordIn', iPasswordIn)
            const localVarPath = `/users/savePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iPasswordIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary search for producer users
         * @param {string} producerId 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProducerUsers: async (producerId: string, pageSize: number, pageNumber: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'producerId' is not null or undefined
            assertParamExists('searchProducerUsers', 'producerId', producerId)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('searchProducerUsers', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('searchProducerUsers', 'pageNumber', pageNumber)
            const localVarPath = `/users/byProducerId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (producerId !== undefined) {
                localVarQueryParameter['producerId'] = producerId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary search for store users
         * @param {string} storeId 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchStoreUsers: async (storeId: string, pageSize: number, pageNumber: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('searchStoreUsers', 'storeId', storeId)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('searchStoreUsers', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('searchStoreUsers', 'pageNumber', pageNumber)
            const localVarPath = `/users/byStoreId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search for users
         * @param {string} [email] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers: async (email?: string, pageSize?: number, pageNumber?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary search for warehouse users
         * @param {string} warehouseId 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchWarehouseUsers: async (warehouseId: string, pageSize: number, pageNumber: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists('searchWarehouseUsers', 'warehouseId', warehouseId)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('searchWarehouseUsers', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('searchWarehouseUsers', 'pageNumber', pageNumber)
            const localVarPath = `/users/byWarehouseId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouseId'] = warehouseId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update user
         * @param {IUser} iUser The user object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (iUser: IUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iUser' is not null or undefined
            assertParamExists('updateUser', 'iUser', iUser)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary validates token to change password
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create user
         * @param {IInlineObject1} iInlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(iInlineObject1: IInlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(iInlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary delete user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary reset user password
         * @param {IEmailIn} iEmailIn reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(iEmailIn: IEmailIn, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(iEmailIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary save new user password
         * @param {IPasswordIn} iPasswordIn save new user password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePassword(iPasswordIn: IPasswordIn, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePassword(iPasswordIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary search for producer users
         * @param {string} producerId 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProducerUsers(producerId: string, pageSize: number, pageNumber: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProducerUsers(producerId, pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary search for store users
         * @param {string} storeId 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchStoreUsers(storeId: string, pageSize: number, pageNumber: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchStoreUsers(storeId, pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search for users
         * @param {string} [email] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsers(email?: string, pageSize?: number, pageNumber?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUsers(email, pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary search for warehouse users
         * @param {string} warehouseId 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchWarehouseUsers(warehouseId: string, pageSize: number, pageNumber: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IUserDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchWarehouseUsers(warehouseId, pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update user
         * @param {IUser} iUser The user object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(iUser: IUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(iUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary validates token to change password
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(token: string, options?: any): AxiosPromise<string> {
            return localVarFp.changePassword(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create user
         * @param {IInlineObject1} iInlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(iInlineObject1: IInlineObject1, options?: any): AxiosPromise<IUser> {
            return localVarFp.createUser(iInlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary delete user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get user
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<IUser> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary reset user password
         * @param {IEmailIn} iEmailIn reset password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(iEmailIn: IEmailIn, options?: any): AxiosPromise<string> {
            return localVarFp.resetPassword(iEmailIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary save new user password
         * @param {IPasswordIn} iPasswordIn save new user password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePassword(iPasswordIn: IPasswordIn, options?: any): AxiosPromise<string> {
            return localVarFp.savePassword(iPasswordIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary search for producer users
         * @param {string} producerId 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProducerUsers(producerId: string, pageSize: number, pageNumber: number, options?: any): AxiosPromise<IUserDaoListPaged> {
            return localVarFp.searchProducerUsers(producerId, pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary search for store users
         * @param {string} storeId 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchStoreUsers(storeId: string, pageSize: number, pageNumber: number, options?: any): AxiosPromise<IUserDaoListPaged> {
            return localVarFp.searchStoreUsers(storeId, pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search for users
         * @param {string} [email] 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers(email?: string, pageSize?: number, pageNumber?: number, options?: any): AxiosPromise<IUserDaoListPaged> {
            return localVarFp.searchUsers(email, pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary search for warehouse users
         * @param {string} warehouseId 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchWarehouseUsers(warehouseId: string, pageSize: number, pageNumber: number, options?: any): AxiosPromise<IUserDaoListPaged> {
            return localVarFp.searchWarehouseUsers(warehouseId, pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update user
         * @param {IUser} iUser The user object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(iUser: IUser, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(iUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary validates token to change password
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changePassword(token: string, options?: any) {
        return UsersApiFp(this.configuration).changePassword(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create user
     * @param {IInlineObject1} iInlineObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(iInlineObject1: IInlineObject1, options?: any) {
        return UsersApiFp(this.configuration).createUser(iInlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary delete user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(userId: string, options?: any) {
        return UsersApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get user
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(userId: string, options?: any) {
        return UsersApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary reset user password
     * @param {IEmailIn} iEmailIn reset password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetPassword(iEmailIn: IEmailIn, options?: any) {
        return UsersApiFp(this.configuration).resetPassword(iEmailIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary save new user password
     * @param {IPasswordIn} iPasswordIn save new user password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public savePassword(iPasswordIn: IPasswordIn, options?: any) {
        return UsersApiFp(this.configuration).savePassword(iPasswordIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary search for producer users
     * @param {string} producerId 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public searchProducerUsers(producerId: string, pageSize: number, pageNumber: number, options?: any) {
        return UsersApiFp(this.configuration).searchProducerUsers(producerId, pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary search for store users
     * @param {string} storeId 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public searchStoreUsers(storeId: string, pageSize: number, pageNumber: number, options?: any) {
        return UsersApiFp(this.configuration).searchStoreUsers(storeId, pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search for users
     * @param {string} [email] 
     * @param {number} [pageSize] 
     * @param {number} [pageNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public searchUsers(email?: string, pageSize?: number, pageNumber?: number, options?: any) {
        return UsersApiFp(this.configuration).searchUsers(email, pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary search for warehouse users
     * @param {string} warehouseId 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public searchWarehouseUsers(warehouseId: string, pageSize: number, pageNumber: number, options?: any) {
        return UsersApiFp(this.configuration).searchWarehouseUsers(warehouseId, pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update user
     * @param {IUser} iUser The user object to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(iUser: IUser, options?: any) {
        return UsersApiFp(this.configuration).updateUser(iUser, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarehouseApi - axios parameter creator
 * @export
 */
export const WarehouseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary delete warehouse data
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWarehouseById: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('deleteWarehouseById', 'uuid', uuid)
            const localVarPath = `/warehouse/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary warehouse get
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWarehouse: async (pageSize: number, pageNumber: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getAllWarehouse', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getAllWarehouse', 'pageNumber', pageNumber)
            const localVarPath = `/warehouse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get products of a warehouse
         * @summary get products of a warehouse
         * @param {string} uuid 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {boolean} [isActive] 
         * @param {boolean} [hasStock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsByWarehouse: async (uuid: string, pageSize: number, pageNumber: number, isActive?: boolean, hasStock?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getProductsByWarehouse', 'uuid', uuid)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('getProductsByWarehouse', 'pageSize', pageSize)
            // verify required parameter 'pageNumber' is not null or undefined
            assertParamExists('getProductsByWarehouse', 'pageNumber', pageNumber)
            const localVarPath = `/warehouse/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (uuid !== undefined) {
                localVarQueryParameter['uuid'] = uuid;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (hasStock !== undefined) {
                localVarQueryParameter['hasStock'] = hasStock;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * gets the warehouse
         * @summary gets the warehouse
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarehouseById: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getWarehouseById', 'uuid', uuid)
            const localVarPath = `/warehouse/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get warehouse by user id
         * @summary get warehouse by user id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarehouseByUserId: async (uuid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uuid' is not null or undefined
            assertParamExists('getWarehouseByUserId', 'uuid', uuid)
            const localVarPath = `/warehouse/byUserId/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary warehouse get list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarehouseList: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/warehouse/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary new user for warehouse
         * @param {IRegisterRequest} iRegisterRequest new user for warehouse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUserWarehouse: async (iRegisterRequest: IRegisterRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iRegisterRequest' is not null or undefined
            assertParamExists('registerNewUserWarehouse', 'iRegisterRequest', iRegisterRequest)
            const localVarPath = `/warehouse/newUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iRegisterRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saving warehouse
         * @param {IWarehouseIn} iWarehouseIn send save warehouse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWarehouse: async (iWarehouseIn: IWarehouseIn, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iWarehouseIn' is not null or undefined
            assertParamExists('registerWarehouse', 'iWarehouseIn', iWarehouseIn)
            const localVarPath = `/warehouse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iWarehouseIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update warehouse data
         * @param {IWarehouseIn} iWarehouseIn put warehouse details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarehouse: async (iWarehouseIn: IWarehouseIn, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'iWarehouseIn' is not null or undefined
            assertParamExists('updateWarehouse', 'iWarehouseIn', iWarehouseIn)
            const localVarPath = `/warehouse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(iWarehouseIn, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarehouseApi - functional programming interface
 * @export
 */
export const WarehouseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarehouseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary delete warehouse data
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWarehouseById(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWarehouseById(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary warehouse get
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWarehouse(pageSize: number, pageNumber: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IWarehouseDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWarehouse(pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get products of a warehouse
         * @summary get products of a warehouse
         * @param {string} uuid 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {boolean} [isActive] 
         * @param {boolean} [hasStock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsByWarehouse(uuid: string, pageSize: number, pageNumber: number, isActive?: boolean, hasStock?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IProductDaoListPaged>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsByWarehouse(uuid, pageSize, pageNumber, isActive, hasStock, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * gets the warehouse
         * @summary gets the warehouse
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWarehouseById(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IWarehouseOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWarehouseById(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get warehouse by user id
         * @summary get warehouse by user id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWarehouseByUserId(uuid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IWarehouseOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWarehouseByUserId(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary warehouse get list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWarehouseList(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IWarehouseListOut>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWarehouseList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary new user for warehouse
         * @param {IRegisterRequest} iRegisterRequest new user for warehouse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerNewUserWarehouse(iRegisterRequest: IRegisterRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerNewUserWarehouse(iRegisterRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary saving warehouse
         * @param {IWarehouseIn} iWarehouseIn send save warehouse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerWarehouse(iWarehouseIn: IWarehouseIn, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IWarehouseOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerWarehouse(iWarehouseIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update warehouse data
         * @param {IWarehouseIn} iWarehouseIn put warehouse details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWarehouse(iWarehouseIn: IWarehouseIn, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IWarehouseOut>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWarehouse(iWarehouseIn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarehouseApi - factory interface
 * @export
 */
export const WarehouseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarehouseApiFp(configuration)
    return {
        /**
         * 
         * @summary delete warehouse data
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWarehouseById(uuid: string, options?: any): AxiosPromise<string> {
            return localVarFp.deleteWarehouseById(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary warehouse get
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWarehouse(pageSize: number, pageNumber: number, options?: any): AxiosPromise<IWarehouseDaoListPaged> {
            return localVarFp.getAllWarehouse(pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * get products of a warehouse
         * @summary get products of a warehouse
         * @param {string} uuid 
         * @param {number} pageSize 
         * @param {number} pageNumber 
         * @param {boolean} [isActive] 
         * @param {boolean} [hasStock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsByWarehouse(uuid: string, pageSize: number, pageNumber: number, isActive?: boolean, hasStock?: boolean, options?: any): AxiosPromise<IProductDaoListPaged> {
            return localVarFp.getProductsByWarehouse(uuid, pageSize, pageNumber, isActive, hasStock, options).then((request) => request(axios, basePath));
        },
        /**
         * gets the warehouse
         * @summary gets the warehouse
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarehouseById(uuid: string, options?: any): AxiosPromise<IWarehouseOut> {
            return localVarFp.getWarehouseById(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * get warehouse by user id
         * @summary get warehouse by user id
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarehouseByUserId(uuid: string, options?: any): AxiosPromise<IWarehouseOut> {
            return localVarFp.getWarehouseByUserId(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary warehouse get list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWarehouseList(options?: any): AxiosPromise<Array<IWarehouseListOut>> {
            return localVarFp.getWarehouseList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary new user for warehouse
         * @param {IRegisterRequest} iRegisterRequest new user for warehouse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNewUserWarehouse(iRegisterRequest: IRegisterRequest, options?: any): AxiosPromise<string> {
            return localVarFp.registerNewUserWarehouse(iRegisterRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary saving warehouse
         * @param {IWarehouseIn} iWarehouseIn send save warehouse
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWarehouse(iWarehouseIn: IWarehouseIn, options?: any): AxiosPromise<IWarehouseOut> {
            return localVarFp.registerWarehouse(iWarehouseIn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update warehouse data
         * @param {IWarehouseIn} iWarehouseIn put warehouse details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarehouse(iWarehouseIn: IWarehouseIn, options?: any): AxiosPromise<IWarehouseOut> {
            return localVarFp.updateWarehouse(iWarehouseIn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarehouseApi - object-oriented interface
 * @export
 * @class WarehouseApi
 * @extends {BaseAPI}
 */
export class WarehouseApi extends BaseAPI {
    /**
     * 
     * @summary delete warehouse data
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public deleteWarehouseById(uuid: string, options?: any) {
        return WarehouseApiFp(this.configuration).deleteWarehouseById(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary warehouse get
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public getAllWarehouse(pageSize: number, pageNumber: number, options?: any) {
        return WarehouseApiFp(this.configuration).getAllWarehouse(pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get products of a warehouse
     * @summary get products of a warehouse
     * @param {string} uuid 
     * @param {number} pageSize 
     * @param {number} pageNumber 
     * @param {boolean} [isActive] 
     * @param {boolean} [hasStock] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public getProductsByWarehouse(uuid: string, pageSize: number, pageNumber: number, isActive?: boolean, hasStock?: boolean, options?: any) {
        return WarehouseApiFp(this.configuration).getProductsByWarehouse(uuid, pageSize, pageNumber, isActive, hasStock, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * gets the warehouse
     * @summary gets the warehouse
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public getWarehouseById(uuid: string, options?: any) {
        return WarehouseApiFp(this.configuration).getWarehouseById(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get warehouse by user id
     * @summary get warehouse by user id
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public getWarehouseByUserId(uuid: string, options?: any) {
        return WarehouseApiFp(this.configuration).getWarehouseByUserId(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary warehouse get list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public getWarehouseList(options?: any) {
        return WarehouseApiFp(this.configuration).getWarehouseList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary new user for warehouse
     * @param {IRegisterRequest} iRegisterRequest new user for warehouse
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public registerNewUserWarehouse(iRegisterRequest: IRegisterRequest, options?: any) {
        return WarehouseApiFp(this.configuration).registerNewUserWarehouse(iRegisterRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary saving warehouse
     * @param {IWarehouseIn} iWarehouseIn send save warehouse
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public registerWarehouse(iWarehouseIn: IWarehouseIn, options?: any) {
        return WarehouseApiFp(this.configuration).registerWarehouse(iWarehouseIn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update warehouse data
     * @param {IWarehouseIn} iWarehouseIn put warehouse details
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarehouseApi
     */
    public updateWarehouse(iWarehouseIn: IWarehouseIn, options?: any) {
        return WarehouseApiFp(this.configuration).updateWarehouse(iWarehouseIn, options).then((request) => request(this.axios, this.basePath));
    }
}


