import { RootState } from "types";
import { initialState } from ".";
import { createSelector } from "@reduxjs/toolkit";

const selectUser = (state: RootState) => state.users || initialState;

export const selectUserList = createSelector(
  [selectUser],
  (users) => users.userList
);

export const selectWarehouseList = createSelector(
  [selectUser],
  (users) => users.warehouseList
);

export const selectProducerList = createSelector(
  [selectUser],
  (users) => users.producerList
);

export const selectStoreList = createSelector(
  [selectUser],
  (users) => users.storeList
);

export const selectLoading = createSelector(
  [selectUser],
  (users) => users.loading
);

export const selectPagination = createSelector(
  [selectUser],
  (users) => users.pagination
);

export const selectError = createSelector([selectUser], (users) => users.error);

export const selectTotalPagesCount = createSelector(
  [selectUser],
  (users) => users.totalPagesCount
);
