import {
  IOrderStateEnum,
  IPaymentMethodEnum,
  IUserRoleEnum,
} from "openapi/types";

export const getPaymentMethodLabel = (
  paymentMethod: string | IPaymentMethodEnum
) => {
  switch (paymentMethod) {
    case IPaymentMethodEnum.CARD:
      return "Card";
    case IPaymentMethodEnum.BANK_TRANSFER:
      return "Transfer bancar";
    case IPaymentMethodEnum.CASH:
      return "Cash";
    default:
      return "";
  }
};

export const getPaymentMethodDropdownOptions = () => {
  return Object.values(IPaymentMethodEnum)
    .filter((value) => typeof value === "number")
    .map((method) => ({
      id: method.toString(),
      display: getPaymentMethodLabel(method),
    }));
};

export const getUserRoleDropdownOptions = () => {
  return Object.values(IUserRoleEnum).map((role) => ({
    id: role.toString(),
    display: getUserRoleLabel(role),
  }));
};

export const getOrderStatusLabel = (orderStatus?: IOrderStateEnum) => {
  switch (orderStatus) {
    case IOrderStateEnum.Placed:
      return "Plasată";
    case IOrderStateEnum.Executed:
      return "Executat";
    case IOrderStateEnum.Closed:
      return "Închis";
    case IOrderStateEnum.Invoiced:
      return "Facturat";
    case IOrderStateEnum.Paid:
      return "Plătit";
    case IOrderStateEnum.Shipped:
      return "Expediat";
    case IOrderStateEnum.Canceled:
      return "Anulat";
    case IOrderStateEnum.Proforma:
      return "Pro forma";
    default:
      return "";
  }
};

export const getUserRoleLabel = (role?: IUserRoleEnum) => {
  switch (role) {
    case IUserRoleEnum.Store:
      return "Administrator magazin";
    case IUserRoleEnum.Producer:
      return "Administrator producator";
    case IUserRoleEnum.Warehouse:
      return "Administrator depozit";
    case IUserRoleEnum.SysAdmin:
      return "Administrator system";
    default:
      return "";
  }
};
