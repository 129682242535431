import { RootState } from "types";
import { initialState } from ".";
import { createSelector } from "@reduxjs/toolkit";

const selectStore = (state: RootState) => state.stores || initialState;

export const selectStoreList = createSelector(
  [selectStore],
  (stores) => stores.storeList
);

export const selectLoading = createSelector(
  [selectStore],
  (stores) => stores.loading
);

export const selectPagination = createSelector(
  [selectStore],
  (stores) => stores.pagination
);

export const selectError = createSelector(
  [selectStore],
  (stores) => stores.error
);

export const selectTotalPagesCount = createSelector(
  [selectStore],
  (stores) => stores.totalPagesCount
);
