import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProducersState } from "./types";
import { IProducerIn, IProducerOut } from "openapi/types";

export const initialState: IProducersState = {
  producerList: [],
  pagination: {
    pageSize: 10,
    pageNumber: 0,
  },
  totalPagesCount: 0,
  loading: false,
  error: undefined,
};

export const producersSlice = createSlice({
  name: "producers",
  initialState,
  reducers: {
    setPagination(
      state,
      action: PayloadAction<{
        pagination: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.pagination = action.payload.pagination;
      state.loading = true;
      state.error = undefined;
    },
    setPaginationSuccess(
      state,
      action: PayloadAction<{
        producerList: IProducerOut[];
        totalPagesCount: number;
      }>
    ) {
      state.producerList = action.payload.producerList;
      state.totalPagesCount = action.payload.totalPagesCount;
      state.loading = false;
    },
    setPaginationError(state, action: PayloadAction<{ error: string }>) {
      state.error = action.payload.error;
      state.loading = false;
    },
    createProducer(
      state,
      action: PayloadAction<{
        producer: IProducerIn;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
    deleteProducer(
      state,
      action: PayloadAction<{
        producerId: string;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
    updateProducer(
      state,
      action: PayloadAction<{
        producer: IProducerIn;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
  },
});

export const {
  setPagination,
  setPaginationSuccess,
  setPaginationError,
  createProducer,
  deleteProducer,
  updateProducer,
} = producersSlice.actions;

export default producersSlice.reducer;
