import styled from "styled-components";

export const CustomPaginationWrapper = styled.div`
  display: flex;

  &.pagination__wrap--small {
    margin-top: 0;
    display: block;
  }
`;
