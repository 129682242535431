import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IToastMessageState, ToastType } from "./types";

export const initialState: IToastMessageState = {
  message: "",
  toastType: ToastType.Info,
  isVisible: false,
};

const toastSlice = createSlice({
  name: "toastMessage",
  initialState,
  reducers: {
    displayToastMessage(
      state,
      action: PayloadAction<{ message: string; type: ToastType }>
    ) {
      state.message = action.payload.message;
      state.toastType = action.payload.type;
      state.isVisible = true;
    },
    hideToastMessage(state) {
      state.isVisible = false;
    },
  },
});

export const {
    displayToastMessage,
    hideToastMessage,
} = toastSlice.actions;

export default toastSlice.reducer;
