export const getRefreshToken = () => {
  const state: any = localStorage.getItem("state");
  const parsedState = JSON.parse(state);
  if (parsedState.session.refreshToken) {
    return parsedState.session.refreshToken;
  } else {
    return null;
  }
};

export const getAccessToken = () => {
  const state: any = localStorage.getItem("state");
  const parsedState = JSON.parse(state);
  if (parsedState.session.accessToken) {
    return parsedState.session.accessToken;
  } else {
    return null;
  }
};

export const decodeJwtPayload = (token: string) => {
  return JSON.parse(atob(token.split(".")[1]));
};
