import styled from "styled-components";

export const CartWrapper = styled.div`
  width: 60%;
  background-color: #6372ff;
  border-radius: 10px 0px 0px 10px;
  padding: 25px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
