import styled from "styled-components";

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100vw - 50px);
  margin: 30px 25px 25px 25px;
  background-color: #e0e0e0;
  border-radius: 10px;
  height: 80vh;
`;
