import { IOrderOut, IOrderStateEnum, IUserRoleEnum } from "openapi/types";
import { Text } from "atoms";
import { TextButton } from "atoms/TextButton";
import { getTotalPriceOfProducts } from "utils/UtilFunctions";
import { useNavigate } from "react-router-dom";
import { getDateFromDatetime } from "utils/dateUtils";
import { getOrderStatusLabel } from "utils/enumUtils";
import { IconButton, Tooltip } from "@mui/material";
import { Visibility, LocalShipping } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { shipOrder } from "containers/OrdersPage/slice";
import { selectPagination } from "containers/OrdersPage/slice/selectors";
import { selectUser } from "containers/App/slice/selectors";
import PaymentIcon from "@mui/icons-material/Payment";
import { useState } from "react";
import Input from "components/Input";
import { executeOrder } from "containers/CartPage/slice";

interface IOrderRowProps {
  order: IOrderOut;
}

export const OrderRow = ({ order }: IOrderRowProps) => {
  const [selectedAwb, setSelectedAwb] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pagination = useSelector(selectPagination);
  const user = useSelector(selectUser);

  const onOpen = () => {
    navigate(`/order/${order.orderId}`);
  };

  const onShip = () => {
    dispatch(
      shipOrder({
        orderId: order.orderId,
        awb: selectedAwb,
        pagination,
        successMessage: "Comanda a fost trimisă cu succes!",
      })
    );
  };

  const onExecuteOrder = () => {
    if (order.orderId) {
      dispatch(
        executeOrder({
          orderId: order.orderId,
          pagination,
          successMessage: "Factura a fost emisă cu succes!",
        })
      );
    }
  };

  return (
    <tr style={{ borderTop: "1px solid #e8e8e8", height: "64px" }}>
      <th>
        <Text bold>{order.orderId?.substring(0, 8)}</Text>
      </th>
      <td>
        <Text bold>{getDateFromDatetime(order.createdAt)}</Text>
      </td>
      <td>
        <Text bold>{getOrderStatusLabel(order.orderState)}</Text>
      </td>
      <td>
        <Text bold>{getTotalPriceOfProducts(order.products ?? [])} RON</Text>
      </td>
      <td>
        <Text bold>{order.awb}</Text>
      </td>
      <td>
        <div className="d-flex flex-row">
          <Tooltip title="Vizualizează comanda">
            <span>
              <IconButton onClick={onOpen}>
                <Visibility style={{ fill: "#6372ff" }} />
              </IconButton>
            </span>
          </Tooltip>
          {user?.role === IUserRoleEnum.Warehouse &&
            order?.orderState === IOrderStateEnum.Paid && (
              <>
                <Tooltip title="Setează ca expediat">
                  <span>
                    <IconButton onClick={onShip}>
                      <LocalShipping style={{ fill: "#6372ff" }} />
                    </IconButton>
                  </span>
                </Tooltip>
                <div className="d-flex flex-row align-items-center mt-2">
                  <Text className="mr-2" bold>
                    AWB:
                  </Text>
                  <Input
                    value={selectedAwb}
                    onChange={(val) => setSelectedAwb(val)}
                  />
                </div>
              </>
            )}
          {user?.role === IUserRoleEnum.SysAdmin &&
            order?.orderState === IOrderStateEnum.Invoiced && (
              <>
                <Tooltip title="Setează ca plătit">
                  <span>
                    <IconButton onClick={onExecuteOrder}>
                      <PaymentIcon style={{ fill: "#6372ff" }} />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            )}
        </div>
      </td>
    </tr>
  );
};
