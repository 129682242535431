import { Link } from "react-router-dom";
import styled from "styled-components";

export const ListItemWrapper = styled(Link)`
  margin: 9px 20px 0;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #333;
  position: relative;
  display: block;
  text-decoration: none;

  &:hover {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }

  &:after {
    display: block; /* Display on hover */
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0%;
    height: 2px;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    transition: width 0.2s;
  }

  &:hover:after {
    display: block;
    width: 100%;
  }
`;

export const ListItemWrapperDiv = styled.div`
  margin: 9px 20px 0;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #333;
  position: relative;
  display: block;
  text-decoration: none;

  &:hover {
    color: #333;
  }

  &:after {
    display: block; /* Display on hover */
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0%;
    height: 2px;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
    transition: width 0.2s;
  }

  &:hover:after {
    display: block;
    width: 100%;
  }
`;
