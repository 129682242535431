import { ActionType } from "typesafe-actions";
import {
  loadSingleOrder,
  loadSingleOrderError,
  loadSingleOrderSuccess,
} from ".";
import { orderApiInstance } from "services";
import { call, put, takeLatest } from "redux-saga/effects";
import { IOrderOut } from "openapi/types";

export function* loadSingleOrderSaga(
  action: ActionType<typeof loadSingleOrder>
) {
  const { orderId } = action.payload;

  try {
    const response = yield call(
      [orderApiInstance, orderApiInstance.getOrder],
      orderId
    );
    const order: IOrderOut = response.data;
    yield put(loadSingleOrderSuccess({ order }));
  } catch (e: any) {
    const response = e;
    yield put(
      loadSingleOrderError({
        error: response.message ?? "Something went wrong",
      })
    );
  }
}

export function* singleOrderSaga() {
  yield takeLatest(loadSingleOrder, loadSingleOrderSaga);
}
