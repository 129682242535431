import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  height: 28px;
  margin-left: 13px;
  margin-right: 7px;
  border-bottom: 1px solid #eff1f5;
  cursor: pointer;
`;
