import { sessionSaga } from "containers/App/slice/saga";
import { orderSaga } from "containers/CartPage/slice/saga";
import { ordersSaga } from "containers/OrdersPage/slice/saga";
import { producersSaga } from "containers/ProducersPage/slice/saga";
import { productSaga } from "containers/ProductsPage/slice/saga";
import { profileSaga } from "containers/ProfilePage/slice/saga";
import { singleOrderSaga } from "containers/SingleOrderPage/slice/saga";
import { storesSaga } from "containers/StoresPage/slice/saga";
import { usersSaga } from "containers/UsersPage/slice/saga";
import { warehousesSaga } from "containers/WarehousesPage/slice/saga";
import { all, fork } from "redux-saga/effects";

const rootSaga = function* () {
  {
    yield all([fork(sessionSaga)]);
    yield all([fork(storesSaga)]);
    yield all([fork(productSaga)]);
    yield all([fork(orderSaga)]);
    yield all([fork(ordersSaga)]);
    yield all([fork(singleOrderSaga)]);
    yield all([fork(producersSaga)]);
    yield all([fork(warehousesSaga)]);
    yield all([fork(usersSaga)]);
    yield all([fork(profileSaga)]);
  }
};

export default rootSaga;
