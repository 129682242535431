import { ActionType } from "typesafe-actions";
import {
  createStore,
  deleteStore,
  setPagination,
  setPaginationError,
  setPaginationSuccess,
  updateStore,
} from ".";
import { call, put, takeLatest } from "redux-saga/effects";
import { storeApiInstance } from "services";
import { displayToastMessage } from "components/ToastMessage/slice";
import { ToastType } from "components/ToastMessage/slice/types";

export function* setPaginationSaga(action: ActionType<typeof setPagination>) {
  const { pagination } = action.payload;
  try {
    const pageSize = pagination.pageSize;
    const pageNumber = pagination.pageNumber;
    const response = yield call(
      [storeApiInstance, storeApiInstance.getAllStore],
      pageSize,
      pageNumber
    );
    const totalPagesCount = response.data.totalPagesCount;
    yield put(
      setPaginationSuccess({
        storeList: response.data.storeDaoList,
        totalPagesCount,
      })
    );
  } catch (e: any) {
    const response = e;
    yield put(
      setPaginationError({
        error: response.data.message ?? "Something went wrong",
      })
    );
  }
}

export function* updateStoreSaga(action: ActionType<typeof updateStore>) {
  const { store, pagination } = action.payload;

  try {
    yield call([storeApiInstance, storeApiInstance.updateStore], store);
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
    yield put(
      displayToastMessage({
        message: "Magazinul a fost actualizat cu success!",
        type: ToastType.Success,
      })
    );
  } catch (e: any) {
    const response = e;
  }
}

export function* deleteStoreSaga(action: ActionType<typeof deleteStore>) {
  const { storeId, pagination } = action.payload;

  try {
    yield call([storeApiInstance, storeApiInstance.deleteStoreById], storeId);
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
  }
}

export function* createStoreSaga(action: ActionType<typeof createStore>) {
  const { store, pagination } = action.payload;

  try {
    yield call([storeApiInstance, storeApiInstance.registerStore], store);
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
  }
}

export function* storesSaga() {
  yield takeLatest(setPagination.type, setPaginationSaga);
  yield takeLatest(createStore.type, createStoreSaga);
  yield takeLatest(updateStore.type, updateStoreSaga);
  yield takeLatest(deleteStore.type, deleteStoreSaga);
}
