import {
  AuthenticationApi,
  StoreApi,
  ProductApi,
  WarehouseApi,
  ProducerApi,
  UsersApi,
  OrderApi,
  DocumentApi,
  OrderproductApi,
} from "openapi/types";
import instance, { apiUrl } from "./axiosInstance";

export const authenticationApiInstance = new AuthenticationApi(
  undefined,
  apiUrl,
  instance
);

export const storeApiInstance = new StoreApi(undefined, apiUrl, instance);

export const productApiInstance = new ProductApi(undefined, apiUrl, instance);

export const warehouseApiInstance = new WarehouseApi(
  undefined,
  apiUrl,
  instance
);

export const producerApiInstance = new ProducerApi(undefined, apiUrl, instance);

export const userApiInstance = new UsersApi(undefined, apiUrl, instance);

export const orderApiInstance = new OrderApi(undefined, apiUrl, instance);

export const documentApiInstance = new DocumentApi(undefined, apiUrl, instance);

export const orderProductApiInstance = new OrderproductApi(
  undefined,
  apiUrl,
  instance
);
