import Button from "components/Button";
import FormInput from "components/FormInput";
import { Text } from "atoms";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Modal from "components/Modal";
import { useEffect, useState } from "react";
import { createStore, updateStore } from "containers/StoresPage/slice";
import { useDispatch, useSelector } from "react-redux";
import { selectPagination } from "containers/StoresPage/slice/selectors";
import { IStoreIn } from "openapi/types";
import { StoreIn } from "utils/interfaces/interfaces";
import { Dropdown } from "components/Dropdown/Dropdown";
import { getPaymentMethodDropdownOptions } from "utils/enumUtils";
import { ModalContentWrapper } from "./atoms/ModalContentWrapper";

interface IStoreModalProps {
  onClose: () => void;
  store?: IStoreIn;
}

export const AddStoreModal = ({ onClose, store }: IStoreModalProps) => {
  const [selectedStore, setSelectedStore] = useState<IStoreIn>(new StoreIn());
  const pagination = useSelector(selectPagination);
  const dispatch = useDispatch();

  useEffect(() => {
    if (store) {
      setSelectedStore(store);
    }
  }, []);

  const onAction = () => {
    if (store) {
      dispatch(updateStore({ store: selectedStore, pagination }));
    } else {
      dispatch(createStore({ store: selectedStore, pagination }));
    }
    setSelectedStore(new StoreIn());
    onClose();
  };

  const updateStoreVariables = (key: keyof IStoreIn, value) => {
    setSelectedStore({
      ...selectedStore,
      [key]: value,
    });
  };

  return (
    <Modal isVisible={true} onClose={onClose}>
      <ModalContentWrapper>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <div className="mt-3">
          <Text bold fontSize="20px">
            Informatii magazin
          </Text>
          <FormInput
            name="Nume"
            value={selectedStore?.name ?? ""}
            onChange={(value) => {
              updateStoreVariables("name", value);
            }}
          />
          <FormInput
            name="Adresa"
            value={selectedStore?.storeAddress?.address ?? ""}
            onChange={(value) => {
              updateStoreVariables("storeAddress", {
                ...selectedStore.storeAddress,
                address: value,
              });
            }}
          />
          <FormInput
            name="Oras"
            value={selectedStore?.storeAddress?.city ?? ""}
            onChange={(value) => {
              updateStoreVariables("storeAddress", {
                ...selectedStore.storeAddress,
                city: value,
              });
            }}
          />
          <FormInput
            name="Judet"
            value={selectedStore?.storeAddress?.county ?? ""}
            onChange={(value) => {
              updateStoreVariables("storeAddress", {
                ...selectedStore.storeAddress,
                county: value,
              });
            }}
          />
          <FormInput
            name="Tara"
            value={selectedStore?.storeAddress?.country ?? ""}
            onChange={(value) => {
              updateStoreVariables("storeAddress", {
                ...selectedStore.storeAddress,
                country: value,
              });
            }}
          />
          <Text bold>Metodă de plată:</Text>
          <Dropdown
            id="paymentMethodDropdown"
            bordered
            title="Metodă de plată"
            selectedItemId={selectedStore.paymentMethod?.toString() ?? ""}
            onSelect={(value) => {
              updateStoreVariables("paymentMethod", value);
            }}
            items={getPaymentMethodDropdownOptions()}
          />
          <Text bold>Termen de plată:</Text>
          <input
            style={{ maxWidth: "50px", height: "24px" }}
            type="number"
            value={selectedStore.paymentTerm}
            onChange={(e) => {
              updateStoreVariables("paymentTerm", e.target.value);
            }}
          />
        </div>
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button onClick={onAction}>{store ? "Editeaza" : "Adauga"}</Button>
        </div>
      </ModalContentWrapper>
    </Modal>
  );
};
