import { InputWrapper } from "./atoms/InputWrapper";
import { Label } from "./atoms/Label";

interface IInputProps {
  value?: string;
  placeholder?: string;
  onChange: (val: string) => void;
  onClick?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  label?: string;
  type?: string;
  className?: string;
  ref?: any;
  disabled?: boolean;
  id?: string;
}

export const Input = (props: IInputProps) => {
  const {
    id,
    label,
    value,
    onChange,
    placeholder,
    className = "",
    type = "",
    onClick = () => {},
    onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {},
    ref,
    disabled = false,
  } = props;

  return (
    <div
      ref={ref}
      onClick={onClick}
      onKeyDown={onKeyDown}
      className={className}
    >
      {label && <Label>{label}</Label>}
      <InputWrapper
        data-testid={id}
        disabled={disabled}
        value={value || ""}
        type={type}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
};
