import Button from "components/Button";
import FormInput from "components/FormInput";
import { updateStore } from "containers/StoresPage/slice";
import { IInvoiceIn, IStoreOut } from "openapi/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStore } from "utils/localStorage";
import { loadStore } from "./slice";
import { selectLoading, selectStore } from "./slice/selectors";
import { TableSpinnerWrapper } from "atoms";
import LoadingSpinner from "components/LoadingSpinner";

export const ProfilePage = () => {
  const localStorageStore = getStore() as IStoreOut;
  const store = useSelector(selectStore);
  const loading = useSelector(selectLoading);
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoiceIn>();
  const dispatch = useDispatch();

  const updateInvoiceVariables = (key: keyof IInvoiceIn, value) => {
    setSelectedInvoice({
      ...selectedInvoice,
      [key]: value,
    });
  };

  useEffect(() => {
    if (localStorageStore) {
      dispatch(loadStore({ storeId: localStorageStore.storeId }));
    }
  }, []);

  useEffect(() => {
    if (store) {
      setSelectedInvoice(store.invoice);
    }
  }, [store]);

  const updateStoreInvoice = (invoice?: IInvoiceIn) => {
    if (invoice) {
      dispatch(updateStore({ store: { ...store, invoice } }));
    }
  };

  return (
    <div>
      {loading ? (
        <TableSpinnerWrapper style={{ height: "80vh" }}>
          <LoadingSpinner />
        </TableSpinnerWrapper>
      ) : (
        <>
          <div className="d-flex flex-row">
            <div className="m-5 w-50">
              <FormInput
                name="Nr.ord.Reg.Com./an"
                value={selectedInvoice?.chamberOfCommRegNo}
                onChange={(value) => {
                  updateInvoiceVariables("chamberOfCommRegNo", value);
                }}
              />
              <FormInput
                name="C.I.F."
                value={selectedInvoice?.fiscalIdentifier}
                onChange={(value) => {
                  updateInvoiceVariables("fiscalIdentifier", value);
                }}
              />
              <FormInput
                name="Sediu"
                value={selectedInvoice?.headquarters}
                onChange={(value) => {
                  updateInvoiceVariables("headquarters", value);
                }}
              />
              <FormInput
                name="Judet"
                value={selectedInvoice?.county}
                onChange={(value) => {
                  updateInvoiceVariables("county", value);
                }}
              />
              <FormInput
                name="Cont"
                value={selectedInvoice?.bankAccount}
                onChange={(value) => {
                  updateInvoiceVariables("bankAccount", value);
                }}
              />
              <FormInput
                name="Banca"
                value={selectedInvoice?.bankName}
                onChange={(value) => {
                  updateInvoiceVariables("bankName", value);
                }}
              />
              <FormInput
                name="Mijloc transport:auto Nr.inmatr."
                value={selectedInvoice?.carRegistrationNumber}
                onChange={(value) => {
                  updateInvoiceVariables("carRegistrationNumber", value);
                }}
              />
            </div>
            <div className="m-5 w-50">
              <FormInput
                name="Delegat"
                value={selectedInvoice?.delegateName}
                onChange={(value) => {
                  updateInvoiceVariables("delegateName", value);
                }}
              />
              <FormInput
                name="CI seria"
                value={selectedInvoice?.idSerial}
                onChange={(value) => {
                  updateInvoiceVariables("idSerial", value);
                }}
              />
              <FormInput
                name="CI număr"
                value={selectedInvoice?.idNumber}
                onChange={(value) => {
                  updateInvoiceVariables("idNumber", value);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              onClick={() => updateStoreInvoice(selectedInvoice)}
              className="p-2 mr-5 mt-5"
            >
              Editează
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
