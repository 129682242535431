import { PaginationItem } from "reactstrap";
import styled from "styled-components";

export const CustomPaginationItem = styled(PaginationItem)`
  &.active .pagination__link {
    background-color: transparent;
    font-weight: bold;
    color: #646777;
  }

  &.disabled .pagination__link svg {
    fill: #dddddd;
  }
`;
