import { useDispatch, useSelector } from "react-redux";
import { selectOrder } from "./slice/selectors";
import { useEffect, useState } from "react";
import { loadSingleOrder } from "./slice";
import { useLocation, useNavigate } from "react-router-dom";
import { OrderProductsTable } from "components/OrderProductsTable/OrderProductsTable";
import { IWarehouseListOut } from "openapi/types";
import { selectWarehouseList } from "containers/ProductsPage/slice/selectors";
import { loadWarehouses } from "containers/ProductsPage/slice";
import { Wrapper } from "./atoms/Wrapper";
import { Text } from "atoms";
import { getTotalPriceOfProducts } from "utils/UtilFunctions";
import { IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { getOrderStatusLabel } from "utils/enumUtils";

export const SingleOrderPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const order = useSelector(selectOrder);
  const warehouseList = useSelector(selectWarehouseList);
  const [foundWarehouses, setFoundWarehouses] = useState<IWarehouseListOut[]>(
    []
  );

  useEffect(() => {
    const orderId = location.pathname.replace("/order/", "");
    dispatch(loadSingleOrder({ orderId }));
    dispatch(loadWarehouses());
  }, []);

  useEffect(() => {
    const list: IWarehouseListOut[] = [];
    warehouseList.map((warehouse) => {
      if (warehouse.warehouseId) {
        const products = order?.products?.filter((prod) => {
          if (prod.product?.warehouse === warehouse.warehouseId) return true;
          return false;
        });
        if (products && products.length > 0) {
          list.push(warehouse);
        }
      }
    });
    setFoundWarehouses(list);
  }, [warehouseList, order]);

  const onBack = () => {
    navigate("/orders");
  };

  return (
    <div className="mt-2">
      <IconButton onClick={onBack}>
        <ArrowBack />
      </IconButton>
      <Wrapper>
        {order && order.products && order.products.length > 0 && (
          <div>
            <Text bold color="white" fontSize="18px">
              Identificator comanda: {order.orderId?.substring(0, 8)}
            </Text>
            <OrderProductsTable
              warehouseList={foundWarehouses}
              order={order}
              disabled={true}
            />
            <Text className="mt-5" bold color="white" fontSize="18px">
              Stare : {getOrderStatusLabel(order.orderState)}
            </Text>
            <Text bold color="white" fontSize="18px">
              Pret total : {getTotalPriceOfProducts(order.products ?? [])} RON
            </Text>
          </div>
        )}
      </Wrapper>
    </div>
  );
};
