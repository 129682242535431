import { Route, Routes, useNavigate } from "react-router-dom";
import { RouteVar } from "utils/constants";
import Layout from "containers/Layout";
import { StoresPage } from "containers/StoresPage/StoresPage";
import { selectAccessToken, selectUser } from "./slice/selectors";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { LandingPage } from "containers/LandingPage/LandingPage";
import { DashboardPage } from "containers/DashboardPage/DashboardPage";
import { ProductsPage } from "containers/ProductsPage/ProductsPage";
import { CartPage } from "containers/CartPage/CartPage";
import { OrdersPage } from "containers/OrdersPage/OrdersPage";
import { SingleOrderPage } from "containers/SingleOrderPage/SingleOrderPage";
import { ProducersPage } from "containers/ProducersPage/ProducersPage";
import { WarehousesPage } from "containers/WarehousesPage/WarehousesPage";
import { UsersPage } from "containers/UsersPage/UsersPage";
import { ToastMessage } from "components/ToastMessage/ToastMessage";
import { ProfilePage } from "containers/ProfilePage/ProfilePage";
import { IUserRoleEnum } from "openapi/types";

const App = () => {
  const navigate = useNavigate();
  const accessToken = useSelector(selectAccessToken);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (!accessToken) navigate(RouteVar.login.Route);
  }, [accessToken]);

  const hideLayout = ["/login", "/register"].includes(location.pathname);

  return (
    <div>
      {!hideLayout && <Layout />}
      <Routes>
        <Route path={RouteVar.login.Route} element={<LandingPage />} />
        <Route path={RouteVar.storesPage.Route} element={<StoresPage />} />
        <Route
          path={RouteVar.producersPage.Route}
          element={<ProducersPage />}
        />
        <Route
          path={RouteVar.warehousesPage.Route}
          element={<WarehousesPage />}
        />
        <Route path={RouteVar.usersPage.Route} element={<UsersPage />} />
        <Route path={RouteVar.mainpage.Route} element={<DashboardPage />} />
        <Route path={RouteVar.productsPage.Route} element={<ProductsPage />} />
        <Route path={RouteVar.cartPage.Route} element={<CartPage />} />
        <Route path={RouteVar.ordersPage.Route} element={<OrdersPage />} />
        {user?.role === IUserRoleEnum.Store && (
          <Route path={RouteVar.profilePage.Route} element={<ProfilePage />} />
        )}
        <Route
          path={RouteVar.singleOrderPage.Route}
          element={<SingleOrderPage />}
        />
      </Routes>
      <ToastMessage />
    </div>
  );
};

export default App;
