import { IOrderProductOut } from "openapi/types";
import { RowWrapper } from "./atoms/RowWrapper";
import { Text } from "atoms";
import { Close } from "@mui/icons-material";
import { QuantityComponent } from "./components/QuantityComponent";
import { selectUserStore } from "containers/App/slice/selectors";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { deleteOrderProduct } from "containers/CartPage/slice";
import { Image } from "atoms/Image";

interface IOrderProductRowProps {
  orderId?: string;
  orderProduct?: IOrderProductOut;
  disabled?: boolean;
}

export const OrderProductRow = ({
  orderId,
  orderProduct,
  disabled,
}: IOrderProductRowProps) => {
  const dispatch = useDispatch();
  const userStore = useSelector(selectUserStore);

  const handleProductRemoval = (
    storeId?: string,
    orderId?: string,
    productId?: string
  ) => {
    if (storeId && orderId && productId)
      dispatch(deleteOrderProduct({ storeId, orderId, productId }));
  };

  return (
    <RowWrapper>
      <div className="d-flex flex-row align-items-center">
        <Image
          height="80px"
          width="80px"
          src={orderProduct?.product?.picture}
        />
        <Text className="ml-3" bold color="white">
          {orderProduct?.product?.name}
        </Text>
      </div>
      <div>
        {disabled === false ? (
          <QuantityComponent
            storeId={userStore?.storeId}
            productId={orderProduct?.product?.productId}
            stock={orderProduct?.product?.stock}
            orderId={orderId}
            quantity={orderProduct?.quantity}
          />
        ) : (
          <Text bold color="white">
            {orderProduct?.quantity} BUC
          </Text>
        )}
      </div>
      <div>
        <Text bold color="white">
          {getPriceByQuantity(orderProduct)} RON
        </Text>
      </div>
      {disabled === false && (
        <div className="d-flex justify-content-center">
          <IconButton
            onClick={() => {
              handleProductRemoval(
                userStore?.storeId,
                orderId,
                orderProduct?.product?.productId
              );
            }}
          >
            <Close style={{ fill: "white" }} />
          </IconButton>
        </div>
      )}
    </RowWrapper>
  );
};

const getPriceByQuantity = (product?: IOrderProductOut) => {
  if (product && product.quantity && product.product && product.product.price) {
    return (product.quantity * product.product.price).toFixed(2);
  } else if (product?.quantity === 0 || product?.product?.price === 0) {
    return 0;
  }
  return "N/A";
};
