import styled from "styled-components";

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  width: 28px;
  height: 28px;

  background: #e8e8e8;

  &:hover {
    background: #aaab5a;

    svg {
      fill: white;
    }
  }
`;
