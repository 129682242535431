import Modal from "components/Modal";
import { createProducer, updateProducer } from "containers/ProducersPage/slice";
import { selectPagination } from "containers/ProducersPage/slice/selectors";
import { IProducerIn } from "openapi/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProducerIn } from "utils/interfaces/interfaces";
import { ModalContentWrapper } from "./atoms/ModalContentWrapper";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import FormInput from "components/FormInput";
import { Text } from "atoms";
import { Dropdown } from "components/Dropdown/Dropdown";
import { getPaymentMethodDropdownOptions } from "utils/enumUtils";
import Button from "components/Button";

interface IProducerModalProps {
  onClose: () => void;
  producer?: IProducerIn;
}

export const AddProducerModal = ({
  onClose,
  producer,
}: IProducerModalProps) => {
  const [selectedProducer, setSelectedProducer] = useState<IProducerIn>(
    new ProducerIn()
  );
  const pagination = useSelector(selectPagination);
  const dispatch = useDispatch();

  useEffect(() => {
    if (producer) {
      setSelectedProducer(producer);
    }
  }, []);

  const onAction = () => {
    if (producer) {
      dispatch(updateProducer({ producer: selectedProducer, pagination }));
    } else {
      dispatch(createProducer({ producer: selectedProducer, pagination }));
    }
    setSelectedProducer(new ProducerIn());
    onClose();
  };

  const updateProducerVariables = (key: keyof IProducerIn, value) => {
    setSelectedProducer({
      ...selectedProducer,
      [key]: value,
    });
  };

  return (
    <Modal isVisible={true} onClose={onClose}>
      <ModalContentWrapper>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <div className="mt-3">
          <Text bold fontSize="20px">
            Informatii producător
          </Text>
          <FormInput
            name="Nume"
            value={selectedProducer?.name ?? ""}
            onChange={(value) => {
              updateProducerVariables("name", value);
            }}
          />
          <FormInput
            name="Adresa"
            value={selectedProducer?.address?.address ?? ""}
            onChange={(value) => {
              updateProducerVariables("address", {
                ...selectedProducer.address,
                address: value,
              });
            }}
          />
          <FormInput
            name="Oras"
            value={selectedProducer?.address?.city ?? ""}
            onChange={(value) => {
              updateProducerVariables("address", {
                ...selectedProducer.address,
                city: value,
              });
            }}
          />
          <FormInput
            name="Judet"
            value={selectedProducer?.address?.county ?? ""}
            onChange={(value) => {
              updateProducerVariables("address", {
                ...selectedProducer.address,
                county: value,
              });
            }}
          />
          <FormInput
            name="Tara"
            value={selectedProducer?.address?.country ?? ""}
            onChange={(value) => {
              updateProducerVariables("address", {
                ...selectedProducer.address,
                country: value,
              });
            }}
          />
          <Text bold>Metodă de plată:</Text>
          <Dropdown
            id="paymentMethodDropdown"
            bordered
            title="Metodă de plată"
            selectedItemId={selectedProducer.paymentMethod?.toString() ?? ""}
            onSelect={(value) => {
              updateProducerVariables("paymentMethod", value);
            }}
            items={getPaymentMethodDropdownOptions()}
          />
          <Text bold>Termen de plată:</Text>
          <input
            style={{ maxWidth: "50px", height: "24px" }}
            type="number"
            value={selectedProducer.paymentTerm}
            onChange={(e) => {
              updateProducerVariables("paymentTerm", e.target.value);
            }}
          />
        </div>
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button onClick={onAction}>{producer ? "Editeaza" : "Adauga"}</Button>
        </div>
      </ModalContentWrapper>
    </Modal>
  );
};
