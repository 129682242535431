import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISingleOrderState } from "./types";
import { IOrderOut } from "openapi/types";

export const initialState: ISingleOrderState = {
  order: undefined,
  loading: false,
  error: undefined,
};

export const singleOrderSlice = createSlice({
  name: "singleOrder",
  initialState,
  reducers: {
    loadSingleOrder(
      state,
      action: PayloadAction<{
        orderId: string;
      }>
    ) {
      state.loading = true;
      state.error = undefined;
    },
    loadSingleOrderSuccess(
      state,
      action: PayloadAction<{
        order: IOrderOut;
      }>
    ) {
      state.order = action.payload.order;
      state.loading = false;
    },
    loadSingleOrderError(state, action: PayloadAction<{ error: string }>) {
      state.error = action.payload.error;
      state.order = undefined;
      state.loading = false;
    },
  },
});

export const { loadSingleOrder, loadSingleOrderSuccess, loadSingleOrderError } =
  singleOrderSlice.actions;

export default singleOrderSlice.reducer;
