import styled from "styled-components";

export const InputWrapper = styled.input`
  width: 100%;
  height: 32px;
  font-size: 12px;
  border: 1px solid #e8e8e8;
  color: #646777;
  transition: border 0.3s;
  outline: none;
  padding-left: 11px;

  &:focus {
    border: 1px solid #aaab5a;
  }
`;
