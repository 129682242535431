import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IWarehousesState } from "./types";
import { IWarehouseIn, IWarehouseOut } from "openapi/types";

export const initialState: IWarehousesState = {
  warehouseList: [],
  pagination: {
    pageSize: 10,
    pageNumber: 0,
  },
  totalPagesCount: 0,
  loading: false,
  error: undefined,
};

export const warehousesSlice = createSlice({
  name: "warehouses",
  initialState,
  reducers: {
    setPagination(
      state,
      action: PayloadAction<{
        pagination: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.pagination = action.payload.pagination;
      state.loading = true;
      state.error = undefined;
    },
    setPaginationSuccess(
      state,
      action: PayloadAction<{
        warehouseList: IWarehouseOut[];
        totalPagesCount: number;
      }>
    ) {
      state.warehouseList = action.payload.warehouseList;
      state.totalPagesCount = action.payload.totalPagesCount;
      state.loading = false;
    },
    setPaginationError(state, action: PayloadAction<{ error: string }>) {
      state.error = action.payload.error;
      state.loading = false;
    },
    createWarehouse(
      state,
      action: PayloadAction<{
        warehouse: IWarehouseIn;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
    deleteWarehouse(
      state,
      action: PayloadAction<{
        warehouseId: string;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
    updateWarehouse(
      state,
      action: PayloadAction<{
        warehouse: IWarehouseIn;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
  },
});

export const {
  setPagination,
  setPaginationSuccess,
  setPaginationError,
  createWarehouse,
  deleteWarehouse,
  updateWarehouse,
} = warehousesSlice.actions;

export default warehousesSlice.reducer;
