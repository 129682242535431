import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfileState } from "./types";
import { IStoreOut } from "openapi/types";

export const initialState: IProfileState = {
  store: undefined,
  loading: false,
  error: undefined,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    loadStore(state, action: PayloadAction<{ storeId?: string }>) {
      state.loading = true;
    },
    loadStoreSuccess(state, action: PayloadAction<{ store: IStoreOut }>) {
      state.loading = false;
      state.error = undefined;
      state.store = action.payload.store;
    },
    loadStoreError(state, action: PayloadAction<{ error: string }>) {
      state.loading = false;
      state.store = undefined;
      state.error = action.payload.error;
    },
  },
});

export const { loadStore, loadStoreSuccess, loadStoreError } =
  profileSlice.actions;

export default profileSlice.reducer;
