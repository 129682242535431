import { TableHeader } from "components/TableHeader/TableHeader";

export const HeaderRow = () => {
  const elements = [
    {
      label: "Nume",
      withoutBorder: true,
    },
    {
      label: "Judet",
    },
    {
      label: "Oras",
    },
    {
      label: "Adresa",
    },
    {
      label: "Actiuni",
    },
  ];
  return <TableHeader elements={elements} />;
};
