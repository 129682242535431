import axios, { AxiosInstance } from "axios";
import { getAccessToken, getRefreshToken } from "utils/localStorage";

export const apiUrl =
  process.env.REACT_APP_API_HOST || `https://${window.location.hostname}/api`;

const instance: AxiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 300000,
});

const unauthenticatedPaths = [
  "/authentication/login",
  "/authentication/register",
];
const refreshPath = "/authentication/refresh";

function isUnauthenticatedEndpoint(url) {
  return unauthenticatedPaths.find((path) => url.includes(path));
}

function isLogEndpoint(url) {
  return false;
}

// Data needed to handle multiple calls with token expired
let isRefreshing = false;
let refreshSubscribers: any[] = [];
function onAccessTokenFetched(accessToken: string) {
  // Proceed the call for all the subscribers after the new token is fetched
  refreshSubscribers = refreshSubscribers.filter((callback) =>
    callback(accessToken)
  );
}

function subscribeTokenRefresh(cb: any) {
  refreshSubscribers.push(cb);
}

export const setupInterceptors = (actions, dispatch) => {
  instance.interceptors.request.use((config) => {
    const refreshUrl = apiUrl + refreshPath;

    if (!isUnauthenticatedEndpoint(config.url) && refreshUrl !== config.url) {
      const accessToken = getAccessToken();
      const temporaryAccessToken = config.headers["X-Temporary-Access-Token"];

      if (accessToken) {
        config.headers.Accept = isLogEndpoint(config.url)
          ? "text/plain"
          : "application/json";
        config.headers["Content-Type"] = "application/json";
        config.headers.Authorization = "Bearer " + accessToken;
      } else if (temporaryAccessToken) {
        config.headers.Accept = isLogEndpoint(config.url)
          ? "text/plain"
          : "application/json";
        config.headers["Content-Type"] = "application/json";
        config.headers.Authorization = "Bearer " + temporaryAccessToken;
      }
    } else if (refreshUrl === config.url) {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        config.headers.Accept = isLogEndpoint(config.url)
          ? "text/plain"
          : "application/json";
        config.headers["Content-Type"] = "application/json";
        config.headers.Authorization = "Bearer " + refreshToken;
      }
    }
    return config;
  });

  instance.interceptors.response.use(undefined, (error) => {
    if (!error.response) {
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      error.config &&
      !error.config.__isRetryRequest
    ) {
      const originalRequest = error.config;

      if (!isRefreshing) {
        isRefreshing = true;
        instance
          .get(apiUrl + refreshPath)
          .then((response) => {
            isRefreshing = false;

            // Save the newly generated token
            const accessToken = response.data.accessToken || "";
            dispatch(actions.updateAccessToken({ accessToken }));

            // Notify subscribers
            onAccessTokenFetched(accessToken);
          })
          .catch((e) => {
            isRefreshing = false;
            console.error("Access token refresh error", e);
            dispatch(actions.logoutUser());
          });
      }

      const retryOriginalRequest = new Promise((resolve) => {
        subscribeTokenRefresh((accessToken: string) => {
          originalRequest.headers.Authorization = "Bearer " + accessToken;
          originalRequest.__isRetryRequest = true;
          resolve(doRetry(originalRequest));
        });
      });

      return retryOriginalRequest;
    } else {
      return Promise.reject(error.response);
    }
  });

  const doRetry = (config: any) => {
    return instance
      .request(config)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          dispatch(actions.logoutUser());
          return Promise.reject({ message: "Not authenticated" });
        }

        return Promise.reject(error.response);
      });
  };
};

export default instance;
