import styled from "styled-components";

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
  border-radius: 0px 10px 10px 0px;
  padding: 25px;
`;
