import { useSelector } from "react-redux";
import { selectOrdersList } from "./slice/selectors";
import { OrdersTable } from "./components/OrderTable/OrdersTable";

export const OrdersPage = () => {
  const ordersList = useSelector(selectOrdersList);

  return (
    <div>
      <OrdersTable ordersList={ordersList} />
    </div>
  );
};
