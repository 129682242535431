import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IOrderState } from "./types";
import { IOrderOut } from "openapi/types";
import { NavigateFunction } from "react-router-dom";

export const initialState: IOrderState = {
  order: undefined,
  loading: false,
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    loadOrder(state, action: PayloadAction<{ storeId: string }>) {
      state.loading = true;
    },
    loadOrderSuccess(
      state,
      action: PayloadAction<{
        order: IOrderOut;
      }>
    ) {
      state.order = action.payload.order;
      state.loading = false;
    },
    loadOrderError(state, action: PayloadAction<{ error: string }>) {
      state.loading = false;
    },
    updateOrder(
      state,
      action: PayloadAction<{
        storeId: string;
        orderId: string;
        productId: string;
        quantity: number;
      }>
    ) {},
    deleteOrderProduct(
      state,
      action: PayloadAction<{
        storeId: string;
        orderId: string;
        productId: string;
      }>
    ) {},
    executeOrder(
      state,
      action: PayloadAction<{
        orderId: string;
        pagination?: {
          pageNumber: number;
          pageSize: number;
          warehouseId?: string;
          storeId?: string;
        };
        successMessage?: string;
        navigate?: NavigateFunction;
      }>
    ) {},
  },
});

export const {
  loadOrder,
  loadOrderSuccess,
  loadOrderError,
  updateOrder,
  deleteOrderProduct,
  executeOrder,
} = orderSlice.actions;

export default orderSlice.reducer;
