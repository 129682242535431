import {
  selectLoading,
  selectPagination,
  selectTotalPagesCount,
} from "containers/OrdersPage/slice/selectors";
import { IOrderOut, IUserRoleEnum } from "openapi/types";
import { useDispatch, useSelector } from "react-redux";
import { OrderRow } from "../OrderRow/OrderRow";
import { useEffect } from "react";
import { setPagination } from "containers/OrdersPage/slice";
import {
  selectUser,
  selectUserStore,
  selectUserWarehouse,
} from "containers/App/slice/selectors";
import Table from "components/Table";
import { HeaderRow } from "../OrderRow/HeaderRow";
import { PaginationWrapper } from "atoms/PaginationWrapper";
import CustomPagination from "components/Pagination";

interface IOrderTableProps {
  ordersList: IOrderOut[];
}

export const OrdersTable = ({ ordersList }: IOrderTableProps) => {
  const totalPagesCount = useSelector(selectTotalPagesCount);
  const pagination = useSelector(selectPagination);
  const loading = useSelector(selectLoading);
  const user = useSelector(selectUser);
  const currentStore = useSelector(selectUserStore);
  const currentWarehouse = useSelector(selectUserWarehouse);

  const dispatch = useDispatch();

  const rows = ordersList.map((order) => <OrderRow order={order} />);

  useEffect(() => {
    if (user?.role === IUserRoleEnum.Store) {
      dispatch(
        setPagination({
          pagination: {
            pageNumber: 0,
            pageSize: 10,
            storeId: currentStore?.storeId,
          },
        })
      );
    } else if (user?.role === IUserRoleEnum.Warehouse) {
      dispatch(
        setPagination({
          pagination: {
            pageNumber: 0,
            pageSize: 10,
            warehouseId: currentWarehouse?.warehouseId,
          },
        })
      );
    } else if (user?.role === IUserRoleEnum.Producer) {
      // TODO: Implement raport vanzari
    } else {
      dispatch(
        setPagination({
          pagination: {
            pageNumber: 0,
            pageSize: 10,
          },
        })
      );
    }
  }, []);

  const selectPage = (pageNumber: number) => {
    dispatch(setPagination({ pagination: { ...pagination, pageNumber } }));
  };

  return (
    <div className="mt-2">
      <Table
        headerRow={<HeaderRow />}
        rows={rows}
        loading={loading}
        notFoundText="Nu s-au gasit comenzi!"
      />
      <PaginationWrapper>
        <CustomPagination
          pagesCount={totalPagesCount}
          pageSize={pagination.pageSize}
          pageNumber={pagination.pageNumber}
          onSelectPage={selectPage}
        />
      </PaginationWrapper>
    </div>
  );
};
