import Button from "components/Button";
import Table from "components/Table";
import { useEffect, useState } from "react";
import { PaginationWrapper } from "atoms/PaginationWrapper";
import CustomPagination from "components/Pagination";
import { setPagination } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoading,
  selectPagination,
  selectProducerList,
  selectTotalPagesCount,
} from "./slice/selectors";
import { ProducerRow } from "./components/ProducerItem/ProducerRow";
import { AddProducerModal } from "./components/ProducerModal/AddProducerModal";
import { HeaderRow } from "./components/ProducerItem/HeaderRow";

export const ProducersPage = () => {
  const [addModalVisibility, setAddModalVisibility] = useState(false);
  const totalPagesCount = useSelector(selectTotalPagesCount);
  const producerList = useSelector(selectProducerList);
  const rows = producerList.map((producer) => (
    <ProducerRow producer={producer} />
  ));
  const pagination = useSelector(selectPagination);
  const loading = useSelector(selectLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPagination({ pagination: { pageNumber: 0, pageSize: 10 } }));
  }, []);

  const selectPage = (pageNumber: number) => {
    dispatch(setPagination({ pagination: { ...pagination, pageNumber } }));
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "end" }}
        className="mr-2 mt-2"
      >
        <Button
          style={{ width: "10%" }}
          onClick={() => setAddModalVisibility(true)}
        >
          Add
        </Button>
      </div>
      <div className="mt-2">
        <Table
          headerRow={<HeaderRow />}
          rows={rows}
          loading={loading}
          notFoundText="Nu s-au găsit producători"
        />
        <PaginationWrapper>
          <CustomPagination
            pagesCount={totalPagesCount}
            pageSize={pagination.pageSize}
            pageNumber={pagination.pageNumber}
            onSelectPage={selectPage}
          />
        </PaginationWrapper>
      </div>
      {addModalVisibility && (
        <AddProducerModal onClose={() => setAddModalVisibility(false)} />
      )}
    </div>
  );
};
