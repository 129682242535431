import styled, { keyframes } from "styled-components";
import { ISpinnerProps } from ".";

export const LoadingSpinner = (props: ISpinnerProps) => {
  return <Spinner style={props.customStyle ?? {}} />;
};

const baseColor = "#6372ff";

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;

  &:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid ${baseColor};
    border-color: ${baseColor} transparent ${baseColor} transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;
