import { ActionType } from "typesafe-actions";
import {
  setPagination,
  setPaginationError,
  setPaginationSuccess,
  shipOrder,
} from ".";
import { call, put, takeLatest } from "redux-saga/effects";
import { orderApiInstance, storeApiInstance } from "services";
import { displayToastMessage } from "components/ToastMessage/slice";
import { ToastType } from "components/ToastMessage/slice/types";

export function* setPaginationSaga(action: ActionType<typeof setPagination>) {
  const { pagination } = action.payload;

  try {
    const pageSize = pagination.pageSize;
    const pageNumber = pagination.pageNumber;
    const warehouseId = pagination.warehouseId;
    const storeId = pagination.storeId;
    if (warehouseId) {
      const response = yield call(
        [orderApiInstance, orderApiInstance.getOrdersByWarehouse],
        pageSize,
        pageNumber,
        warehouseId
      );
      yield put(
        setPaginationSuccess({
          ordersList: response.data.orderDaoList,
          totalPagesCount: response.data.totalPagesCount,
        })
      );
    } else if (storeId) {
      const response = yield call(
        [storeApiInstance, storeApiInstance.getOrdersByStoreId],
        storeId,
        pageSize,
        pageNumber
      );
      yield put(
        setPaginationSuccess({
          ordersList: response.data.orderDaoList,
          totalPagesCount: response.data.totalPagesCount,
        })
      );
    } else {
      const response = yield call(
        [orderApiInstance, orderApiInstance.getAllOrders],
        pageSize,
        pageNumber
      );
      yield put(
        setPaginationSuccess({
          ordersList: response.data.orderDaoList,
          totalPagesCount: response.data.totalPagesCount,
        })
      );
    }
  } catch (e: any) {
    const response = e;
    yield put(
      setPaginationError({ error: response.message ?? "Something went wrong" })
    );
  }
}

export function* shipOrderSaga(action: ActionType<typeof shipOrder>) {
  const { orderId, awb, successMessage, pagination } = action.payload;

  try {
    if (orderId) {
      yield call([orderApiInstance, orderApiInstance.shipOrder], {
        orderId,
        awb: awb ?? "",
      });
      if (successMessage) {
        yield put(
          displayToastMessage({
            message: successMessage,
            type: ToastType.Success,
          })
        );
      }
      if (pagination) {
        yield call(setPaginationSaga, setPagination({ pagination }));
      }
    }
  } catch (e: any) {
    console.log("Something went wrong when updating order: ", e);
  }
}

export function* ordersSaga() {
  yield takeLatest(setPagination, setPaginationSaga);
  yield takeLatest(shipOrder, shipOrderSaga);
}
