import { RootState } from "types";
import { initialState } from ".";
import { createSelector } from "@reduxjs/toolkit";

const selectWarehouse = (state: RootState) => state.warehouses || initialState;

export const selectWarehouseList = createSelector(
  [selectWarehouse],
  (warehouses) => warehouses.warehouseList
);

export const selectLoading = createSelector(
  [selectWarehouse],
  (warehouses) => warehouses.loading
);

export const selectPagination = createSelector(
  [selectWarehouse],
  (warehouses) => warehouses.pagination
);

export const selectError = createSelector(
  [selectWarehouse],
  (warehouses) => warehouses.error
);

export const selectTotalPagesCount = createSelector(
  [selectWarehouse],
  (warehouses) => warehouses.totalPagesCount
);
