import { RootState } from "types";
import { initialState } from ".";
import { createSelector } from "@reduxjs/toolkit";

const selectProduct = (state: RootState) => state.products || initialState;

export const selectProductsList = createSelector(
  [selectProduct],
  (products) => products.productsList
);

export const selectLoading = createSelector(
  [selectProduct],
  (products) => products.loading
);

export const selectPagination = createSelector(
  [selectProduct],
  (products) => products.pagination
);

export const selectError = createSelector(
  [selectProduct],
  (products) => products.error
);

export const selectTotalPagesCount = createSelector(
  [selectProduct],
  (products) => products.totalPagesCount
);

export const selectWarehouseList = createSelector(
  [selectProduct],
  (products) => products.warehouseList
);

export const selectProducerList = createSelector(
  [selectProduct],
  (products) => products.producerList
);
