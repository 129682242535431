import { IOrderProductOut, IUser, IUserRoleEnum } from "openapi/types";

export const getTotalPriceOfProducts = (
  products: IOrderProductOut[],
  warehouseId?: string
) => {
  if (warehouseId) {
    const filteredProducts = products.filter(
      (prod) => prod.product?.warehouse === warehouseId
    );
    return filteredProducts
      .reduce((acc, orderProduct) => {
        const price = orderProduct.product?.price;
        const quantity = orderProduct.quantity;
        if (price && quantity) {
          return acc + price * quantity;
        }
        return acc;
      }, 0)
      .toFixed(2);
  } else
    return products
      .reduce((acc, orderProduct) => {
        const price = orderProduct.product?.price;
        const quantity = orderProduct.quantity;
        if (price && quantity) {
          return acc + price * quantity;
        }
        return acc;
      }, 0)
      .toFixed(2);
};

export const getUsersEntityByRole = (user: IUser) => {
  const role = user.role;
  switch (role) {
    case IUserRoleEnum.Store:
      return user.store ?? "";
    case IUserRoleEnum.Producer:
      return user.producer ?? "";
    case IUserRoleEnum.Warehouse:
      return user.warehouse ?? "";
    case IUserRoleEnum.SysAdmin:
      return "";
    default:
      return "";
  }
};
