import styled from "styled-components";

export const Text = styled.div<{
  color?: string;
  fontSize?: string;
  bold?: boolean;
  textTransform?: string;
  cursor?: string;
  lineHeight?: string;
  noWrap?: boolean;
}>`
  color: ${(p) => p.color ?? "#555555"};
  font-size: ${(p) => p.fontSize ?? "14px"};
  line-height: ${(p) => p.lineHeight ?? ""};
  font-weight: ${(p) => (p.bold ? "bold" : "normal")};
  text-transform: ${(p) => p.textTransform ?? ""};
  white-space: ${(p) => (p.noWrap ? "nowrap" : "")};
  cursor: ${(p) => p.cursor ?? ""};
`;
