import { Text } from "atoms";
import { useState } from "react";
import styled from "styled-components";

interface ITableHeaderCellProps {
  orderBy?: string;
  orderDir?: string;
  label: string;
  sortingField?: string;
  onClick?: (sortingField: string) => void;
  withoutBorder?: boolean;
}

export const TableHeaderCell = (props: ITableHeaderCellProps) => {
  const { orderBy, orderDir, sortingField, label, onClick, withoutBorder } =
    props;

  const [hovered, setHovered] = useState(false);

  let sortDir = orderBy === sortingField ? orderDir : "";

  return (
    <th>
      <Wrapper
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        clickable={!!sortingField}
        withoutBorder={withoutBorder}
        onClick={() => onClick?.(sortingField ?? "")}
      >
        <Text fontSize="13px" lineHeight="15px" bold className="mr-3">
          {label}
        </Text>

        {sortingField && (
          <div className="ml-1">{/* <TableSortSvg sortDir={sortDir} /> */}</div>
        )}
      </Wrapper>
    </th>
  );
};

const Wrapper = styled.div<{
  clickable?: boolean;
  withoutBorder?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: ${(p) => (p.clickable ? "pointer" : "")};
  border-left: ${(p) => (p.withoutBorder ? "" : "4px solid #E8E8E8")};
  height: 39px;
  padding-left: 10px;
  margin-bottom: 12px;
`;
