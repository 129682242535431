import { Pagination } from "reactstrap";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronDoubleLeftIcon from "mdi-react/ChevronDoubleLeftIcon";
import ChevronDoubleRightIcon from "mdi-react/ChevronDoubleRightIcon";
import { Text } from "atoms";
import {
  CustomPaginationItem,
  CustomPaginationLink,
  CustomPaginationWrapper,
  IconWrapper,
} from "./atoms";

interface IPaginationProps {
  onSelectPage?: (pageNumber: number) => void;
  pagesCount: number;
  pageSize: number;
  pageNumber: number;
  isItemsPerPageVisible?: boolean;
  setPageSize?: (pageNumber: number) => void;
}

export const CustomPagination = ({
  onSelectPage,
  pagesCount,
  pageNumber,
  isItemsPerPageVisible,
  setPageSize,
}: IPaginationProps) => {
  const setPage = (page: number) => {
    if (page < 0 || page > pagesCount) {
      return;
    }
    onSelectPage?.(page);
  };

  return pagesCount > 0 ? (
    <CustomPaginationWrapper className="d-flex w-100">
      <Pagination className="pagination">
        <CustomPaginationItem disabled={pageNumber === 0}>
          <CustomPaginationLink
            className="pagination__link"
            type="button"
            onClick={() => setPage(0)}
          >
            <IconWrapper>
              <ChevronDoubleLeftIcon
                style={{ width: "13px", height: "13px" }}
              />
            </IconWrapper>
          </CustomPaginationLink>
        </CustomPaginationItem>
        <CustomPaginationItem disabled={pageNumber === 0}>
          <CustomPaginationLink
            className="pagination__link"
            type="button"
            onClick={() => setPage(pageNumber - 1)}
          >
            <IconWrapper>
              <ChevronLeftIcon style={{ width: "13px", height: "13px" }} />
            </IconWrapper>
          </CustomPaginationLink>
        </CustomPaginationItem>
        {pageNumber > 1 && (
          <CustomPaginationItem>
            <CustomPaginationLink
              className="pagination__link"
              type="button"
              onClick={() => setPage(pageNumber - 2)}
            >
              {pageNumber - 1}
            </CustomPaginationLink>
          </CustomPaginationItem>
        )}
        {pageNumber > 0 && (
          <CustomPaginationItem>
            <CustomPaginationLink
              className="pagination__link"
              type="button"
              onClick={() => setPage(pageNumber - 1)}
            >
              {pageNumber}
            </CustomPaginationLink>
          </CustomPaginationItem>
        )}
        <CustomPaginationItem active>
          <CustomPaginationLink className="pagination__link" type="button">
            {pageNumber + 1}
          </CustomPaginationLink>
        </CustomPaginationItem>
        {pageNumber < pagesCount - 1 && (
          <CustomPaginationItem>
            <CustomPaginationLink
              className="pagination__link"
              type="button"
              onClick={() => setPage(pageNumber + 1)}
            >
              {pageNumber + 2}
            </CustomPaginationLink>
          </CustomPaginationItem>
        )}
        {pageNumber < pagesCount - 2 && (
          <CustomPaginationItem>
            <CustomPaginationLink
              className="pagination__link"
              type="button"
              onClick={() => setPage(pageNumber + 2)}
            >
              {pageNumber + 3}
            </CustomPaginationLink>
          </CustomPaginationItem>
        )}

        <CustomPaginationItem disabled={pageNumber === pagesCount - 1}>
          <CustomPaginationLink
            className="pagination__link"
            type="button"
            onClick={() => setPage(pageNumber + 1)}
          >
            <IconWrapper>
              <ChevronRightIcon style={{ width: "13px", height: "13px" }} />
            </IconWrapper>
          </CustomPaginationLink>
        </CustomPaginationItem>

        <CustomPaginationItem disabled={pageNumber === pagesCount - 1}>
          <CustomPaginationLink
            className="pagination__link"
            type="button"
            onClick={() => setPage(pagesCount - 1)}
          >
            <IconWrapper>
              <ChevronDoubleRightIcon
                style={{ width: "13px", height: "13px" }}
              />
            </IconWrapper>
          </CustomPaginationLink>
        </CustomPaginationItem>
      </Pagination>
      {isItemsPerPageVisible && (
        <Pagination className="pagination d-flex align-items-center justify-content-center align-self-center ms-5">
          <Text fontSize="13px" bold className="pt-1">
            Items per page:
          </Text>
          <CustomPaginationItem>
            <CustomPaginationLink
              className="pagination__link"
              type="button"
              onClick={() => setPageSize?.(10)}
            >
              10
            </CustomPaginationLink>
          </CustomPaginationItem>
          <CustomPaginationItem>
            <CustomPaginationLink
              className="pagination__link"
              type="button"
              onClick={() => setPageSize?.(50)}
            >
              50
            </CustomPaginationLink>
          </CustomPaginationItem>
          <CustomPaginationItem>
            <CustomPaginationLink
              className="pagination__link"
              type="button"
              onClick={() => setPageSize?.(100)}
            >
              100
            </CustomPaginationLink>
          </CustomPaginationItem>
        </Pagination>
      )}
    </CustomPaginationWrapper>
  ) : (
    <div style={{ height: "28px" }} />
  );
};
