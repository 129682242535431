import { ActionType } from "typesafe-actions";
import { loadStore, loadStoreError, loadStoreSuccess } from ".";
import { storeApiInstance } from "services";
import { call, put, takeLatest } from "redux-saga/effects";

export function* loadStoreSaga(action: ActionType<typeof loadStore>) {
  const { storeId } = action.payload;
  try {
    if (storeId) {
      const response = yield call(
        [storeApiInstance, storeApiInstance.getStoreById],
        storeId
      );
      yield put(loadStoreSuccess({ store: response.data }));
    } else {
      yield put(loadStoreError({ error: "StoreId was not given!" }));
    }
  } catch (e: any) {
    const response = e;
    yield put(
      loadStoreError({ error: response.data.message ?? "Something went wrong" })
    );
  }
}

export function* profileSaga() {
  yield takeLatest(loadStore.type, loadStoreSaga);
}
