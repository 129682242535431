import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IOrdersState } from "./types";
import { IOrderIn, IOrderOut } from "openapi/types";

export const initialState: IOrdersState = {
  ordersList: [],
  pagination: {
    pageSize: 10,
    pageNumber: 0,
  },
  totalPagesCount: 0,
  loading: false,
  error: undefined,
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setPagination(
      state,
      action: PayloadAction<{
        pagination: {
          pageNumber: number;
          pageSize: number;
          warehouseId?: string;
          storeId?: string;
        };
      }>
    ) {
      state.pagination = action.payload.pagination;
      state.loading = true;
      state.error = undefined;
    },
    setPaginationSuccess(
      state,
      action: PayloadAction<{
        ordersList: IOrderOut[];
        totalPagesCount: number;
      }>
    ) {
      state.ordersList = action.payload.ordersList;
      state.totalPagesCount = action.payload.totalPagesCount;
      state.loading = false;
    },
    setPaginationError(state, action: PayloadAction<{ error: string }>) {
      state.loading = false;
      state.ordersList = [];
      state.error = action.payload.error;
    },
    shipOrder(
      state,
      action: PayloadAction<{
        orderId?: string;
        awb?: string;
        successMessage?: string;
        pagination?: {
          pageNumber: number;
          pageSize: number;
          warehouseId?: string;
          storeId?: string;
        };
      }>
    ) {},
  },
});

export const {
  setPagination,
  setPaginationSuccess,
  setPaginationError,
  shipOrder,
} = ordersSlice.actions;

export default ordersSlice.reducer;
