import styled from "styled-components";

export const DropdownRow = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 7px 4px 13px;
  padding-right: 7px;
  cursor: pointer;
  background: ${(p) => (p.selected ? "#AAAB5A1A" : "")};
  &:hover {
    background: #aaab5a1a;
  }
`;
