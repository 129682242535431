import { IOrderOut, IWarehouseListOut } from "openapi/types";
import { Text } from "atoms";
import { OrderProductRow } from "./components/OrderProductRow/OrderProductRow";

interface IOrderTableProps {
  warehouseList: IWarehouseListOut[];
  order: IOrderOut;
  disabled: boolean;
}

export const OrderProductsTable = ({
  warehouseList,
  order,
  disabled,
}: IOrderTableProps) => {
  return (
    <div>
      {warehouseList.map((warehouse) => (
        <>
          <Text className="mt-2" bold color="white">
            {warehouse.title}
          </Text>
          <div style={{ display: "grid" }}>
            {order?.products
              ?.filter(
                (prod) => prod.product?.warehouse === warehouse.warehouseId
              )
              ?.slice()
              .sort((a, b) => {
                const nameA = a.product?.name ?? "";
                const nameB = b.product?.name ?? "";
                return nameA?.localeCompare(nameB);
              })
              .map((product) => (
                <OrderProductRow
                  orderId={order.orderId}
                  orderProduct={product}
                  disabled={disabled}
                />
              ))}
          </div>
        </>
      ))}
    </div>
  );
};
