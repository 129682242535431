import styled from "styled-components";

export const ChildrenWrapper = styled.div`
  position: absolute;
  min-width: 100%;
  left: 0px;
  top: 0px;

  z-index: 50;

  background: #ffffff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
`;
