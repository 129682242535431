import { useEffect, useState } from "react";
import { LoginContainer } from "./atoms/LoginContainer";
import { LoginBox } from "./atoms/LoginBox";
import Input from "components/Input";
import Button from "components/Button";
import { Text } from "atoms";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "containers/App/slice";
import { selectLoginError, selectUser } from "containers/App/slice/selectors";
import { useNavigate } from "react-router-dom";
import { About } from "components/landingpage/about";
import { Contact } from "components/landingpage/contact";
import { Features } from "components/landingpage/features";
import { Gallery } from "components/landingpage/gallery";
import { Header } from "components/landingpage/header";
import { Navigation } from "components/landingpage/navigation";
import { Services } from "components/landingpage/services";
import { Team } from "components/landingpage/Team";
import { Testimonials } from "components/landingpage/testimonials";
import JsonData from "../../data/data.json";


interface ILandingPageData {
 Header:{},
 Features:{},
 About:{},
 Services:{},
 Gallery:{},
 Testimonials:{},
 Team:{},
 Contact:{}
}

export const LandingPage = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const loginError = useSelector(selectLoginError);
  const [landingPageData, setLandingPageData] = useState<ILandingPageData>();
  
  useEffect(() => {
    setLandingPageData(JsonData);
    if (user) {
      navigate("/");
    }
  }, [user]);

  const handleLogin = () => {
    dispatch(loginUser({ email: email, password: password }));
  };

  return (
    <div>
      <LoginContainer>
        <LoginBox>
          <Text bold={true} fontSize="20px">
            AltfelDistrib
          </Text>
          <div>
            <div className="d-flex flex-row">
              <Input
                className="mr-2"
                key={"email"}
                type="email"
                placeholder="Email"
                value={email}
                onChange={(value) => setEmail(value)}
              />
              <Input
                className="mr-2"
                key={"password"}
                type="password"
                placeholder="Password"
                value={password}
                onChange={(value) => setPassword(value)}
              />
              <Button className="mr-2" onClick={handleLogin}>
                Login
              </Button>
            </div>
            {loginError && (
              <Text color={"red"} bold>
                {loginError}
              </Text>
            )}
          </div>
        </LoginBox>
        <div>
          <Header data={landingPageData?.Header} />
          <Features data={landingPageData?.Features} />
          <About data={landingPageData?.About} />
          <Services data={landingPageData?.Services} />
          <Testimonials data={landingPageData?.Testimonials} />
          <Contact data={landingPageData?.Contact} />
        </div>
      </LoginContainer>
    </div>
  );
};


