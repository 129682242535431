import Button from "components/Button";
import FormInput from "components/FormInput";
import { Text } from "atoms";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Modal from "components/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPagination } from "containers/StoresPage/slice/selectors";
import { IWarehouseIn } from "openapi/types";
import { WarehouseIn } from "utils/interfaces/interfaces";
import {
  createWarehouse,
  updateWarehouse,
} from "containers/WarehousesPage/slice";
import { ModalContentWrapper } from "./atoms/ModalContentWrapper";

interface IWarehouseModalProps {
  onClose: () => void;
  warehouse?: IWarehouseIn;
}

export const AddWarehouseModal = ({
  onClose,
  warehouse,
}: IWarehouseModalProps) => {
  const [selectedWarehouse, setSelectedWarehouse] = useState<IWarehouseIn>(
    new WarehouseIn()
  );
  const pagination = useSelector(selectPagination);
  const dispatch = useDispatch();

  useEffect(() => {
    if (warehouse) {
      setSelectedWarehouse(warehouse);
    }
  }, []);

  const onAction = () => {
    if (warehouse) {
      dispatch(updateWarehouse({ warehouse: selectedWarehouse, pagination }));
    } else {
      dispatch(createWarehouse({ warehouse: selectedWarehouse, pagination }));
    }
    setSelectedWarehouse(new WarehouseIn());
    onClose();
  };

  const updateWarehouseVariables = (key: keyof IWarehouseIn, value) => {
    setSelectedWarehouse({
      ...selectedWarehouse,
      [key]: value,
    });
  };

  return (
    <Modal isVisible={true} onClose={onClose}>
      <ModalContentWrapper>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <div className="mt-3">
          <Text bold fontSize="20px">
            Informatii depozit
          </Text>
          <FormInput
            name="Nume"
            value={selectedWarehouse?.title ?? ""}
            onChange={(value) => {
              updateWarehouseVariables("title", value);
            }}
          />
          <FormInput
            name="Adresa"
            value={selectedWarehouse?.address?.address ?? ""}
            onChange={(value) => {
              updateWarehouseVariables("address", {
                ...selectedWarehouse.address,
                address: value,
              });
            }}
          />
          <FormInput
            name="Oras"
            value={selectedWarehouse?.address?.city ?? ""}
            onChange={(value) => {
              updateWarehouseVariables("address", {
                ...selectedWarehouse.address,
                city: value,
              });
            }}
          />
          <FormInput
            name="Judet"
            value={selectedWarehouse?.address?.county ?? ""}
            onChange={(value) => {
              updateWarehouseVariables("address", {
                ...selectedWarehouse.address,
                county: value,
              });
            }}
          />
          <FormInput
            name="Tara"
            value={selectedWarehouse?.address?.country ?? ""}
            onChange={(value) => {
              updateWarehouseVariables("address", {
                ...selectedWarehouse.address,
                country: value,
              });
            }}
          />
        </div>
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button onClick={onAction}>
            {warehouse ? "Editeaza" : "Adauga"}
          </Button>
        </div>
      </ModalContentWrapper>
    </Modal>
  );
};
