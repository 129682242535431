import { FunctionComponent, useEffect, useRef } from "react";

interface IClickOutsideWrapperProps {
  onDismiss: () => void;
  className?: string;
  children?: React.ReactNode;
}

export const ClickOutsideWrapper: FunctionComponent<
  IClickOutsideWrapperProps
> = (props) => {
  const { children, onDismiss, className } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onDismiss();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div ref={wrapperRef} className={className}>
      {children}
    </div>
  );
};
