import styled from "styled-components";

export const PaginationWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  margin: 10px 10px 15px 10px;
  @media only screen and (max-width: 600px), (max-height: 600px) {
    justify-content: center;
  }
`;
