import {
  selectLoading,
  selectOrder,
} from "containers/CartPage/slice/selectors";
import { useDispatch, useSelector } from "react-redux";
import { IWarehouseListOut } from "openapi/types";
import { Text } from "atoms";
import { TableWrapper } from "./atoms/TableWrapper";
import { CartWrapper } from "./atoms/CartWrapper";
import { SummaryWrapper } from "./atoms/SummaryWrapper";
import LoadingSpinner from "components/LoadingSpinner";
import Button from "components/Button";
import { executeOrder } from "containers/CartPage/slice";
import { selectWarehouseList } from "containers/ProductsPage/slice/selectors";
import { useEffect, useState } from "react";
import { getTotalPriceOfProducts } from "utils/UtilFunctions";
import { OrderProductsTable } from "../../../../components/OrderProductsTable/OrderProductsTable";
import { useNavigate } from "react-router-dom";

export const CartTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order = useSelector(selectOrder);
  const loading = useSelector(selectLoading);
  const warehouseList = useSelector(selectWarehouseList);
  const [foundWarehouses, setFoundWarehouses] = useState<IWarehouseListOut[]>(
    []
  );

  useEffect(() => {
    const list: IWarehouseListOut[] = [];
    warehouseList.map((warehouse) => {
      if (warehouse.warehouseId) {
        const products = order?.products?.filter((prod) => {
          if (prod.product?.warehouse === warehouse.warehouseId) return true;
          return false;
        });
        if (products && products.length > 0) {
          list.push(warehouse);
        }
      }
    });
    setFoundWarehouses(list);
  }, [warehouseList, order]);

  const handleExecution = () => {
    if (
      order &&
      order.orderId &&
      order.products &&
      order.products?.length > 0
    ) {
      dispatch(
        executeOrder({
          orderId: order?.orderId,
          successMessage: "Comanda a fost trimisă cu succes!",
          navigate,
        })
      );
    }
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <TableWrapper>
          <CartWrapper>
            <div
              className="d-flex flex-row justify-content-between align-items-center"
              style={{
                borderBottom: "1px solid white",
                paddingBottom: "25px",
              }}
            >
              <Text bold fontSize="30px" color="white">
                Cos cumparaturi
              </Text>
              <Text bold fontSize="14px" color="white">
                {order ? (
                  <>
                    {order?.products && order?.products?.length === 1
                      ? `${order?.products?.length} produs`
                      : `${order?.products?.length} produse`}
                  </>
                ) : (
                  "0 produse"
                )}
              </Text>
            </div>
            {order?.products && order.products.length > 0 ? (
              <OrderProductsTable
                warehouseList={foundWarehouses}
                order={order}
                disabled={false}
              />
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "80%" }}
              >
                <Text fontSize="18px" bold color="white">
                  Cosul este gol. Adaugati produse
                </Text>
              </div>
            )}
          </CartWrapper>
          <SummaryWrapper>
            <div>
              <Text bold fontSize="30px" color="black">
                Sumar
              </Text>
              {foundWarehouses.map((warehouse) => (
                <div>
                  <Text bold fontSize="18px">
                    {warehouse.title}
                  </Text>
                  <Text
                    color="black"
                    fontSize="18px"
                  >{`Pret: ${getTotalPriceOfProducts(
                    order?.products ?? [],
                    warehouse.warehouseId
                  )} RON`}</Text>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-between align-items-end">
              <Text
                fontSize="26px"
                color="black"
              >{`Pret total: ${getTotalPriceOfProducts(
                order?.products ?? []
              )} RON`}</Text>
              <Button onClick={handleExecution} style={{ height: "40px" }}>
                Trimite comanda
              </Button>
            </div>
          </SummaryWrapper>
        </TableWrapper>
      )}
    </>
  );
};
