import styled from "styled-components";

export const HeaderWrapper = styled.div<{ bordered?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;

  cursor: pointer;
  height: 28px;
  padding-left: 13px;
  padding-right: 7px;
  border: ${(p) => (p.bordered ? "1px solid #EAEAEA;" : "")};
`;
