import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from ".";

const selectSession = (state: RootState) => state?.session || initialState;

export const selectUser = createSelector(
  [selectSession],
  (session) => session.user
);

export const selectUserStore = createSelector(
  [selectSession],
  (session) => session.store
);

export const selectUserWarehouse = createSelector(
  [selectSession],
  (session) => session.warehouse
);

export const selectUserProducer = createSelector(
  [selectSession],
  (session) => session.producer
);

export const selectLoginError = createSelector(
  [selectSession],
  (session) => session.loginError
);

export const selectRegisterError = createSelector(
  [selectSession],
  (session) => session.registerError
);

export const selectLoading = createSelector(
  [selectSession],
  (session) => session.loading
);

export const selectAccessToken = createSelector(
  [selectSession],
  (session) => session.accessToken
);
