import { loadState, saveState } from "utils/localStorage";
import { ISessionState } from "./types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  IProducerOut,
  IRegisterRequest,
  IStoreOut,
  IUser,
  IWarehouseOut,
} from "openapi/types";

const state = loadState();

export const initialState: ISessionState = {
  language: "ro",
  accessToken: state?.accessToken ?? "",
  refreshToken: state?.refreshToken ?? "",
  user: state?.user,
  loginError: undefined,
  registerError: undefined,
  loading: false,
  store: state?.store ?? undefined,
  warehouse: state?.warehouse ?? undefined,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    loginUser(
      state,
      action: PayloadAction<{ email: string; password: string }>
    ) {
      state.loading = true;
      state.loginError = undefined;
    },
    loginSuccess(
      state,
      action: PayloadAction<{
        accessToken: string;
        refreshToken: string;
        user: IUser;
        store?: IStoreOut;
        warehouse?: IWarehouseOut;
        producer?: IProducerOut;
      }>
    ) {
      state.loading = false;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.user = action.payload.user;
      state.store = action.payload.store;
      state.warehouse = action.payload.warehouse;
      state.producer = action.payload.producer;
      saveState({
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        user: state.user,
        store: state.store,
        warehouse: state.warehouse,
        producer: state.producer,
      });
    },
    loginError(state, action: PayloadAction<{ error: string }>) {
      state.loading = false;
      state.loginError = action.payload.error;
    },

    logoutUser(state) {
      state.accessToken = "";
      state.refreshToken = "";
      state.user = undefined;
      saveState({});
    },

    registerUser(
      state,
      action: PayloadAction<{ registerRequest: IRegisterRequest }>
    ) {
      state.loading = true;
      state.registerError = undefined;
    },
    registerSuccess(state) {
      state.loading = false;
    },
    registerError(state, action: PayloadAction<{ error: string }>) {
      state.loading = false;
      state.registerError = action.payload.error;
    },
  },
});

export const {
  loginUser,
  loginSuccess,
  loginError,
  logoutUser,
  registerUser,
  registerSuccess,
  registerError,
} = sessionSlice.actions;

export default sessionSlice.reducer;
