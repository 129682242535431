import { RootState } from "types";
import { initialState } from ".";
import { createSelector } from "@reduxjs/toolkit";

const selectSingleOrder = (state: RootState) =>
  state.singleOrder || initialState;

export const selectOrder = createSelector(
  [selectSingleOrder],
  (singleOrder) => singleOrder.order
);

export const selectLoading = createSelector(
  [selectSingleOrder],
  (singleOrder) => singleOrder.loading
);

export const selectError = createSelector(
  [selectSingleOrder],
  (singleOrder) => singleOrder.error
);
