import styled from "styled-components";

export const LoginBox = styled.div`
  top: 0;
  z-index:1000;
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1)
  text-align: center;
`;
