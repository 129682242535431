import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { initialState } from ".";

const selectToastMessageDetails = (state: RootState) =>
  state?.toastMessage || initialState;

export const selectToastMessage = createSelector(
  [selectToastMessageDetails],
  (session) => session.message
);

export const selectToastType = createSelector(
  [selectToastMessageDetails],
  (session) => session.toastType
);

export const selectIsVisilbe = createSelector(
  [selectToastMessageDetails],
  (session) => session.isVisible
);
