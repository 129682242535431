import styled, { css } from "styled-components";

export const Wrapper = styled.div<{ isVisible?: boolean }>`
  display: none;

  ${(p) =>
    p.isVisible &&
    css`
      background-color: rgb(0, 0, 0, 0.6);

      backdrop-filter: blur(6px);

      z-index: 1000;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      overflow: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;
