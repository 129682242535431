import styled from "styled-components";

export const Button = styled.button`
  background-color: #007bff;
  min-width: 60px;
  max-width: 150px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;
