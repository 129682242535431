import { ActionType } from "typesafe-actions";
import {
  loginError,
  loginSuccess,
  loginUser,
  registerError,
  registerSuccess,
  registerUser,
} from ".";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  authenticationApiInstance,
  producerApiInstance,
  storeApiInstance,
  warehouseApiInstance,
} from "services";
import i18n from "i18next";
import {
  IProducerOut,
  IStoreOut,
  IUser,
  IUserRoleEnum,
  IWarehouseOut,
} from "openapi/types";
import { decodeJwtPayload } from "utils/jwtAuth";

export function* loginUserSaga(action: ActionType<typeof loginUser>) {
  const { email, password } = action.payload;

  try {
    const response = yield call(
      [authenticationApiInstance, authenticationApiInstance.login],
      { email, password }
    );
    let { accessToken, refreshToken } = response.data;
    let user: IUser & { sub: string } = decodeJwtPayload(accessToken);
    user.email = user.sub;
    const userId = user.userId ?? "";
    i18n.changeLanguage(user.language);
    let store: IStoreOut | undefined = undefined;
    let warehouse: IWarehouseOut | undefined = undefined;
    let producer: IProducerOut | undefined = undefined;
    if (user.role === IUserRoleEnum.Store) {
      try {
        const storeResponse = yield call(
          [storeApiInstance, storeApiInstance.getStoreByUser],
          userId
        );
        store = storeResponse.data;
      } catch (e: any) {
        console.log("Store not found for user!");
      }
      try {
        if (store && store.storeId) {
          const storeWarehouseResponse = yield call(
            [storeApiInstance, storeApiInstance.getWarehouseByStoreId],
            store?.storeId ?? ""
          );
          warehouse = storeWarehouseResponse.data;
        }
      } catch (e: any) {
        console.log("Default warehouse not found for store!");
      }
    } else if (user.role === IUserRoleEnum.Producer) {
      try {
        const producerResponse = yield call(
          [producerApiInstance, producerApiInstance.getProducerByUserId],
          userId
        );
        producer = producerResponse.data;
      } catch (e: any) {
        console.log("Producer not found for user");
      }
    } else if (user.role === IUserRoleEnum.Warehouse) {
      try {
        const userWarehouseResponse = yield call(
          [warehouseApiInstance, warehouseApiInstance.getWarehouseByUserId],
          userId
        );
        warehouse = userWarehouseResponse.data;
      } catch (e: any) {
        console.log("Warehouse not found for user");
      }
    }
    yield put(
      loginSuccess({
        refreshToken,
        accessToken,
        user,
        store,
        warehouse,
        producer,
      })
    );
  } catch (e: any) {
    const { data } = e.response;
    yield put(loginError({ error: data ?? "Something went wrong" }));
  }
}

export function* registerUserSaga(action: ActionType<typeof registerUser>) {
  const { registerRequest } = action.payload;

  try {
    const response = yield call(
      [authenticationApiInstance, authenticationApiInstance.register],
      registerRequest
    );
    i18n.changeLanguage(registerRequest.language);
    yield put(registerSuccess());
  } catch (e: any) {
    const { data } = e.response;
    yield put(registerError({ error: data ?? "Something went wrong" }));
  }
}

export function* sessionSaga() {
  yield takeLatest(loginUser.type, loginUserSaga);
  yield takeLatest(registerUser.type, registerUserSaga);
}
