import { Image } from "atoms/Image";
import { ChangeEvent, useEffect, useState } from "react";
import { ImagePlaceholder } from "./atoms/ImagePlaceholder";
import { useDispatch } from "react-redux";
import { displayToastMessage } from "components/ToastMessage/slice";
import { ToastType } from "components/ToastMessage/slice/types";

interface IImageUploaderProps {
  image?: string;
  onChange: (image: File) => void;
}

export const ImageUploader = ({ image, onChange }: IImageUploaderProps) => {
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    if (image) {
      setSelectedImage(image);
    }
  }, [image]);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type.includes("image")) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      onChange(file);
    } else {
      dispatch(displayToastMessage({
        message: "Selecteaza o poza!",
        type: ToastType.Error
      }))
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        {selectedImage ? (
          <Image height="80px" width="80px" className="mt-2" src={selectedImage} onClick={handleClick} />
        ) : (
          <ImagePlaceholder className="mt-2" onClick={handleClick} />
        )}
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
        id="fileInput"
      />
    </div>
  );
};
