import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { ClickOutsideWrapper } from "components/ClickOutsideWrapper/ClickOutsideWrapper";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Wrapper } from "./atoms/Wrapper";
import { HeaderWrapper } from "./atoms/HeaderWrapper";
import { ChildrenWrapper } from "./atoms/ChildrenWrapper";

interface ICollapseItemProps {
  title: React.ReactNode;
  children: React.ReactNode;
  bordered?: boolean;
  disabled?: boolean;
  id?: string;
}

export const CollapseItem = forwardRef((props: ICollapseItemProps, ref) => {
  const { id, title, children, bordered } = props;

  const [opened, setOpened] = useState(false);

  useImperativeHandle(ref, () => ({
    toggle() {
      setOpened((val) => !val);
    },
    open() {
      if (!props.disabled) setOpened(true);
    },
    close() {
      setOpened(false);
    },
  }));

  return (
    <ClickOutsideWrapper onDismiss={() => setOpened(false)}>
      <Wrapper
        data-testid={id}
        onClick={() => {
          if (!props.disabled) setOpened(true);
        }}
      >
        <HeaderWrapper bordered={bordered}>
          {title}
          <KeyboardArrowDownOutlined
            fontSize="small"
            style={{ marginLeft: "auto" }}
          />
        </HeaderWrapper>
        {opened && (
          <ChildrenWrapper onClick={(event) => event.stopPropagation()}>
            {children}
          </ChildrenWrapper>
        )}
      </Wrapper>
    </ClickOutsideWrapper>
  );
});
