import { useState } from "react";
import { Wrapper } from "./atoms/Wrapper";
import { IconButton } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { CollapseWrapper } from "./atoms/CollapseWrapper";
import TopbarMenuLinks from "./TopbarMenuLinks";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "containers/App/slice";
import { LinkWrapperDiv } from "./atoms/LinkWrapper";
import { BackButton } from "./atoms/BackButton";
import { RouteVar } from "utils/constants";
import { selectUser } from "containers/App/slice/selectors";
import { IUserRoleEnum } from "openapi/types";

export const TopbarProfile = () => {
  const [collapse, setCollapse] = useState(false);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <IconButton
        style={{ backgroundColor: "transparent", color: "black" }}
        onClick={() => setCollapse((val) => !val)}
      >
        <AccountCircle className="mr-3" />
      </IconButton>
      {collapse && (
        <BackButton type="button" onClick={() => setCollapse((val) => !val)} />
      )}
      <CollapseWrapper isOpen={collapse}>
        <div>
          {user?.role === IUserRoleEnum.Store && (
            <TopbarMenuLinks
              title="Editează Profil"
              path={RouteVar.profilePage.Route}
              onClick={() => setCollapse((val) => !val)}
            />
          )}
          <LinkWrapperDiv onClick={() => dispatch(logoutUser())}>
            <p style={{ fontSize: "14px", margin: 0, lineHeight: "16px" }}>
              Delogare
            </p>
          </LinkWrapperDiv>
        </div>
      </CollapseWrapper>
    </Wrapper>
  );
};
