import styled from "styled-components";

export const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  grid-gap: 15px;
  align-items: center;
  margin: 25px 25px 0px 25px;
  border-bottom: 1px solid white;
  padding-bottom: 15px;
`;
