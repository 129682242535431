import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  box-shadow: 0px 4px 40px rgba(160, 174, 192, 0.15);
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 100px);

  @media only screen and (max-width: 600px), (max-height: 600px) {
    max-height: calc(100vh - 30px);
    max-width: calc(100vw - 30px);
  }
`;
