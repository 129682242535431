import { ReactNode } from "react";
import { ISidebarItemTypeEnum } from "./enums";
import {
  IProducerIn,
  IProductIn,
  IStoreIn,
  IUser,
  IWarehouseIn,
} from "openapi/types";

export default {};

export interface ISvgProps {
  width?: string;
  height?: string;
  color?: string;
}

export interface ISidebarElement {
  type: ISidebarItemTypeEnum;
  title: string;
  route?: string;
  svg?: ReactNode;
  children?: ISidebarElement[];
}

export class StoreIn implements IStoreIn {
  storeId = "";
  name = "";
  storeAddress = undefined;
  paymentTerm = 0;
  paymentMethod = undefined;
  bonus = 0;
  volumeDiscount = 0;
  regularityDiscount = 0;
  exclusiveDiscount = 0;
  score = 0;
  users: IUser[] = [];
  isActive = true;
}

export class ProducerIn implements IProducerIn {
  producerId = "";
  name = "";
  address = undefined;
  paymentTerm = 0;
  paymentMethod = undefined;
  listingFee = 0;
  positioningFee = 0;
  communicationFee = 0;
  logisticDiscount = 0;
  score = 0;
  products: IProductIn[] = [];
}

export class WarehouseIn implements IWarehouseIn {
  warehouseId = "";
  title = "";
  address = undefined;
}

export class User implements IUser {
  userId = "";
  firstName = "";
  lastName = "";
  email = "";
  password = "";
  role = undefined;
  store = "";
  warehouse = "";
  producer = "";
}

export class ProductIn implements IProductIn {
  productId = "";
  name = "";
  picture = "";
  isActive = false;
  price = 0;
  producers = [];
  productUnit = "";
  stock = 0;
  warehouse = "";
}

export interface IProductImage {
  productId: string;
  link: string;
}

export interface IDashboard {
  title: string;
  xAxis: string;
  yAxis: string;
  series: string;
  data: [string, number][];
}
