import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import CloseIcon from "mdi-react/CloseIcon";
import { selectIsVisilbe, selectToastMessage, selectToastType } from "./slice/selectors";
import { hideToastMessage } from "./slice";
import { ToastType } from "./slice/types";

export const ToastMessage = () => {
  const { t } = useTranslation("common");

  const dispatch = useDispatch();

  const toastMessage = useSelector(selectToastMessage);
  const toastType = useSelector(selectToastType);
  const toastVisible = useSelector(selectIsVisilbe);

  const [activeTimeout, setActiveTimeout] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (toastVisible) {
      if (activeTimeout) {
        clearTimeout(activeTimeout);
      }
      const timeout = setTimeout(() => {
        dispatch(hideToastMessage());
      }, 5000);
      setActiveTimeout(timeout);
    }
  }, [toastVisible]);

  const hideToast = () => {
    if (activeTimeout) {
      clearTimeout(activeTimeout);
    }
    dispatch(hideToastMessage());
    setActiveTimeout(undefined);
  };

  const getToastColorByType = () => {
    switch (toastType) {
      case ToastType.Success:
        return "#53CA43";
      case ToastType.Error:
        return "#C54747";
      case ToastType.Warning:
        return "#CA8F43";
      case ToastType.Info:
        return "#628ECD";
      default:
        return "#FFFFFF";
    }
  };

  return (
    <Wrapper visible={toastVisible}>
      <ToastTypeWrapper color={getToastColorByType()} />
      <ToastMessageWrapper>{t(toastMessage)}</ToastMessageWrapper>
      <ToastCloseButton onClick={hideToast}>
        <CloseIcon />
      </ToastCloseButton>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ visible: boolean }>`
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};

  position: fixed;
  bottom: 15px;

  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: stretch;
  flex-flow: row;

  margin: auto;
  box-shadow: 0 2px 10px 1px #b5b5b5;

  min-width: 200px;
  max-width: 500px;
  background: #ffffff;

  z-index: 500;
`;

const ToastTypeWrapper = styled.div<{ color: string }>`
  background-color: ${(props) => props.color || "#FFFFFF"};
  width: 5px;
`;

const ToastMessageWrapper = styled.p`
  display: flex;
  align-items: center;

  margin: 0;
  padding: 8px 10px;
  font-size: 14px;
`;

const ToastCloseButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 5px;
`;
