import { ActionType } from "typesafe-actions";
import {
  createWarehouse,
  deleteWarehouse,
  setPagination,
  setPaginationError,
  setPaginationSuccess,
  updateWarehouse,
} from ".";
import { call, put, takeLatest } from "redux-saga/effects";
import { warehouseApiInstance } from "services";

export function* setPaginationSaga(action: ActionType<typeof setPagination>) {
  const { pagination } = action.payload;
  try {
    const pageSize = pagination.pageSize;
    const pageNumber = pagination.pageNumber;
    const response = yield call(
      [warehouseApiInstance, warehouseApiInstance.getAllWarehouse],
      pageSize,
      pageNumber
    );
    const totalPagesCount = response.data.totalPagesCount;
    yield put(
      setPaginationSuccess({
        warehouseList: response.data.warehouseDaoList,
        totalPagesCount,
      })
    );
  } catch (e: any) {
    const response = e;
    yield put(
      setPaginationError({
        error: response.data.message ?? "Something went wrong",
      })
    );
  }
}

export function* updateWarehouseSaga(
  action: ActionType<typeof updateWarehouse>
) {
  const { warehouse, pagination } = action.payload;

  try {
    yield call(
      [warehouseApiInstance, warehouseApiInstance.updateWarehouse],
      warehouse
    );
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
  }
}

export function* deleteWarehouseSaga(
  action: ActionType<typeof deleteWarehouse>
) {
  const { warehouseId, pagination } = action.payload;

  try {
    yield call(
      [warehouseApiInstance, warehouseApiInstance.deleteWarehouseById],
      warehouseId
    );
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
  }
}

export function* createWarehouseSaga(
  action: ActionType<typeof createWarehouse>
) {
  const { warehouse, pagination } = action.payload;

  try {
    yield call(
      [warehouseApiInstance, warehouseApiInstance.registerWarehouse],
      warehouse
    );
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
  }
}

export function* warehousesSaga() {
  yield takeLatest(setPagination.type, setPaginationSaga);
  yield takeLatest(createWarehouse.type, createWarehouseSaga);
  yield takeLatest(updateWarehouse.type, updateWarehouseSaga);
  yield takeLatest(deleteWarehouse.type, deleteWarehouseSaga);
}
