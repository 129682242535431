import Button from "components/Button";
import { useEffect, useState } from "react";
import { ProductsTable } from "./ProductsTable";
import { AddProductModal } from "./components/ProductModal/AddProductModal";
import { loadWarehouses } from "./slice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "containers/App/slice/selectors";
import { IUserRoleEnum } from "openapi/types";

export const ProductsPage = () => {
  const user = useSelector(selectUser);
  const [addModalVisibility, setAddModalVisibility] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadWarehouses());
  }, []);

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "end" }}
        className="mr-2 mt-2"
      >
        {user?.role === IUserRoleEnum.SysAdmin ||
          (user?.role === IUserRoleEnum.Producer && (
            <Button
              style={{ width: "10%" }}
              onClick={() => setAddModalVisibility(true)}
            >
              Add
            </Button>
          ))}
      </div>
      <ProductsTable />
      {addModalVisibility && (
        <AddProductModal onClose={() => setAddModalVisibility(false)} />
      )}
    </div>
  );
};
