import { useState } from "react";
import { Wrapper } from "./atoms/Wrapper";
import { BackButton } from "./atoms/BackButton";
import { CollapseWrapper } from "./atoms/CollapseWrapper";
import TopbarMenuLinks from "./TopbarMenuLinks";
import { ListItemWrapperDiv } from "../atoms/ListItemWrapper";
import { RouteVar } from "utils/constants";

export const TopbarAdmin = () => {
  const [collapse, setCollapse] = useState(false);
  return (
    <Wrapper>
      <ListItemWrapperDiv className="ml-5" onClick={() => setCollapse(true)}>
        ADMINISTRAȚIE
      </ListItemWrapperDiv>
      {collapse && (
        <BackButton type="button" onClick={() => setCollapse((val) => !val)} />
      )}
      <CollapseWrapper isOpen={collapse}>
        <div>
          <TopbarMenuLinks
            title="Magazine"
            path={RouteVar.storesPage.Route}
            onClick={() => setCollapse((val) => !val)}
          />
          <TopbarMenuLinks
            title="Producători"
            path={RouteVar.producersPage.Route}
            onClick={() => setCollapse((val) => !val)}
          />
          <TopbarMenuLinks
            title="Depozite"
            path={RouteVar.warehousesPage.Route}
            onClick={() => setCollapse((val) => !val)}
          />
          <TopbarMenuLinks
            title="Plăți"
            path={RouteVar.paymentsPage.Route}
            onClick={() => setCollapse((val) => !val)}
          />
          <TopbarMenuLinks
            title="Useri"
            path={RouteVar.usersPage.Route}
            onClick={() => setCollapse((val) => !val)}
          />
        </div>
      </CollapseWrapper>
    </Wrapper>
  );
};
