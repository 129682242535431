import { ActionType } from "typesafe-actions";
import {
  deleteOrderProduct,
  executeOrder,
  loadOrder,
  loadOrderError,
  loadOrderSuccess,
  updateOrder,
} from ".";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  orderApiInstance,
  orderProductApiInstance,
  storeApiInstance,
} from "services";
import { IOrderOut, IOrderStateEnum } from "openapi/types";
import { displayToastMessage } from "components/ToastMessage/slice";
import { ToastType } from "components/ToastMessage/slice/types";
import { setPaginationSaga } from "containers/OrdersPage/slice/saga";
import { setPagination } from "containers/OrdersPage/slice";

export function* loadOrderSaga(action: ActionType<typeof loadOrder>) {
  const { storeId } = action.payload;

  try {
    const response = yield call(
      [storeApiInstance, storeApiInstance.getOrdersByStoreId],
      storeId,
      1,
      0,
      IOrderStateEnum.Placed
    );
    const order: IOrderOut = response.data.orderDaoList[0];
    yield put(loadOrderSuccess({ order }));
  } catch (e: any) {
    yield put(loadOrderError({ error: e.message }));
    console.log("Something went wrong:", e.message);
  }
}

export function* updateOrderSaga(action: ActionType<typeof updateOrder>) {
  const { storeId, orderId, productId, quantity } = action.payload;

  try {
    yield call(
      [orderProductApiInstance, orderProductApiInstance.orderUpdateQuantity],
      orderId,
      productId,
      quantity
    );
    yield call(loadOrderSaga, loadOrder({ storeId }));
  } catch (e: any) {
    const response = e;
    console.log(
      "Something went wrong when updating product quantity:",
      response.message
    );
  }
}

export function* deleteOrderProductSaga(
  action: ActionType<typeof deleteOrderProduct>
) {
  const { storeId, orderId, productId } = action.payload;

  try {
    yield call(
      [orderProductApiInstance, orderProductApiInstance.orderRemoveProduct],
      orderId,
      productId
    );
    yield call(loadOrderSaga, loadOrder({ storeId }));
  } catch (e: any) {
    const response = e;
    console.log(
      "Something went wrong when deleting product from order:",
      response.message
    );
  }
}

export function* executeOrderSaga(action: ActionType<typeof executeOrder>) {
  const { orderId, successMessage, navigate, pagination } = action.payload;

  try {
    yield call([orderApiInstance, orderApiInstance.executeOrder], { orderId });
    if (successMessage) {
      yield put(
        displayToastMessage({
          message: successMessage,
          type: ToastType.Success,
        })
      );
    }
    if (navigate) {
      navigate("/orders");
    }
    if (pagination) {
      yield call(setPaginationSaga, setPagination({ pagination }));
    }
  } catch (e: any) {
    const response = e;
    yield put(
      displayToastMessage({
        message: "A aparut a problema la executare comenzii",
        type: ToastType.Error,
      })
    );
    console.log("Something went wrong when executing order:", response.message);
  }
}

export function* orderSaga() {
  yield takeLatest(loadOrder.type, loadOrderSaga);
  yield takeLatest(updateOrder.type, updateOrderSaga);
  yield takeLatest(deleteOrderProduct.type, deleteOrderProductSaga);
  yield takeLatest(executeOrder.type, executeOrderSaga);
}
