import styled from "styled-components";

export const TableContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  flex: 1;
  min-height: max(80vh, 450px);

  @media only screen and (max-width: 600px), (max-height: 600px) {
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
  }
`;
