import Button from "components/Button";
import FormInput from "components/FormInput";
import { Text } from "atoms";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import Modal from "components/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPagination } from "containers/StoresPage/slice/selectors";
import { IUser, IUserRoleEnum } from "openapi/types";
import { User } from "utils/interfaces/interfaces";
import { ModalContentWrapper } from "./atoms/ModalContentWrapper";
import { createUser, updateUser } from "containers/UsersPage/slice";
import { Dropdown } from "components/Dropdown/Dropdown";
import { getUserRoleDropdownOptions } from "utils/enumUtils";
import {
  selectProducerList,
  selectStoreList,
  selectWarehouseList,
} from "containers/UsersPage/slice/selectors";

interface IUserModalProps {
  onClose: () => void;
  user?: IUser;
}

export const AddUserModal = ({ onClose, user }: IUserModalProps) => {
  const [selectedUser, setSelectedUser] = useState<IUser>(new User());
  const [selectedPassword, setSelectedPassword] = useState<string>("");
  const pagination = useSelector(selectPagination);
  const warehouseList = useSelector(selectWarehouseList);
  const producerList = useSelector(selectProducerList);
  const storeList = useSelector(selectStoreList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setSelectedUser(user);
    }
  }, []);

  const onAction = () => {
    if (user) {
      dispatch(updateUser({ user: selectedUser, pagination }));
    } else {
      dispatch(
        createUser({
          user: selectedUser,
          password: selectedPassword,
          pagination,
        })
      );
    }
    setSelectedUser(new User());
    onClose();
  };

  const updateUserVariables = (key: keyof IUser, value) => {
    setSelectedUser({
      ...selectedUser,
      [key]: value,
    });
  };

  return (
    <Modal isVisible={true} onClose={onClose}>
      <ModalContentWrapper>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </div>
        <div className="mt-3">
          <Text bold fontSize="20px">
            Informatii user
          </Text>
          <FormInput
            name="Nume"
            value={selectedUser?.lastName ?? ""}
            onChange={(value) => {
              updateUserVariables("lastName", value);
            }}
          />
          <FormInput
            name="Prenume"
            value={selectedUser?.firstName ?? ""}
            onChange={(value) => {
              updateUserVariables("firstName", value);
            }}
          />
          <FormInput
            name="Email"
            value={selectedUser?.email ?? ""}
            onChange={(value) => {
              updateUserVariables("email", value);
            }}
          />
          {!user && (
            <FormInput
              name="Password"
              type="password"
              value={selectedPassword ?? ""}
              onChange={(value) => {
                setSelectedPassword(value);
              }}
            />
          )}
          <Text bold>Rol:</Text>
          <Dropdown
            id="roleDropdown"
            bordered
            title="Rol"
            selectedItemId={selectedUser.role?.toString() ?? ""}
            onSelect={(value) => {
              updateUserVariables("role", value);
            }}
            items={getUserRoleDropdownOptions()}
          />
          {selectedUser.role === IUserRoleEnum.Store ? (
            <>
              <Text bold>Magazin:</Text>
              <Dropdown
                id="storeDropdown"
                bordered
                title="Magazin"
                selectedItemId={
                  storeList.find(
                    (store) => store.storeId === selectedUser.store?.toString()
                  )?.storeId ?? ""
                }
                onSelect={(value) => {
                  updateUserVariables("store", value);
                }}
                items={storeList.map((store) => {
                  return { id: store.storeId ?? "", display: store.name ?? "" };
                })}
              />
            </>
          ) : selectedUser.role === IUserRoleEnum.Producer ? (
            <>
              <Text bold>Producator:</Text>
              <Dropdown
                id="producerDropdown"
                bordered
                title="Producator"
                selectedItemId={
                  producerList.find(
                    (producer) =>
                      producer.producerId === selectedUser.producer?.toString()
                  )?.producerId ?? ""
                }
                onSelect={(value) => {
                  updateUserVariables("producer", value);
                }}
                items={producerList.map((producer) => {
                  return {
                    id: producer.producerId ?? "",
                    display: producer.name ?? "",
                  };
                })}
              />
            </>
          ) : selectedUser.role === IUserRoleEnum.Warehouse ? (
            <>
              <Text bold>Depozit:</Text>
              <Dropdown
                id="depozitDropdown"
                bordered
                title="Depozit"
                selectedItemId={
                  warehouseList.find(
                    (warehouse) =>
                      warehouse.warehouseId ===
                      selectedUser.warehouse?.toString()
                  )?.warehouseId ?? ""
                }
                onSelect={(value) => {
                  updateUserVariables("warehouse", value);
                }}
                items={warehouseList.map((warehouse) => {
                  return {
                    id: warehouse.warehouseId ?? "",
                    display: warehouse.title ?? "",
                  };
                })}
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <div
          className="mt-3"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <Button onClick={onAction}>{user ? "Editeaza" : "Adauga"}</Button>
        </div>
      </ModalContentWrapper>
    </Modal>
  );
};
