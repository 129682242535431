import { Dialog } from "@mui/material";
import { Text } from "atoms";
import { Button } from "reactstrap";

interface IProps {
  onClose: () => void;
  description: string;
  onConfirm: () => void;
}

export const ConfirmationDialogue = ({
  onClose,
  description,
  onConfirm,
}: IProps) => {
  return (
    <Dialog open={true} onClose={onClose}>
      <div className="flex align-items-center justify-content-center p-4">
        <Text bold fontSize="14px">
          {description}
        </Text>

        <div className="mt-5 mb-4">
          <Button
            data-testid="confirmButton"
            onClick={onConfirm}
            color="primary"
            style={{ width: "150px" }}
          >
            Confirmă
          </Button>
          <Button
            data-testid="cancelButton"
            onClick={onClose}
            color="danger"
            className="ml-3"
            style={{ width: "150px" }}
          >
            Anulare
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
