import { Text } from "atoms";
import { AddStoreModal } from "../StoreModal/AddStoreModal";
import { useState } from "react";
import { Cancel, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectPagination } from "containers/StoresPage/slice/selectors";
import { updateStore } from "containers/StoresPage/slice";
import { IStoreIn } from "openapi/types";
import { ConfirmationDialogue } from "components/ConfirmationDialogue/ConfirmationDialogue";

interface IStoreRowProps {
  store: IStoreIn;
}

export const StoreRow = ({ store }: IStoreRowProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const pagination = useSelector(selectPagination);
  const dispatch = useDispatch();

  const onAction = () => {
    dispatch(
      updateStore({
        store: { ...store, isActive: !store.isActive },
        pagination,
      })
    );
  };

  return (
    <tr style={{ borderTop: "1px solid #e8e8e8", height: "63px" }}>
      <th>
        <Text bold>{store.name}</Text>
      </th>
      <td>
        <Text bold>{store.storeAddress?.county}</Text>
      </td>
      <td>
        <Text bold>{store.storeAddress?.city}</Text>
      </td>
      <td>
        <Text bold>{store.storeAddress?.address}</Text>
      </td>
      <td>
        <IconButton className="mr-2" onClick={() => setIsVisible(true)}>
          <Edit />
        </IconButton>
        <IconButton onClick={() => setConfirmVisibility(true)}>
          <Cancel />
        </IconButton>
      </td>
      {isVisible && (
        <AddStoreModal onClose={() => setIsVisible(false)} store={store} />
      )}
      {confirmVisibility && (
        <ConfirmationDialogue
          onClose={() => setConfirmVisibility(false)}
          description="Ești sigur că vrei să dezactivezi acest magazin?"
          onConfirm={onAction}
        />
      )}
    </tr>
  );
};
