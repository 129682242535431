import { ParentWrapper } from "./atoms/ParentWrapper";
import { Wrapper } from "./atoms/Wrapper";
import { TableWrapper } from "./atoms/TableWrapper";
import { Tbl } from "./atoms/Tbl";
import { PageLayoutTable } from "atoms/PageLayoutTable";
import { TableContentWrapper } from "atoms/TableContentWrapper";
import { TableSpinnerWrapper } from "atoms/TableSpinnerWrapper";
import LoadingSpinner from "components/LoadingSpinner";
import { Text } from "atoms";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";

interface ITableProps {
  headerRow?: JSX.Element;
  rows: JSX.Element[];
  loading?: boolean;
  onScrollBottom?: () => void;
  notFoundText: string;
}

export const Table = forwardRef(
  (
    { headerRow, rows, loading, notFoundText, onScrollBottom }: ITableProps,
    ref
  ) => {
    const tbodyRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      scrollToTop() {
        if (tbodyRef && tbodyRef.current) {
          tbodyRef.current.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      },
    }));

    const [showVerticalShadow, setShowVerticalShadow] = useState(false);
    const [showHorizontalShadow, setShowHorizontalShadow] = useState(false);

    const handleScroll = (event: any) => {
      const target = event.target;
      if (target.scrollTop) {
        if (
          (showHorizontalShadow && target.scrollTop < 10) ||
          (!showHorizontalShadow && target.scrollTop >= 10)
        ) {
          setShowHorizontalShadow((val) => !val);
        }
      }

      if (target.scrollLeft) {
        if (
          (showVerticalShadow && target.scrollLeft < 10) ||
          (!showVerticalShadow && target.scrollLeft >= 10)
        ) {
          setShowVerticalShadow((val) => !val);
        }
      }

      if (
        onScrollBottom &&
        target.scrollTop + target.clientHeight >= target.scrollHeight
      ) {
        onScrollBottom();
      }
    };

    return (
      <PageLayoutTable>
        <TableContentWrapper>
          <div style={{ display: "flex", flexGrow: 1 }}>
            {loading ? (
              <TableSpinnerWrapper>
                <LoadingSpinner />
              </TableSpinnerWrapper>
            ) : rows && rows.length > 0 ? (
              <ParentWrapper>
                <Wrapper>
                  <TableWrapper ref={tbodyRef} onScroll={handleScroll}>
                    <Tbl
                      showHorizontalShadow={showHorizontalShadow}
                      showVerticalShadow={showVerticalShadow}
                    >
                      <thead>{headerRow}</thead>
                      <tbody>{rows}</tbody>
                    </Tbl>
                  </TableWrapper>
                </Wrapper>
              </ParentWrapper>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Text fontSize="16px">{notFoundText}</Text>
              </div>
            )}
          </div>
        </TableContentWrapper>
      </PageLayoutTable>
    );
  }
);
