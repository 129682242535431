import { LinkWrapper } from "./atoms/LinkWrapper";

interface IProps {
  title: string;
  path: string;
  onClick: () => void;
}

const TopbarMenuLinks = (props: IProps) => {
  const { title, path, onClick } = props;

  return (
    <LinkWrapper to={path} onClick={onClick}>
      <p style={{ fontSize: "14px", margin: 0, lineHeight: "16px" }}>{title}</p>
    </LinkWrapper>
  );
};

export default TopbarMenuLinks;
