export enum ToastType {
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning",
}

export interface IToastMessageState {
  message: string;
  toastType: ToastType;
  isVisible: boolean;
}
export type ContainerState = IToastMessageState;
