import { Text } from "atoms";
import { useState } from "react";
import { Cancel, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectPagination } from "containers/StoresPage/slice/selectors";
import { IWarehouseIn } from "openapi/types";
import { ConfirmationDialogue } from "components/ConfirmationDialogue/ConfirmationDialogue";
import { deleteWarehouse } from "containers/WarehousesPage/slice";
import { AddWarehouseModal } from "../WarehouseModal/AddWarehouseModal";

interface IWarehouseRowProps {
  warehouse: IWarehouseIn;
}

export const WarehouseRow = ({ warehouse }: IWarehouseRowProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const pagination = useSelector(selectPagination);
  const dispatch = useDispatch();

  const onAction = () => {
    if (warehouse.warehouseId) {
      dispatch(
        deleteWarehouse({
          warehouseId: warehouse.warehouseId,
          pagination,
        })
      );
    }
  };

  return (
    <tr style={{ borderTop: "1px solid #e8e8e8", height: "63px" }}>
      <th>
        <Text bold>{warehouse.title}</Text>
      </th>
      <td>
        <Text bold>{warehouse.address?.county}</Text>
      </td>
      <td>
        <Text bold>{warehouse.address?.city}</Text>
      </td>
      <td>
        <Text bold>{warehouse.address?.address}</Text>
      </td>
      <td>
        <IconButton className="mr-2" onClick={() => setIsVisible(true)}>
          <Edit />
        </IconButton>
        <IconButton onClick={() => setConfirmVisibility(true)}>
          <Cancel />
        </IconButton>
      </td>
      {isVisible && (
        <AddWarehouseModal
          onClose={() => setIsVisible(false)}
          warehouse={warehouse}
        />
      )}
      {confirmVisibility && (
        <ConfirmationDialogue
          onClose={() => setConfirmVisibility(false)}
          description="Ești sigur că vrei să ștergi acest depozit?"
          onConfirm={onAction}
        />
      )}
    </tr>
  );
};
