import { TableHeaderCell } from "components/TableHeaderCell/TableHeaderCell";

interface ITableHeaderProps {
  elements: Array<{
    orderBy?: string;
    orderDir?: string;
    label: string;
    sortingField?: string;
    onClick?: (sortingField: string) => void;
  }>;
}

export const TableHeader = ({ elements }: ITableHeaderProps) => {
  return (
    <tr>
      {elements.map((el, index) => (
        <TableHeaderCell {...el} key={index} />
      ))}
    </tr>
  );
};
