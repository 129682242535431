import { useEffect, useRef } from "react";
import { Wrapper } from "./atoms/Wrapper";
import { ContentWrapper } from "./atoms/ContentWrapper";

interface IModalProps {
  isVisible?: boolean;
  children?: React.ReactNode;
  onClose: () => void;
}

export const Modal = ({ isVisible, onClose, children }: IModalProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        isVisible && onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <Wrapper isVisible={isVisible}>
      <ContentWrapper ref={ref}>{children}</ContentWrapper>
    </Wrapper>
  );
};
