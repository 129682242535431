import { BarChart } from "components/Charts/BarChart/BarChart";
import { selectUser } from "containers/App/slice/selectors";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { IDashboard } from "utils/interfaces/interfaces";
import { ProductsTable } from "containers/ProductsPage/ProductsTable";
import { IUserRoleEnum } from "openapi/types";

export const DashboardPage = () => {
  const user = useSelector(selectUser);
  const [dashboardData, setDashboardData] = useState<IDashboard | undefined>(
    undefined
  );

  useEffect(() => {
    if (user && user.role) {
      const role = user.role;
      if (role === IUserRoleEnum.Store) {
        const dataMap = orders.reduce((accumulator, order) => {
          const day = order.date.split(".")[0];
          if (!accumulator.has(day)) {
            accumulator.set(day, 0);
          }
          accumulator.set(day, accumulator.get(day) + order.cost);
          return accumulator;
        }, new Map());

        const data: [string, number][] = [];
        for (let i = 1; i <= 30; i++) {
          const day = i < 10 ? `0${i}` : `${i}`; // Add leading zero if day is single digit
          const totalCost = dataMap.get(day) || 0; // Get the total cost for the day, or 0 if not found
          data.push([day, totalCost]);
        }
        const dashData: IDashboard = {
          title: "Cheltuielile mele Aprilie 2024",
          series: "Lei",
          xAxis: "Zile",
          yAxis: "Bani",
          data: data,
        };
        setDashboardData(dashData);
      } else if (role === IUserRoleEnum.Producer) {
        const dataMap = orders.reduce((accumulator, order) => {
          const day = order.date.split(".")[0];
          if (!accumulator.has(day)) {
            accumulator.set(day, 0);
          }
          accumulator.set(day, accumulator.get(day) + order.cost);
          return accumulator;
        }, new Map());

        const data: [string, number][] = [];
        for (let i = 1; i <= 30; i++) {
          const day = i < 10 ? `0${i}` : `${i}`; // Add leading zero if day is single digit
          const totalCost = dataMap.get(day) || 0; // Get the total cost for the day, or 0 if not found
          data.push([day, totalCost]);
        }
        const dashData: IDashboard = {
          title: "Comenzile mele Aprilie 2024",
          series: "Lei",
          xAxis: "Zile",
          yAxis: "Bani",
          data: data,
        };
        setDashboardData(dashData);
      }
    }
  }, [user]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="mt-5">
        {dashboardData && dashboardData.data.length > 0 ? (
          <BarChart
            data={dashboardData.data}
            series={dashboardData.series}
            title={dashboardData.title}
            xAxis={dashboardData.xAxis}
            yAxis={dashboardData.yAxis}
          />
        ) : (
          <div>NO DATA AVAILABLE</div>
        )}
      </div>
      <div className="mt-5">
        <ProductsTable />
      </div>
    </div>
  );
};

const orders = [
  {
    orderId: "1",
    storeId: "3cb611ec-6b2f-429a-8a05-0795d5ca589f",
    producerId: "4d3bcb6b-2710-483a-a0b3-75bcc1d88145",
    product_id: "1e8f5e03-a9cc-4179-b6d1-c7220f49f6de",
    units: 24,
    cost: 360,
    date: "01.04.2024",
  },
  {
    orderId: "2",
    storeId: "3cb611ec-6b2f-429a-8a05-0795d5ca589f",
    producerId: "4d3bcb6b-2710-483a-a0b3-75bcc1d88145",
    product_id: "bc4fbba3-e558-4609-b7b2-d06a91b97609",
    units: 48,
    cost: 288,
    date: "01.04.2024",
  },
  {
    orderId: "3",
    storeId: "3cb611ec-6b2f-429a-8a05-0795d5ca589f",
    producerId: "4d3bcb6b-2710-483a-a0b3-75bcc1d88145",
    product_id: "1e8f5e03-a9cc-4179-b6d1-c7220f49f6de",
    units: 12,
    cost: 180,
    date: "02.04.2024",
  },
  {
    orderId: "4",
    storeId: "3cb611ec-6b2f-429a-8a05-0795d5ca589f",
    producerId: "4d3bcb6b-2710-483a-a0b3-75bcc1d88145",
    product_id: "bc4fbba3-e558-4609-b7b2-d06a91b97609",
    units: 24,
    cost: 144,
    date: "02.04.2024",
  },
  {
    orderId: "5",
    storeId: "3cb611ec-6b2f-429a-8a05-0795d5ca589f",
    producerId: "4d3bcb6b-2710-483a-a0b3-75bcc1d88145",
    product_id: "1e8f5e03-a9cc-4179-b6d1-c7220f49f6de",
    units: 24,
    cost: 360,
    date: "03.04.2024",
  },
  {
    orderId: "6",
    storeId: "3cb611ec-6b2f-429a-8a05-0795d5ca589f",
    producerId: "4d3bcb6b-2710-483a-a0b3-75bcc1d88145",
    product_id: "bc4fbba3-e558-4609-b7b2-d06a91b97609",
    units: 48,
    cost: 288,
    date: "05.04.2024",
  },
  {
    orderId: "7",
    storeId: "3cb611ec-6b2f-429a-8a05-0795d5ca589f",
    producerId: "4d3bcb6b-2710-483a-a0b3-75bcc1d88145",
    product_id: "1e8f5e03-a9cc-4179-b6d1-c7220f49f6de",
    units: 24,
    cost: 360,
    date: "06.04.2024",
  },
  {
    orderId: "8",
    storeId: "3cb611ec-6b2f-429a-8a05-0795d5ca589f",
    producerId: "4d3bcb6b-2710-483a-a0b3-75bcc1d88145",
    product_id: "bc4fbba3-e558-4609-b7b2-d06a91b97609",
    units: 48,
    cost: 288,
    date: "06.04.2024",
  },
  {
    orderId: "9",
    storeId: "3cb611ec-6b2f-429a-8a05-0795d5ca589f",
    producerId: "4d3bcb6b-2710-483a-a0b3-75bcc1d88145",
    product_id: "1e8f5e03-a9cc-4179-b6d1-c7220f49f6de",
    units: 24,
    cost: 360,
    date: "07.04.2024",
  },
  {
    orderId: "10",
    storeId: "3cb611ec-6b2f-429a-8a05-0795d5ca589f",
    producerId: "4d3bcb6b-2710-483a-a0b3-75bcc1d88145",
    product_id: "bc4fbba3-e558-4609-b7b2-d06a91b97609",
    units: 48,
    cost: 288,
    date: "09.04.2024",
  },
  {
    orderId: "11",
    storeId: "3cb611ec-6b2f-429a-8a05-0795d5ca589f",
    producerId: "4d3bcb6b-2710-483a-a0b3-75bcc1d88145",
    product_id: "1e8f5e03-a9cc-4179-b6d1-c7220f49f6de",
    units: 18,
    cost: 270,
    date: "10.04.2024",
  },
  {
    orderId: "12",
    storeId: "3cb611ec-6b2f-429a-8a05-0795d5ca589f",
    producerId: "4d3bcb6b-2710-483a-a0b3-75bcc1d88145",
    product_id: "bc4fbba3-e558-4609-b7b2-d06a91b97609",
    units: 12,
    cost: 72,
    date: "10.04.2024",
  },
];
