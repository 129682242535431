import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IProductsState } from "./types";
import {
  IProducerListOut,
  IProductIn,
  IProductOut,
  IWarehouseListOut,
} from "openapi/types";

export const initialState: IProductsState = {
  productsList: [],
  pagination: {
    pageSize: 10,
    pageNumber: 0,
  },
  totalPagesCount: 0,
  loading: false,
  error: undefined,

  warehouseList: [],
  producerList: [],
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setPagination(
      state,
      action: PayloadAction<{
        pagination: {
          pageNumber: number;
          pageSize: number;
          warehouseId?: string;
          producerId?: string;
          isActive?: boolean;
          hasStock?: boolean;
        };
      }>
    ) {
      state.pagination = action.payload.pagination;
      state.loading = true;
      state.error = undefined;
    },
    setPaginationSuccess(
      state,
      action: PayloadAction<{
        productsList: IProductOut[];
        totalPagesCount: number;
      }>
    ) {
      state.productsList = action.payload.productsList;
      state.totalPagesCount = action.payload.totalPagesCount;
      state.loading = false;
    },
    setPaginationError(state, action: PayloadAction<{ error: string }>) {
      state.error = action.payload.error;
      state.productsList = [];
      state.loading = false;
    },
    addProduct(
      state,
      action: PayloadAction<{
        storeId: string;
        productId: string;
        quantity: number;
      }>
    ) {},
    createProduct(
      state,
      action: PayloadAction<{
        product: IProductIn;
        image: File | null;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
    deleteProduct(state, action: PayloadAction<{ productId: string }>) {
      state.loading = true;
    },
    updateProduct(
      state,
      action: PayloadAction<{
        product: IProductIn;
        pagination?: { pageNumber: number; pageSize: number };
      }>
    ) {
      state.loading = true;
    },
    loadWarehouses(state) {},
    loadWarehouseSuccess(
      state,
      action: PayloadAction<{ warehouseList: IWarehouseListOut[] }>
    ) {
      state.warehouseList = action.payload.warehouseList;
    },
    loadProducers(state) {},
    loadProducersSuccess(
      state,
      action: PayloadAction<{ producerList: IProducerListOut[] }>
    ) {
      state.producerList = action.payload.producerList;
    },
  },
});

export const {
  setPagination,
  setPaginationSuccess,
  setPaginationError,
  addProduct,
  createProduct,
  deleteProduct,
  updateProduct,
  loadWarehouses,
  loadWarehouseSuccess,
  loadProducers,
  loadProducersSuccess,
} = productsSlice.actions;

export default productsSlice.reducer;
