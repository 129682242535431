import { RootState } from "types";
import { initialState } from ".";
import { createSelector } from "@reduxjs/toolkit";

const selectProfile = (state: RootState) => state.profile || initialState;

export const selectStore = createSelector(
  [selectProfile],
  (profile) => profile.store
);

export const selectError = createSelector(
  [selectProfile],
  (profile) => profile.error
);

export const selectLoading = createSelector(
  [selectProfile],
  (profile) => profile.loading
);
